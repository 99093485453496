import React, { useState } from "react";
import { UserAuth } from "../lib/authContext";
import NavigationBar from "../components/ui/NavBar";
import { useSelector } from "react-redux";
import {
  BasketProduct,
  selectProducts,
  selectTotal,
} from "../lib/redux/basketSlice";
import CartProduct from "../components/CartProduct";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";

const Cart = () => {
  const { user } = UserAuth();
  const navigate = useNavigate();
  const products = useSelector(selectProducts);
  const subtotal = useSelector(selectTotal);
  const [discount, setDiscount] = useState(true);

  return (
    <main>
      <NavigationBar />
      <div
        className="lg:flex max-w-screen-2xl mx-auto pt-[6rem] md:pt-32 justify-evenly"
        id="cart"
      >
        {/* Left */}
        <div className="flex flex-col p-5 space-y-10 bg-white shadow-md">
          {products.length === 0 ? (
            <>
              <h1 className="text-3xl border-b pb-4">Your cart is empty</h1>
              <button
                className="select-none text-black px-3 py-2 bg-gradient-to-b from-cyan-200 to-cyan-400 border border-cyan-300 rounded-md focus:outline-none hover:from-cyan-500 hover:to-cyan-400 w-full"
                onClick={() => navigate("/")}
              >
                Go to Home
              </button>
            </>
          ) : (
            <h1 className="text-3xl border-b pb-4">Your cart</h1>
          )}
          {products
            .reduce(
              (acc: BasketProduct[], item) =>
                acc.some((obj) => obj.product_id === item.product_id)
                  ? acc
                  : [...acc, item],
              []
            )
            .map((item, i) => (
              <CartProduct
                key={i}
                product_id={item.product_id}
                product_images={item.product_images}
                product_name={item.product_name}
                product_price={item.product_price}
                product_details={item.product_details}
                product_description={item.product_description}
              />
            ))}
        </div>
        {/* Right */}

        {products.length > 0 && (
          <div className="flex flex-col bg-white p-10 shadow-md mt-5 lg:mt-0">
            <div className="flex flex-row justify-between">
              <div className="flex flex-row items-center">
                <h2>
                  Subtotal ({products.length}{" "}
                  {products.length > 1 ? "products" : "product"})
                </h2>
              </div>
              <h2 className=" ml-5">Rs. {(subtotal / 0.7).toFixed(2)}</h2>
            </div>

            <div className="flex flex-row justify-between">
              <div className="flex flex-row items-center">
                <h2 className="font-semibold">Discounted Price</h2>
                <button
                  className="text-xs flex justify-center items-center border border-gray-500 rounded-full h-4 w-4 ml-1"
                  onClick={() => setDiscount(!discount)}
                >
                  i
                </button>
              </div>
              <h2 className=" font-bold ml-5">Rs. {subtotal.toFixed(2)}</h2>
            </div>

            {discount && (
              <div className="bg-[#f6f6f6] rounded-xl p-2 my-1 lg:w-80">
                <h2 className="text-sm text-center">
                  Congratulations! You are saving{" "}
                  <span className="font-semibold truncate">
                    Rs. {((subtotal / 0.7) * 0.3).toFixed(2)}
                  </span>{" "}
                  (30% discount) with this order!
                </h2>
              </div>
            )}

            {user ? (
              <>
                <button
                  className="select-none text-black px-3 py-2 bg-gradient-to-b from-cyan-200 to-cyan-400 border border-cyan-300 rounded-md focus:outline-none hover:from-cyan-500 hover:to-cyan-400 w-full mt-3"
                  onClick={() => navigate("/checkout")}
                >
                  Proceed to checkout
                </button>
              </>
            ) : (
              <button
                className="select-none text-black px-3 py-2 bg-gradient-to-b from-cyan-200 to-cyan-400 border border-cyan-300 rounded-md focus:outline-none hover:from-cyan-500 hover:to-cyan-400 w-full mt-3"
                onClick={() => {
                  navigate("/account");
                }}
              >
                Sign in to checkout
              </button>
            )}
          </div>
        )}
      </div>
      <Footer />
    </main>
  );
};

export default Cart;
