import Card from "../ProductCard";
import { Button } from "flowbite-react";
import { useCallback, useEffect, useState } from "react";
import { bedroom } from "../../assets/data/categories";
import { fetchProducts } from "../../lib/apis";
import { Product } from "../../lib/types";

const Bedroom = () => {
  const [allProducts, setAllProducts] = useState<Product[] | undefined>();
  const [sortBySubcategory, setSortBySubcategory] = useState<{
    [key: string]: string;
  }>({}); // Track sorting for each subcategory

  const getItems = useCallback(async () => {
    const res = await fetchProducts("bedroom");
    if (res?.status === 200) setAllProducts(res.data);
  }, []);

  useEffect(() => {
    if (!allProducts || allProducts.length === 0) {
      getItems();
    }
  }, [getItems]);

  return (
    <main
      id="bedroom"
      className="container min-h-screen w-full px-3 md:px-10 pt-[6rem] md:pt-[7rem] lg:pt-[6rem]"
    >
      <h1 className="text-2xl md:text-4xl xl:text-6xl text-center font-bold my-10 tracking-widest uppercase">
        Bedroom Decor
      </h1>
      <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-3">
        {bedroom.map((item) => (
          <Button
            key={item.id}
            className="bg-white hover:bg-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 flex-grow items-center w-full p-4 focus:ring-0"
            onClick={() => {
              var elementToView = document.getElementById(item.id);
              elementToView?.scrollIntoView({ behavior: "smooth" });
            }}
          >
            <p className="text-black dark:hover:text-white lg:tracking-widest tracking-wide md:uppercase">
              {item.name}
            </p>
          </Button>
        ))}
      </div>

      {bedroom.map((item) => {
        const subcategorySort = sortBySubcategory[item.id] || "4"; // Get the sort option for the current subcategory, default to "4"

        // Sort products based on the current subcategory's sorting option
        const sortedProducts = allProducts
          ?.filter((product) => product.product_subcategory === item.id)
          .sort((a, b) => {
            switch (subcategorySort) {
              case "0": // Lowest Price
                return a.product_price - b.product_price;
              case "1": // Highest Price
                return b.product_price - a.product_price;
              case "2": // A-Z
                return a.product_name.localeCompare(b.product_name);
              case "3": // Z-A
                return b.product_name.localeCompare(a.product_name);
              case "4": // New to Old
                return (
                  new Date(b.created_at).getTime() -
                  new Date(a.created_at).getTime()
                );
              case "5": // Old to New
                return (
                  new Date(a.created_at).getTime() -
                  new Date(b.created_at).getTime()
                );
              default:
                return 0;
            }
          });

        return (
          <section key={item.id} id={item.id}>
            <div className="flex justify-between items-center">
              <h1 className="text-2xl my-10 md:ml-3 font-semibold md:uppercase">
                {item.name}
              </h1>

              {/* Sorting dropdown for this subcategory */}
              <select
                name={`sort-${item.id}`}
                value={subcategorySort}
                className="border-0 rounded"
                onChange={(e) =>
                  setSortBySubcategory((prev) => ({
                    ...prev,
                    [item.id]: e.target.value,
                  }))
                }
              >
                <option value="4">New to Old</option>
                <option value="5">Old to New</option>
                <option value="0">Lowest Price</option>
                <option value="1">Highest Price</option>
                <option value="2">A-Z</option>
                <option value="3">Z-A</option>
              </select>
            </div>

            <div
              className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-5 xl:gap-10"
              id={item.id}
            >
              {sortedProducts?.map((product, index) => (
                <Card key={index} item={product} />
              ))}
            </div>
          </section>
        );
      })}
    </main>
  );
};

export default Bedroom;
