import React from "react";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import { AuthContextProvider } from "./lib/authContext";
import AOS from "aos";
import { ToastContainer } from "react-toastify";

import "./App.css";
import "aos/dist/aos.css";
import "react-toastify/dist/ReactToastify.css";

AOS.init();

function App() {
  return (
    <BrowserRouter>
      <AuthContextProvider>
        <ToastContainer hideProgressBar={true} autoClose={2000} />
        <AppRoutes />
      </AuthContextProvider>
    </BrowserRouter>
  );
}

export default App;
