export default [
  {
    product_id: "456707b6-703c-47ba-9f57-58d86a5d8f8c",
    product_name: '"Cloud Cocoon" Macramé Baby Bassinet',
    product_link: "cloud-cocoon-macrame-baby-bassinet",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/baby-bassinet/cloud-cocoon-macrame-baby-bassinet.webp",
    ],
    product_details: ["18 × 34 inch", "52 inch height"],
    product_price: 5999,
    product_category: "bedroom",
    product_subcategory: "baby_bassinet",
  },
  {
    product_id: "6d57a0ac-4174-4072-aeec-2e784d053dc8",
    product_name: "Wooden Macramé Book Stand",
    product_link: "wooden-macrame-book-stand",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/book-stand/wooden-macrame-book-stand.webp",
    ],
    product_details: ["16 × 22 inch"],
    product_price: 4999,
    product_category: "organizer",
    product_subcategory: "book_stand",
  },
  {
    product_id: "245a5f3c-2026-4ecb-b9a4-b01ae129181d",
    product_name: '"Boho Tapestry" Macramé Accessories Hanger',
    product_link: "boho-tapestry-macrame-accessories-hanger",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/accessories-hanger/boho-tapestry-macrame-accessories-hanger.webp",
    ],
    product_details: ["16 × 34 inch"],
    product_price: 499,
    product_category: "organizer",
    product_subcategory: "accessories_holder",
  },
  {
    product_id: "a47f2d3d-f39d-48e1-b8ec-6d521a17cffe",
    product_name: '"Minimilist" Jewellery Macramé Hanger',
    product_link: "minimilist-jewellery-macrame-hanger",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/accessories-hanger/minimilist-jewellery-macrame-hanger.webp",
    ],
    product_details: ["10 × 24 inch"],
    product_price: 399,
    product_category: "organizer",
    product_subcategory: "accessories_holder",
  },
  {
    product_id: "5e9be72c-3fdf-4702-b4c3-6f817dcfd874",
    product_name: '"Peachy Minimilist" Jewellery Macramé Hanger',
    product_link: "peachy-minimilist-jewellery-macrame-hanger",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/accessories-hanger/peachy-minimilist-jewellery-macrame-hanger.webp",
    ],
    product_details: ["8 × 16 inch"],
    product_price: 199,
    product_category: "organizer",
    product_subcategory: "accessories_holder",
  },
  {
    product_id: "eb0b8586-ddf0-4d27-8741-b1518b8bc810",
    product_name: '"Boho Knotty Treasures" Accessories Hanger',
    product_link: "boho-knotty-treasures-hanger",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/accessories-hanger/boho-knotty-treasures-hanger.webp",
    ],
    product_details: ["8 × 16 inch", "Single Piece"],
    product_price: 399,
    product_category: "organizer",
    product_subcategory: "accessories_holder",
  },
  {
    product_id: "f504da5b-c69a-415b-ae05-bf1b1e031be0",
    product_name: '"Boho Knotty Treasures" Set of 3 Accessories Hanger',
    product_link: "boho-knotty-treasures-set-of-3-hanger",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/accessories-hanger/boho-knotty-treasures-hanger.webp",
    ],
    product_details: ["8 × 16 inch", "Set of 3"],
    product_price: 1199,
    product_category: "organizer",
    product_subcategory: "accessories_holder",
  },
  {
    product_id: "23e4227c-ce45-4374-835b-3ce72fb85f52",
    product_name: '"Timeless" Macramé Clutch Bag with sling',
    product_link: "macrame-clutch-bag-2",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/bags/macrame-bag-2.webp",
    ],
    product_details: ["7 × 9 inch", "22 inch handle"],
    product_price: 349,
    product_category: "organizer",
    product_subcategory: "bag",
  },
  {
    product_id: "8bf2303c-9c76-4f82-8c82-07bae51bccd5",
    product_name: '"Boho Chic" Macramé Shoulder Bag',
    product_link: "macrame-handbag",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/bags/macrame-bag-4.webp",
    ],
    product_details: ["12 × 12 inch", "12 inch handle"],
    product_price: 499,
    product_category: "organizer",
    product_subcategory: "bag",
  },
  {
    product_id: "bded2fd7-2219-4c07-8dcb-c1182690e573",
    product_name: '"Daisy" Crochet Macramé Tote Bag',
    product_link: "macrame-crocket-handbag-2",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/bags/macrame-bag-12.webp",
    ],
    product_details: ["12 × 12 inch", "12 inch handle"],
    product_price: 699,
    product_category: "organizer",
    product_subcategory: "bag",
  },
  {
    product_id: "09324d46-4427-4e40-86a7-87bf7a41b189",
    product_name: '"Rainbow Sunburst" Macramé Crochet Sling Bag',
    product_link: "macrame-crochet-clutch-bag-2",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/bags/macrame-bag-20.webp",
    ],
    product_details: ["9 × 11 inch", "22 inch handle"],
    product_price: 499,
    product_category: "organizer",
    product_subcategory: "bag",
  },
  {
    product_id: "57d77e82-1708-45cb-b211-7a3ad9493a2f",
    product_name: 'Raffia "Beach Please" Macramé Shoulder Bag',
    product_link: "macrame-raffia-shoulder-bag-3",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/bags/macrame-bag-35.webp",
    ],
    product_details: ["12 × 14 inch", "14 inch handle"],
    product_price: 999,
    product_category: "organizer",
    product_subcategory: "bag",
  },
  {
    product_id: "95d2164f-1a8f-47fc-a4ac-8b8498767dcb",
    product_name: '"Beige Tassel" Macramé Clutch Bag',
    product_link: "macrame-clutch-bag-5",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/bags/macrame-bag-41.webp",
    ],
    product_details: ["8 × 10 inch", "22 inch handle"],
    product_price: 399,
    product_category: "organizer",
    product_subcategory: "bag",
  },
  {
    product_id: "4dfa8d84-38e4-4acd-aa06-04a656ed702e",
    product_name: '"Frosted Tassel" Macramé Clutch Bag',
    product_link: "macrame-clutch-bag-6",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/bags/macrame-bag-42.webp",
    ],
    product_details: ["8 × 10 inch", "22 inch handle"],
    product_price: 399,
    product_category: "organizer",
    product_subcategory: "bag",
  },
  {
    product_id: "e476d3a3-61ee-49f2-8ca0-4f0654e5bb1b",
    product_name: '"Vintage Knit" Macramé Clutch Bag',
    product_link: "macrame-clutch-bag-8",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/bags/macrame-bag-44.webp",
    ],
    product_details: ["8 × 10 inch", "22 inch handle"],
    product_price: 399,
    product_category: "organizer",
    product_subcategory: "bag",
  },
  {
    product_id: "fdff1be1-efbd-4e5d-9534-0091e60ecbe4",
    product_name: '"Slay Girl" Macramé Crochet Handbag',
    product_link: "macrame-crochet-handbag-8",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/bags/macrame-bag-52.webp",
    ],
    product_details: ["8 × 10 inch", "10 inch handle"],
    product_price: 699,
    product_category: "organizer",
    product_subcategory: "bag",
  },
  {
    product_id: "baec703d-8322-47db-a563-2852ef12993c",
    product_name: '"Round Mandala" Crochet Macramé Sling Bag',
    product_link: "macrame-crochet-sling-shoulder-bag-2",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/bags/macrame-bag-8.webp",
    ],
    product_details: ["11 inch round", "22 inch handle"],
    product_price: 699,
    product_category: "organizer",
    product_subcategory: "bag",
  },
  {
    product_id: "681d1e83-97d1-4343-aaa4-c9fea16e94a1",
    product_name: "Mini Crochet Macramé Handbag",
    product_link: "macrame-crocket-handbag",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/bags/macrame-bag-11.webp",
    ],
    product_details: ["9 × 9 inch", "4 inch handle"],
    product_price: 399,
    product_category: "organizer",
    product_subcategory: "bag",
  },
  {
    product_id: "e88297d0-793d-42fb-9189-cf1c3be73db9",
    product_name: '"Ganny" Square Macramé Crochet Tote Bag',
    product_link: "macrame-crocket-handbag-6",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/bags/macrame-bag-16.webp",
    ],
    product_details: ["12 × 14 inch", "12 inch handle"],
    product_price: 799,
    product_category: "organizer",
    product_subcategory: "bag",
  },
  {
    product_id: "50273fd2-c70d-4eef-b11c-c025a16fd2f8",
    product_name: '"Peacock Blue" Macramé Mobile Sling Bag',
    product_link: "macrame-mobile-cover",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/bags/macrame-bag-23.webp",
    ],
    product_details: ["5 × 8 inch", "22 inch handle"],
    product_price: 299,
    product_category: "organizer",
    product_subcategory: "bag",
  },
  {
    product_id: "60785b45-6fbe-4997-b914-4aa5757bc626",
    product_name: 'Wooden Bead "Capsule" Macramé Bag',
    product_link: "macrame-wooden-bead-bag",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/bags/macrame-bag-27.webp",
    ],
    product_details: ["8 × 12 inch", "10 inch handle"],
    product_price: 499,
    product_category: "organizer",
    product_subcategory: "bag",
  },
  {
    product_id: "38827f9a-c01f-42d2-acdd-bbb775a9f770",
    product_name: 'Lavender "Kiss See" Macramé Crochet Handbag',
    product_link: "macrame-crochet-handbag-5",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/bags/macrame-bag-37.webp",
    ],
    product_details: ["8 × 8 inch", "6 inch handle"],
    product_price: 599,
    product_category: "organizer",
    product_subcategory: "bag",
  },
  {
    product_id: "e7ec6859-a8d9-485e-8bcc-4a92655ecdad",
    product_name: "Doorway Macramé Curtain",
    product_link: "doorway-macrame-curtain",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/curtain/doorway-macrame-curtain.webp",
    ],
    product_details: ["48 × 84 inch"],
    product_price: 1999,
    product_category: "living",
    product_subcategory: "curtain",
  },
  {
    product_id: "23b5a8d6-15e1-4615-a5ba-757fedbd1b8d",
    product_name: "Macramé Curtain Shades",
    product_link: "macrame-curtain-shades",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/curtain/macrame-curtain-shades.webp",
    ],
    product_details: ["48 × 72 inch"],
    product_price: 1599,
    product_category: "living",
    product_subcategory: "curtain",
  },
  {
    product_id: "cb991369-8917-4080-9e14-9536723a95d4",
    product_name: "Sofa Backdrop Macramé Curtain",
    product_link: "sofa-backdrop-macrame-curtain",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/curtain/sofa-backdrop-macrame-curtain.webp",
    ],
    product_details: ["64 × 84 inch"],
    product_price: 3999,
    product_category: "living",
    product_subcategory: "curtain",
  },
  {
    product_id: "a496e171-68b4-43fb-bc1a-f3027c126463",
    product_name: '"White Wood" Set of 2 Macramé Curtain',
    product_link: "white-wood-set-of-2-macrame-curtain",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/curtain/white-wood-set-of-2-macrame-curtain.webp",
    ],
    product_details: ["64 × 84 inch", "Set of 2"],
    product_price: 3999,
    product_category: "living",
    product_subcategory: "curtain",
  },
  {
    product_id: "efee22e5-9465-4014-ac22-891fbc954508",
    product_name: '"Valance" Window Macramé Curtain',
    product_link: "valance-window-macrame-curtain",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/curtain/valance-window-macrame-curtain.webp",
    ],
    product_details: ["48 × 72 inch"],
    product_price: 2999,
    product_category: "living",
    product_subcategory: "curtain",
  },
  {
    product_id: "d9ed7e80-1e00-43f9-bd4b-d19466e48baf",
    product_name: '"Cross-braid Diamond" Macramé Curtain',
    product_link: "cross-braid-diamond-macrame-curtain",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/curtain/cross-braid-diamond-macrame-curtain.webp",
    ],
    product_details: ["48 × 72 inch"],
    product_price: 2999,
    product_category: "living",
    product_subcategory: "curtain",
  },
  {
    product_id: "608cabf2-0715-4dbd-82e1-f9b4264a7a4c",
    product_name: '"Veridian Elegance" Macramé Dream Catcher',
    product_link: "veridian-elegance-macrame-dream-catcher",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/dream-catcher/veridian-elegance-macrame-dream-catcher.webp",
    ],
    product_details: ["12 × 36 inch"],
    product_price: 599,
    product_category: "bedroom",
    product_subcategory: "dream_catcher",
  },
  {
    product_id: "61afbd55-996f-4614-af4c-75dc61d11cd0",
    product_name: '"Snow White Strands" Macramé Dream Catcher',
    product_link: "snow-white-strands-macrame-dream-catcher",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/dream-catcher/snow-white-strands-macrame-dream-catcher.webp",
    ],
    product_details: ["15 × 35 inch"],
    product_price: 599,
    product_category: "bedroom",
    product_subcategory: "dream_catcher",
  },
  {
    product_id: "3486c7fe-1a7c-49b9-bee7-9d2763b5f243",
    product_name: '"Ivory Sunflower" Macramé Dream Catcher',
    product_link: "ivory-sunflower-macrame-dream-catcher",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/dream-catcher/ivory-sunflower-macrame-dream-catcher.webp",
    ],
    product_details: ["16 × 36 inch"],
    product_price: 699,
    product_category: "bedroom",
    product_subcategory: "dream_catcher",
  },
  {
    product_id: "b6863c0d-0751-490b-ac03-36bd6494bfb2",
    product_name: '"Artisan Arbor" Macramé Dream Catcher',
    product_link: "artisan-arbor-macrame-dream-catcher",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/dream-catcher/artisan-arbor-macrame-dream-catcher.webp",
    ],
    product_details: [
      "12 × 36 inch (left)",
      "16 × 40 inch (center)",
      "10 × 22 inch (right)",
      "Set of 3",
    ],
    product_price: 1599,
    product_category: "bedroom",
    product_subcategory: "dream_catcher",
  },
  {
    product_id: "35d3a2c8-3460-4a00-aed0-8d31d2108b56",
    product_name: '"Stellar Serenity" Macramé Dream Catcher',
    product_link: "stellar-serenity-macrame-dream-catcher",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/dream-catcher/stellar-serenity-macrame-dream-catcher.webp",
    ],
    product_details: ["8 × 25 inch", "Set of 2"],
    product_price: 599,
    product_category: "bedroom",
    product_subcategory: "dream_catcher",
  },
  {
    product_id: "10c1ec16-e5eb-4efa-a3df-be3746050fda",
    product_name: '"Twilight Tassels" Macramé Dream Catcher',
    product_link: "twilight-tassels-macrame-dream-catcher",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/dream-catcher/twilight-tassels-macrame-dream-catcher.webp",
    ],
    product_details: ["8 × 25 inch", "Set of 4"],
    product_price: 1199,
    product_category: "bedroom",
    product_subcategory: "dream_catcher",
  },
  {
    product_id: "62ccb7bf-63d9-49be-ad13-e450332aea42",
    product_name: '"Beach Pearl Tassel" Macramé Dream Catcher',
    product_link: "beach-pearl-tassel-macrame-dream-catcher",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/dream-catcher/beach-pearl-tassel-macrame-dream-catcher.webp",
    ],
    product_details: ["16 × 36 inch"],
    product_price: 799,
    product_category: "bedroom",
    product_subcategory: "dream_catcher",
  },
  {
    product_id: "b302ff74-a7a6-4fa7-91f3-670c54e187f9",
    product_name: '"Peachy Lunar Lace" Macramé Dream Catcher',
    product_link: "peachy-lunar-lace-macrame-dream-catcher",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/dream-catcher/peachy-lunar-lace-macrame-dream-catcher.webp",
    ],
    product_details: ["15 × 36 inch"],
    product_price: 599,
    product_category: "bedroom",
    product_subcategory: "dream_catcher",
  },
  {
    product_id: "ab830de9-a89a-4c2b-9828-b24226b674b6",
    product_name: '"Royal Magenta" Macramé Dream Catcher',
    product_link: "royal-magenta-macrame-dream-catcher",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/dream-catcher/royal-magenta-macrame-dream-catcher.webp",
    ],
    product_details: ["14 × 20 inch"],
    product_price: 499,
    product_category: "bedroom",
    product_subcategory: "dream_catcher",
  },
  {
    product_id: "1197a5a9-58ca-4f8f-a9f8-25712d413cfb",
    product_name: 'Three Tier "Sea Coaster" Macramé Dream Catcher',
    product_link: "three-tier-sea-coaster-macrame-dream-catcher",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/dream-catcher/three-tier-sea-coaster-macrame-dream-catcher.webp",
    ],
    product_details: ["10 × 32 inch"],
    product_price: 799,
    product_category: "bedroom",
    product_subcategory: "dream_catcher",
  },
  {
    product_id: "ce9d69c9-cf4c-4376-98b0-40b983809cab",
    product_name: '"Beaded Boho" Macramé Dream Catcher',
    product_link: "beaded-boho-macrame-dream-catcher",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/dream-catcher/mystical-beaded-macrame-dream-catcher.webp",
    ],
    product_details: ["12 × 22 inch"],
    product_price: 599,
    product_category: "bedroom",
    product_subcategory: "dream_catcher",
  },
  {
    product_id: "4020b4de-0c6e-4448-b8f6-877029b88f3d",
    product_name: '"Pastel Blue" Long Macramé Dream Catcher',
    product_link: "pastel-blue-long-macrame-dream-catcher",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/dream-catcher/pastel-blue-long-macrame-dream-catcher.webp",
    ],
    product_details: ["14 × 40 inch"],
    product_price: 799,
    product_category: "bedroom",
    product_subcategory: "dream_catcher",
  },
  {
    product_id: "98e72ec6-ae74-42e3-b13b-ce9bf89e21d4",
    product_name: '"Ethereal Embrace" Macramé Dream Catcher',
    product_link: "ethereal-embrace-macrame-dream-catcher",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/dream-catcher/ethereal-embrace-macrame-dream-catcher.webp",
    ],
    product_details: ["14 × 40 inch"],
    product_price: 799,
    product_category: "bedroom",
    product_subcategory: "dream_catcher",
  },
  {
    product_id: "23a4f258-7bb1-49fc-b69d-d9faa6943873",
    product_name: '"Frosty Sunflower" Macramé Dream Catcher',
    product_link: "frosty-sunflower-macrame-dream-catcher",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/dream-catcher/frosty-sunflower-macrame-dream-catcher.webp",
    ],
    product_details: ["16 × 36 inch"],
    product_price: 699,
    product_category: "bedroom",
    product_subcategory: "dream_catcher",
  },
  {
    product_id: "7e26f9da-16f6-4b82-b0b0-23a6b505c880",
    product_name: '"Boho Breeze" Macramé Floor Mat',
    product_link: "boho-breeze-macrame-floor-mat",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/floor-mat/boho-breeze-macrame-floor-mat.webp",
    ],
    product_details: ["13 × 42 inch"],
    product_price: 499,
    product_category: "bedroom",
    product_subcategory: "floor_mat",
  },
  {
    product_id: "be28289e-679a-4f2b-a8b6-30c566127ce4",
    product_name: '"Classic Boho Beach" Macramé Floor Mat',
    product_link: "classic-boho-beach-macrame-floor-mat",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/floor-mat/classic-boho-beach-macrame-floor-mat.webp",
    ],
    product_details: ["16 × 38 inch"],
    product_price: 899,
    product_category: "bedroom",
    product_subcategory: "floor_mat",
  },
  {
    product_id: "c706b6d7-71c9-408c-82f6-5c59836bd297",
    product_name: '"Moiety Sunflower" Macramé Floor Mat',
    product_link: "moiety-sunflower-macrame-floor-mat",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/floor-mat/moiety-sunflower-macrame-floor-mat.webp",
    ],
    product_details: ["24 × 18 inch", "Semicircle Mat"],
    product_price: 899,
    product_category: "bedroom",
    product_subcategory: "floor_mat",
  },
  {
    product_id: "cdbbe2c8-be88-4b46-8842-3cd99c660de8",
    product_name: '"Knotwork Tassel" Macramé Floor Mat',
    product_link: "knotwork-tassel-macrame-floor-mat",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/floor-mat/knotwork-tassel-macrame-floor-mat.webp",
    ],
    product_details: ["36 inch round"],
    product_price: 1399,
    product_category: "bedroom",
    product_subcategory: "floor_mat",
  },
  {
    product_id: "5c5dadae-62c9-419c-b4e3-46833f4a98e4",
    product_name: '"Serene Weave" Macramé Floor Mat',
    product_link: "serene-weave-macrame-floor-mat",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/floor-mat/serene-weave-macrame-floor-mat.webp",
    ],
    product_details: ["16 × 38 inch"],
    product_price: 899,
    product_category: "bedroom",
    product_subcategory: "floor_mat",
  },
  {
    product_id: "7267a304-382c-46dd-b08c-193794a77338",
    product_name: '"Cream Moiety Sunflower" Macramé Floor Mat',
    product_link: "cream-moiety-sunflower-macrame-floor-mat",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/floor-mat/cream-moiety-sunflower-macrame-floor-mat.webp",
    ],
    product_details: ["34 × 18 inch", "Half Round Mat"],
    product_price: 999,
    product_category: "bedroom",
    product_subcategory: "floor_mat",
  },
  {
    product_id: "773481f4-e05e-4cb9-9249-14c54707cbbc",
    product_name: '"Classic Persian" Macramé Floor Mat',
    product_link: "classic-persian-macrame-floor-mat",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/floor-mat/classic-persian-macrame-floor-mat.webp",
    ],
    product_details: ["28 × 16 inch", "Half Round Mat"],
    product_price: 799,
    product_category: "bedroom",
    product_subcategory: "floor_mat",
  },
  {
    product_id: "f2fcc7bb-1d2b-4c30-ac40-607fc6d92b98",
    product_name: '"Knotted Lace Lounge" Macramé Floor Mat',
    product_link: "knotted-lace-lounge-macrame-floor-mat",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/floor-mat/knotted-lace-lounge-macrame-floor-mat.webp",
    ],
    product_details: ["28 × 16 inch", "Semicircle Mat"],
    product_price: 799,
    product_category: "bedroom",
    product_subcategory: "floor_mat",
  },
  {
    product_id: "c60119e2-1691-4582-880e-6e200afcf091",
    product_name: '"Frosty Bohemian Breeze" Macramé Floor Mat',
    product_link: "frosty-bohemian-breeze-macrame-floor-mat",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/floor-mat/frosty-bohemian-breeze-macrame-floor-mat.webp",
    ],
    product_details: ["28 × 16 inch", "Half Round Mat"],
    product_price: 799,
    product_category: "bedroom",
    product_subcategory: "floor_mat",
  },
  {
    product_id: "c88b6d25-3374-4119-b915-e71fdb514b91",
    product_name: '"Woven Wonder Sunflower" Macramé Floor Mat',
    product_link: "woven-wonder-sunflower-macrame-floor-mat",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/floor-mat/woven-wonder-sunflower-macrame-floor-mat.webp",
    ],
    product_details: ["24 inch round"],
    product_price: 999,
    product_category: "bedroom",
    product_subcategory: "floor_mat",
  },
  {
    product_id: "b0c811fd-9ea6-4bbb-a3fc-a46128bbee22",
    product_name: '"Diamond Drift" Macramé Wall Hanging',
    product_link: "diamond-drift-macrame-wall-hanging",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/wall-hanging/diamond-drift-macrame-wall-hanging.webp",
    ],
    product_details: ["16 × 22 inch"],
    product_price: 599,
    product_category: "living",
    product_subcategory: "wall_hanging",
  },
  {
    product_id: "9d51b074-9b53-45bf-ae43-4d1468c2ca5c",
    product_name: '"Icicle Dream" Macramé Wall Hanging',
    product_link: "icicle-dream-macrame-wall-hanging",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/wall-hanging/icicle-dream-macrame-wall-hanging.webp",
    ],
    product_details: ["14 × 32 inch"],
    product_price: 499,
    product_category: "living",
    product_subcategory: "wall_hanging",
  },
  {
    product_id: "3a649f86-3d2e-4eb1-8fe9-2154ed9dd739",
    product_name: '"Red Earth Rhythm" Macramé Wall Hanging',
    product_link: "red-earth-rhythm-macrame-wall-hanging",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/wall-hanging/red-earth-rhythm-macrame-wall-hanging.webp",
    ],
    product_details: ["14 × 32 inch"],
    product_price: 499,
    product_category: "living",
    product_subcategory: "wall_hanging",
  },
  {
    product_id: "9c8752fc-4d26-4685-9d3f-56b48a63ed57",
    product_name: '"Mystic Grid" Macramé Wall Hanging',
    product_link: "mystic-grid-macrame-wall-hanging",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/wall-hanging/mystic-grid-macrame-wall-hanging.webp",
    ],
    product_details: ["16 × 22 inch"],
    product_price: 599,
    product_category: "living",
    product_subcategory: "wall_hanging",
  },
  {
    product_id: "9362f0b3-3fbf-4e6b-af00-1eaf47b99dc0",
    product_name: '"Lavender Wave" Macramé Wall Hanging',
    product_link: "lavender-wave-macrame-wall-hanging",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/wall-hanging/lavender-wave-macrame-wall-hanging.webp",
    ],
    product_details: ["24 × 38 inch"],
    product_price: 799,
    product_category: "living",
    product_subcategory: "wall_hanging",
  },
  {
    product_id: "7e5a4f21-4bf1-40bb-8b8c-e6904c4e0fda",
    product_name: '"Threaded Temple" Macramé Wall Hanging',
    product_link: "threaded-temple-macrame-wall-hanging",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/wall-hanging/threaded-temple-macrame-wall-hanging.webp",
    ],
    product_details: ["16 × 34 inch"],
    product_price: 799,
    product_category: "living",
    product_subcategory: "wall_hanging",
  },
  {
    product_id: "b650d795-c286-4cad-9fd2-e2f6529e5c54",
    product_name: '"Nordic Cascade" Macramé Wall Hanging',
    product_link: "nordic-cascade-macrame-wall-hanging",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/wall-hanging/nordic-cascade-macrame-wall-hanging.webp",
    ],
    product_details: ["10 × 12 inch", "Single Piece"],
    product_price: 399,
    product_category: "living",
    product_subcategory: "wall_hanging",
  },
  {
    product_id: "4238780b-c2c6-4455-bdff-5b4525214ea7",
    product_name: '"Artisan Arbor" Macramé Wall Hanging',
    product_link: "artisan-arbor-macrame-wall-hanging",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/wall-hanging/artisan-arbor-macrame-wall-hanging.webp",
    ],
    product_details: ["13 × 35 inch"],
    product_price: 699,
    product_category: "living",
    product_subcategory: "wall_hanging",
  },
  {
    product_id: "1485c0e0-daba-48d5-a2dc-620039f2e4bd",
    product_name: '"Sienna Sunrise" Macramé Wall Hanging',
    product_link: "sienna-sunrise-macrame-wall-hanging",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/wall-hanging/sienna-sunrise-macrame-wall-hanging.webp",
    ],
    product_details: ["14 × 24 inch"],
    product_price: 599,
    product_category: "living",
    product_subcategory: "wall_hanging",
  },
  {
    product_id: "4de783e5-3039-4521-bb10-4d99fdbac2fe",
    product_name: '"Solstice Strings" Macramé Wall Hanging',
    product_link: "solstice-strings-macrame-wall-hanging",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/wall-hanging/solstice-strings-macrame-wall-hanging.webp",
    ],
    product_details: ["13 × 20 inch"],
    product_price: 399,
    product_category: "living",
    product_subcategory: "wall_hanging",
  },
  {
    product_id: "47018167-b940-4005-b1fa-2c04bb124333",
    product_name: "Chandelier Hanging Table",
    product_link: "chandelier-hanging-table",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/hanging-table/chandelier-hanging-table.webp",
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/hanging-table/chandelier-hanging-table-closeup.webp",
    ],
    product_details: ["14 × 34 inch"],
    product_price: 1399,
    product_category: "living",
    product_subcategory: "hanging_table",
  },
  {
    product_id: "fb75e61e-ec9f-4fa7-969c-751e4cd98a8c",
    product_name: "Chandelier Hanging Corner Table",
    product_link: "platinum-luxurious-hammock-chair-with-cushion-set",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/hanging-table/chandelier-hanging-corner-table.webp",
    ],
    product_details: ["14 × 34 inch"],
    product_price: 1399,
    product_category: "living",
    product_subcategory: "hanging_table",
  },
  {
    product_id: "94397dd7-c321-4469-848b-33def04b4bac",
    product_name: '"Water Lily" Macramé Lamp Shade',
    product_link: "water-lily-macrame-lamp-shade",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/lamp-shade/water-lily-macrame-lamp-shade.webp",
    ],
    product_details: ["18 × 42 inch"],
    product_price: 1399,
    product_category: "living",
    product_subcategory: "lamp_shade",
  },
  {
    product_id: "a31b8631-1946-428c-8915-46cd8615a3fe",
    product_name: '"Ranpushedo" Macramé Lamp Shade',
    product_link: "ranpushedo-macrame-lamp-shade",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/lamp-shade/ranpushedo-macrame-lamp-shade.webp",
    ],
    product_details: ["18 × 42 inch"],
    product_price: 1399,
    product_category: "living",
    product_subcategory: "lamp_shade",
  },
  {
    product_id: "aa5b3718-f420-43da-b112-ccbd69e9124a",
    product_name: '"Tassel Twilight" Macramé Lamp Shade',
    product_link: "tassel-twilight-macrame-lamp-shade",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/lamp-shade/tassel-twilight-macrame-lamp-shade.webp",
    ],
    product_details: ["20 × 28 inch"],
    product_price: 1399,
    product_category: "living",
    product_subcategory: "lamp_shade",
  },
  {
    product_id: "e36bfc4d-ae7c-4593-a20a-d4e2a5948900",
    product_name: '"Table Top Chandelier" Macramé Lamp Shade',
    product_link: "table-top-chandelier-macrame-lamp-shade",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/lamp-shade/table-top-chandelier-macrame-lamp-shade.webp",
    ],
    product_details: ["20 × 28 inch"],
    product_price: 1399,
    product_category: "living",
    product_subcategory: "lamp_shade",
  },
  {
    product_id: "74ba8fdc-2c58-48c2-b0fe-9e8d39d7f35f",
    product_name: '"Assyrain" Macramé Lamp Shade',
    product_link: "assyrain-macrame-lamp-shade",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/lamp-shade/assyrain-macrame-lamp-shade.webp",
    ],
    product_details: ["20 × 28 inch"],
    product_price: 1399,
    product_category: "living",
    product_subcategory: "lamp_shade",
  },
  {
    product_id: "d8bf0b7b-f433-427e-b32a-558535bbf590",
    product_name: '"Ivory Zelda" Macramé Lamp Shade',
    product_link: "ivory-zelda-macrame-lamp-shade",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/lamp-shade/ivory-zelda-macrame-lamp-shade.webp",
    ],
    product_details: ["16 × 24 inch"],
    product_price: 799,
    product_category: "living",
    product_subcategory: "lamp_shade",
  },
  {
    product_id: "9fb7011f-222a-40cc-9df4-08652f77871c",
    product_name: '"Boho Radiance" Macramé Lamp Shade',
    product_link: "boho-radiance-macrame-lamp-shade",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/lamp-shade/boho-radiance-macrame-lamp-shade.webp",
    ],
    product_details: ["20 × 32 inch"],
    product_price: 1399,
    product_category: "living",
    product_subcategory: "lamp_shade",
  },
  {
    product_id: "7d61a524-c3da-48c3-89e6-08b7cbeec886",
    product_name: '"Rapunzel" Macramé Lamp Shade',
    product_link: "rapunzel-macrame-lamp-shade",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/lamp-shade/rapunzel-macrame-lamp-shade.webp",
    ],
    product_details: ["16 × 24 inch"],
    product_price: 799,
    product_category: "living",
    product_subcategory: "lamp_shade",
  },
  {
    product_id: "8984024c-39e2-4d11-b071-38615eb5d859",
    product_name: '"Luminous Lattice" Macramé Lamp Shade',
    product_link: "luminous-lattice-macrame-lamp-shade",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/lamp-shade/luminous-lattice-macrame-lamp-shade.webp",
    ],
    product_details: ["16 × 24 inch"],
    product_price: 799,
    product_category: "living",
    product_subcategory: "lamp_shade",
  },
  {
    product_id: "1e5a1db3-82d4-4144-ab6b-ec5c65c20fd8",
    product_name: '"Woven Cascade" Macramé Lamp Shade',
    product_link: "woven-cascade-macrame-lamp-shade",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/lamp-shade/woven-cascade-macrame-lamp-shade.webp",
    ],
    product_details: ["13 × 32 inch"],
    product_price: 799,
    product_category: "living",
    product_subcategory: "lamp_shade",
  },
  {
    product_id: "761f026b-a694-4bc4-aa80-786f7093a90d",
    product_name: '"Fringe Fantasia" Macramé Lamp Shade',
    product_link: "fringe-fantasia-macrame-lamp-shade",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/lamp-shade/fringe-fantasia-macrame-lamp-shade.webp",
    ],
    product_details: ["16 × 20 inch"],
    product_price: 1199,
    product_category: "living",
    product_subcategory: "lamp_shade",
  },
  {
    product_id: "f0db3a56-6cb4-47e2-a6fa-f3773bfdf9d7",
    product_name: '"Dazzling Tassle" Macramé Magazine Holder',
    product_link: "dazzling-tassle-macrame-magazine-holder",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/magazine-holder/dazzling-tassle-macrame-magazine-holder.webp",
    ],
    product_details: ["10 × 20 inch"],
    product_price: 399,
    product_category: "organizer",
    product_subcategory: "magazine_holder",
  },
  {
    product_id: "ab556584-607d-44aa-96de-9f00e1a842a9",
    product_name: '"Knitted Sofa Arm" Macramé Magazine Holder',
    product_link: "knitted-sofa-arm-macrame-magazine-holder",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/magazine-holder/knitted-sofa-arm-macrame-magazine-holder.webp",
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/magazine-holder/knitted-sofa-arm-macrame-magazine-holder-closeup.webp",
    ],
    product_details: ["18 × 12 inch"],
    product_price: 699,
    product_category: "organizer",
    product_subcategory: "magazine_holder",
  },
  {
    product_id: "945edc1e-2f57-47d2-a038-b90fccd03ec7",
    product_name: '"Multi Storage" Macramé Hanging Pockets',
    product_link: "multi-storage-macrame-hanging-pockets",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/magazine-holder/multi-storage-macrame-hanging-pockets.webp",
    ],
    product_details: ["16 × 18 inch", "Set of 3"],
    product_price: 899,
    product_category: "organizer",
    product_subcategory: "magazine_holder",
  },
  {
    product_id: "f9fa56b2-0093-49c3-ab74-1928aef7b179",
    product_name: '"Rainbow Sunburst" Macramé Magazine Holder',
    product_link: "rainbow-sunburst-macrame-magazine-holder",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/magazine-holder/rainbow-sunburst-macrame-magazine-holder.webp",
    ],
    product_details: ["12 × 20 inch"],
    product_price: 499,
    product_category: "organizer",
    product_subcategory: "magazine_holder",
  },
  {
    product_id: "efd1d731-bb51-43a3-9415-e69bcf0a40ea",
    product_name: '"Twine Tempest" Macramé Magazine Holder',
    product_link: "twine-tempest-macrame-magazine-holder",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/magazine-holder/twine-tempest-macrame-magazine-holder.webp",
    ],
    product_details: ["16 × 18 inch"],
    product_price: 899,
    product_category: "organizer",
    product_subcategory: "magazine_holder",
  },
  {
    product_id: "4878ce8a-e1ce-4095-90fd-dd0e380657e8",
    product_name: '"Willow Whirl" Macramé Mirror',
    product_link: "willow-whirl-macrame-mirror",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/mirrors/willow-whirl-macrame-mirror.webp",
    ],
    product_details: ["20 inch round"],
    product_price: 699,
    product_category: "living",
    product_subcategory: "mirror",
  },
  {
    product_id: "54da61fe-c428-4125-86fa-db45a1b60509",
    product_name: 'Powder Blue "Boho Radiance" Macramé Mirror',
    product_link: "powder-blue-boho-radiance-macrame-mirror",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/mirrors/powder-blue-boho-radiance-macrame-mirror.webp",
    ],
    product_details: ["20 inch round"],
    product_price: 699,
    product_category: "living",
    product_subcategory: "mirror",
  },
  {
    product_id: "dba72f99-1ee3-46d6-bf49-7041ff401830",
    product_name: '"Crusty Rusty" Round Macramé Mirror',
    product_link: "crusty-rusty-round-macrame-mirror",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/mirrors/crusty-rusty-round-macrame-mirror.webp",
    ],
    product_details: ["20 inch round"],
    product_price: 699,
    product_category: "living",
    product_subcategory: "mirror",
  },
  {
    product_id: "d24680c1-251e-400e-bbcd-a9208df79a65",
    product_name: '"Tassel Serenity Spiral" Macramé Mirror',
    product_link: "tassel-serenity-spiral-round-macrame-mirror",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/mirrors/tassel-serenity-spiral-round-macrame-mirror.webp",
    ],
    product_details: ["16 inch round"],
    product_price: 599,
    product_category: "living",
    product_subcategory: "mirror",
  },
  {
    product_id: "7da78c97-ce94-4d5f-866e-9c21f32c3d19",
    product_name: '"Off White Boho" Macramé Mirror',
    product_link: "off-white-boho-macrame-mirror",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/mirrors/off-white-boho-macrame-mirror.webp",
    ],
    product_details: ["18 inch round"],
    product_price: 599,
    product_category: "living",
    product_subcategory: "mirror",
  },
  {
    product_id: "1147aff1-05d7-4144-938a-ad41c5ce92b2",
    product_name: '"Gypsy Glow" Round Macramé Mirror',
    product_link: "gypsy-glow-round-macrame-mirror",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/mirrors/gypsy-glow-round-macrame-mirror.webp",
    ],
    product_details: ["20 inch round"],
    product_price: 699,
    product_category: "living",
    product_subcategory: "mirror",
  },
  {
    product_id: "1d03268b-d10b-4eb8-834e-6eeb47c511da",
    product_name: '"Rustic Reflection" Round Macramé Mirror',
    product_link: "rustic-reflection-round-macrame-mirror",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/mirrors/rustic-reflection-round-macrame-mirror.webp",
    ],
    product_details: ["18 inch round"],
    product_price: 599,
    product_category: "living",
    product_subcategory: "mirror",
  },
  {
    product_id: "0bce5c20-d0af-434d-9fa6-4939caa68815",
    product_name: '"Celestial Loop" Round Macramé Mirror',
    product_link: "celestial-loop-round-macrame-mirror",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/mirrors/celestial-loop-round-macrame-mirror.webp",
    ],
    product_details: ["22 inch round"],
    product_price: 799,
    product_category: "living",
    product_subcategory: "mirror",
  },
  {
    product_id: "ab16d701-fee9-485d-865d-7e0fda6160fc",
    product_name: '"Woven Halo" Round Macramé Mirror',
    product_link: "woven-halo-round-macrame-mirror",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/mirrors/woven-halo-round-macrame-mirror.webp",
    ],
    product_details: ["18 inch round"],
    product_price: 599,
    product_category: "living",
    product_subcategory: "mirror",
  },
  {
    product_id: "a1b8968a-6178-4ffc-be08-3405530db224",
    product_name: '"Lush Loom" Round Macramé Mirror',
    product_link: "lush-loom-round-macrame-mirror",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/mirrors/lush-loom-round-macrame-mirror.webp",
    ],
    product_details: ["18 inch round"],
    product_price: 599,
    product_category: "living",
    product_subcategory: "mirror",
  },
  {
    product_id: "02543800-d772-4fb6-b650-968f07c3fd9b",
    product_name: '"Artisan Aura" Round Macramé Mirror',
    product_link: "artisan-aura-round-macrame-mirror",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/mirrors/artisan-aura-round-macrame-mirror.webp",
    ],
    product_details: ["18 inch round"],
    product_price: 599,
    product_category: "living",
    product_subcategory: "mirror",
  },
  {
    product_id: "f3f0dc1a-a414-471b-872f-2ed4dab34f3d",
    product_name: '"Whisker Swing" Macramé Pet Hammock',
    product_link: "whisker-swing-macrame-pet-hammock",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/pet-hammock/whisker-swing-macrame-pet-hammock.webp",
    ],
    product_details: ["16 × 38 inch"],
    product_price: 999,
    product_category: "living",
    product_subcategory: "pet_hammock",
  },
  {
    product_id: "b23394a5-741e-4ed4-90e4-24f678e90691",
    product_name: '"Serenity Sling" Macramé Pet Hammock',
    product_link: "serenity-sling-macrame-pet-hammock",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/pet-hammock/serenity-sling-macrame-pet-hammock.webp",
    ],
    product_details: ["16 × 38 inch"],
    product_price: 999,
    product_category: "living",
    product_subcategory: "pet_hammock",
  },
  {
    product_id: "6b26dcd7-9520-499d-867a-78b43001897a",
    product_name: '"Peachy Perch" Macramé Pet Hammock',
    product_link: "peachy-perch-macrame-pet-hammock",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/pet-hammock/peachy-perch-macrame-pet-hammock.webp",
    ],
    product_details: ["14 × 34 inch"],
    product_price: 799,
    product_category: "living",
    product_subcategory: "pet_hammock",
  },
  {
    product_id: "b7baebec-ecc3-4b3d-889e-0dff7430a704",
    product_name: '"Corded Cocoon" Macramé Pet Hammock',
    product_link: "corded-cocoon-macrame-pet-hammock",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/pet-hammock/corded-cocoon-macrame-pet-hammock.webp",
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/pet-hammock/corded-cocoon-macrame-pet-hammock-closeup.webp",
    ],
    product_details: ["16 × 38 inch"],
    product_price: 999,
    product_category: "living",
    product_subcategory: "pet_hammock",
  },
  {
    product_id: "4f38720b-7e61-4703-b188-d63e1108e115",
    product_name: '"Cuddle Cradle" Macramé Pet Stand',
    product_link: "cuddle-cradle-macrame-pet-stand",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/pet-hammock/cuddle-cradle-macrame-pet-stand.webp",
    ],
    product_details: ["16 × 38 inch"],
    product_price: 999,
    product_category: "living",
    product_subcategory: "pet_hammock",
  },
  {
    product_id: "d9c34a79-afda-44c3-a426-025724bc59f4",
    product_name: '"Boho Buddy" Macramé Pet Hammock',
    product_link: "boho-buddy-macrame-pet-hammock",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/pet-hammock/boho-buddy-macrame-pet-hammock.webp",
    ],
    product_details: ["16 × 38 inch"],
    product_price: 999,
    product_category: "living",
    product_subcategory: "pet_hammock",
  },
  {
    product_id: "cadfef0c-362b-439d-84e7-acab2217449b",
    product_name: '"Threaded Nest" Macramé Pet Hammock',
    product_link: "threaded-nest-macrame-pet-hammock",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/pet-hammock/threaded-nest-macrame-pet-hammock.webp",
    ],
    product_details: ["16 × 38 inch"],
    product_price: 999,
    product_category: "living",
    product_subcategory: "pet_hammock",
  },
  {
    product_id: "b7ef84d5-6a5e-48fa-a418-e0f95d1f62fb",
    product_name: 'Peachy "Whiskers Nook" Macramé Pet Hammock',
    product_link: "peachy-whiskers-nook-macrame-pet-hammock",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/pet-hammock/peachy-whiskers-nook-macrame-pet-hammock.webp",
    ],
    product_details: ["16 × 38 inch"],
    product_price: 999,
    product_category: "living",
    product_subcategory: "pet_hammock",
  },
  {
    product_id: "0d36934c-c51a-4365-bfc5-49d3dbe62ab1",
    product_name: '"Ivory MacraNest" Macramé Pet Hammock',
    product_link: "ivory-macranest-macrame-pet-hammock",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/pet-hammock/ivory-macranest-macrame-pet-hammock.webp",
    ],
    product_details: ["16 × 38 inch"],
    product_price: 999,
    product_category: "living",
    product_subcategory: "pet_hammock",
  },
  {
    product_id: "17c48af8-35c2-4d0e-ad3d-a3e8168bc17d",
    product_name: 'Pewter "Cozy Cascade" Macramé Pet Hammock',
    product_link: "pewter-cozy-cascade-macrame-pet-hammock",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/pet-hammock/pewter-cozy-cascade-macrame-pet-hammock.webp",
    ],
    product_details: ["16 × 38 inch"],
    product_price: 999,
    product_category: "living",
    product_subcategory: "pet_hammock",
  },
  {
    product_id: "e13cb76f-83e1-4171-b22e-58ff1a97b0a0",
    product_name: '"Dreamweaver" Macramé Pet Hammock',
    product_link: "dreamweaver-macrame-pet-hammock",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/pet-hammock/dreamweaver-macrame-pet-hammock.webp",
    ],
    product_details: ["16 × 38 inch"],
    product_price: 999,
    product_category: "living",
    product_subcategory: "pet_hammock",
  },
  {
    product_id: "83919af5-2a44-434b-a572-90e0bbcfa7b3",
    product_name: '"Fur Haven" Macramé Pet Hammock',
    product_link: "fur-haven-macrame-pet-hammock",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/pet-hammock/fur-haven-macrame-pet-hammock.webp",
    ],
    product_details: ["16 × 38 inch"],
    product_price: 999,
    product_category: "living",
    product_subcategory: "pet_hammock",
  },
  {
    product_id: "456d2866-9ee8-4cdd-9731-80bb319f694f",
    product_name: '"Tassel Treasure" Macramé Photo Hanger',
    product_link: "tassel-treasure-macrame-photo-hanger",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/photo-hanger/tassel-treasure-macrame-photo-hanger.webp",
    ],
    product_details: ["34 × 17 inch"],
    product_price: 699,
    product_category: "bedroom",
    product_subcategory: "photo_hanger",
  },
  {
    product_id: "0ce71116-89fe-4525-a66f-00d133e52cd7",
    product_name: '"Threaded Memories" Macramé Photo Hanger',
    product_link: "threaded-memories-macrame-photo-hanger",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/photo-hanger/threaded-memories-macrame-photo-hanger.webp",
    ],
    product_details: ["45 × 32 inch"],
    product_price: 699,
    product_category: "bedroom",
    product_subcategory: "photo_hanger",
  },
  {
    product_id: "34d126e7-4191-4b7a-a962-25a8fd5cd267",
    product_name: '"Memory Wave" Macramé Photo Hanger',
    product_link: "memory-wave-macrame-photo-hanger",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/photo-hanger/memory-wave-macrame-photo-hanger.webp",
    ],
    product_details: ["45 × 34 inch"],
    product_price: 699,
    product_category: "bedroom",
    product_subcategory: "photo_hanger",
  },
  {
    product_id: "fd80ee8b-ea8e-4875-8bf4-44d4ce08bf6e",
    product_name: '"Love Knot Gallery" Macramé Photo Hanger with Shelf Top',
    product_link: "love-knot-gallery-macrame-photo-hanger-with-shelf-top",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/photo-hanger/love-knot-gallery-macrame-photo-hanger-with-shelf-top.webp",
    ],
    product_details: ["42 × 17 inch"],
    product_price: 849,
    product_category: "bedroom",
    product_subcategory: "photo_hanger",
  },
  {
    product_id: "e3fc5405-7e9a-41b0-ba50-aa587306aebd",
    product_name: '"Nostalgic Intertwine" Macramé Photo Hanger',
    product_link: "nostalgic-intertwine-macrame-photo-hanger",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/photo-hanger/nostalgic-intertwine-macrame-photo-hanger.webp",
    ],
    product_details: ["34 × 17 inch"],
    product_price: 699,
    product_category: "bedroom",
    product_subcategory: "photo_hanger",
  },
  {
    product_id: "d67c70cf-8d47-40b2-b008-06a5587fa5d2",
    product_name: '"Flashback Knots" Macramé Photo Hangerwith Top Shelf',
    product_link: "flashback-knots-macrame-photo-hanger-with-top-shelf",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/photo-hanger/flashback-knots-macrame-photo-hanger-with-top-shelf.webp",
    ],
    product_details: ["42 × 17 inch"],
    product_price: 849,
    product_category: "bedroom",
    product_subcategory: "photo_hanger",
  },
  {
    product_id: "db4cb8e3-d879-47d0-a880-d0d9ad59d6e0",
    product_name: '"Black Boho Chic" Macramé Plant Hanger',
    product_link: "black-boho-chic-macrame-plant-hanger",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/plant-hanger/black-boho-chic-macrame-plant-hanger.webp",
    ],
    product_details: ["6 × 34 inch", "Set of 2"],
    product_price: 399,
    product_category: "balcony",
    product_subcategory: "plant_hanger",
  },
  {
    product_id: "9b2455a3-b64e-4c3f-8162-15a83cbbc177",
    product_name: '"Bridesmaid Sleek" Macramé Plant Hanger',
    product_link: "bridesmaid-sleek-macrame-plant-hanger",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/plant-hanger/bridesmaid-sleek-macrame-plant-hanger.webp",
    ],
    product_details: ["6 × 34 inch"],
    product_price: 399,
    product_category: "balcony",
    product_subcategory: "plant_hanger",
  },
  {
    product_id: "af22e53c-3ccf-4862-a5f4-646d2a692dd8",
    product_name: "Classic Macramé Plant Hanger",
    product_link: "classic-macrame-plant-hanger",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/plant-hanger/classic-macrame-plant-hanger.webp",
    ],
    product_details: ["6 × 26 inch"],
    product_price: 199,
    product_category: "balcony",
    product_subcategory: "plant_hanger",
  },
  {
    product_id: "0cedd9dc-a595-43bc-a650-04d1e307737e",
    product_name: '"Classic Seljuq" Macramé Plant Hanger',
    product_link: "classic-seljuq-macrame-plant-hanger",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/plant-hanger/classix-seljuq-macrame-plant-hanger.webp",
    ],
    product_details: ["6 × 34 inch", "Set of 7"],
    product_price: 2799,
    product_category: "balcony",
    product_subcategory: "plant_hanger",
  },
  {
    product_id: "bda2f462-399c-4383-b9a8-06a33706a2d3",
    product_name: '"Crochet Boho Nesty" Macramé Plant Hanger',
    product_link: "crochet-boho-nesty-macrame-plant-hanger",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/plant-hanger/crochet-boho-nesty-macrame-plant-hanger.webp",
    ],
    product_details: ["6 × 34 inch", "Set of 2"],
    product_price: 799,
    product_category: "balcony",
    product_subcategory: "plant_hanger",
  },
  {
    product_id: "c7a73f76-cebc-4733-912e-24c82a18457f",
    product_name: '"Ethereal Embrace" Macramé Plant Hanger',
    product_link: "ethereal-embrace-macrame-plant-hanger",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/plant-hanger/ethereal-embrace-macrame-plant-hanger.webp",
    ],
    product_details: ["6 × 34 inch", "Set of 2"],
    product_price: 399,
    product_category: "balcony",
    product_subcategory: "plant_hanger",
  },
  {
    product_id: "63fb932b-1ad7-4dfa-a3c8-edf220317f83",
    product_name: '"Frosty Tassel" Macramé Plant Hanger',
    product_link: "frosty-tassel-macrame-plant-hanger",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/plant-hanger/frosty-tassel-macrame-plant-hanger.webp",
    ],
    product_details: ["8 × 34 inch", "Set of 3"],
    product_price: 1199,
    product_category: "balcony",
    product_subcategory: "plant_hanger",
  },
  {
    product_id: "9c740465-1de6-482c-8664-a30dae655841",
    product_name: '"Intertwined Crochet" Macramé Plant Hanger',
    product_link: "intertwined-crochet-macrame-plant-hanger",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/plant-hanger/intertwined-crochet-macrame-plant-hanger.webp",
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/plant-hanger/intertwined-crochet-macrame-plant-hanger-closeup.webp",
    ],
    product_details: ["6 × 26 inch"],
    product_price: 399,
    product_category: "balcony",
    product_subcategory: "plant_hanger",
  },
  {
    product_id: "8f68bcb8-71d8-40cc-93c2-828f552f4344",
    product_name: '"Morning Bliss" Macramé Plant Hanger',
    product_link: "morning-bliss-macrame-plant-hanger",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/plant-hanger/morning-bliss-macrame-plant-hanger.webp",
    ],
    product_details: ["6 × 34 inch", "Set of 2"],
    product_price: 799,
    product_category: "balcony",
    product_subcategory: "plant_hanger",
  },
  {
    product_id: "cfd6dbff-e8c1-4246-959f-359672e573c3",
    product_name: 'Solid Black "Diamond Tassel" Macramé Plant Hanger',
    product_link: "solid-black-diamond-tassel-macrame-plant-hanger",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/plant-hanger/solid-black-diamond-tassel-macrame-plant-hanger.webp",
    ],
    product_details: ["6 × 34 inch", "Set of 2"],
    product_price: 799,
    product_category: "balcony",
    product_subcategory: "plant_hanger",
  },
  {
    product_id: "4ae3729a-8834-4863-bc47-98e5f56bdcdb",
    product_name: 'Tangerine "Spiral Twirl" Macramé Plant Hanger',
    product_link: "tangerine-spiral-twirl-macrame-plant-hanger",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/plant-hanger/spiral-twirl-tangerine-macrame-plant-hanger.webp",
    ],
    product_details: ["6 × 26 inch", "Set of 2"],
    product_price: 399,
    product_category: "balcony",
    product_subcategory: "plant_hanger",
  },
  {
    product_id: "a7772bef-ade5-419d-b16e-3eb86018b492",
    product_name: '"Tassel Chandelier" Macramé Plant Hanger',
    product_link: "tassel-chandelier-macrame-plant-hanger",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/plant-hanger/tassel-chandelier-macrame-plant-hanger.webp",
    ],
    product_details: ["6 × 34 inch"],
    product_price: 399,
    product_category: "balcony",
    product_subcategory: "plant_hanger",
  },
  {
    product_id: "36f60490-0f91-47b0-9d56-2e6c1f8ae509",
    product_name: '"Bohemian Shield" Macramé Room Partition',
    product_link: "bohemian-shield-macrame-room-partition",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/room-partition/bohemian-shield-macrame-room-partition.webp",
    ],
    product_details: ["18 × 72 inch", "3 panels"],
    product_price: 14999,
    product_category: "living",
    product_subcategory: "room_partition",
  },
  {
    product_id: "25b57310-59b6-4801-86cc-f6b21e01772b",
    product_name: '"Threaded Serenity" Macramé Room Partition',
    product_link: "threaded-serenity-macrame-room-partition",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/room-partition/threaded-serenity-macrame-room-partition.webp",
    ],
    product_details: ["18 × 72 inch", "3 panels"],
    product_price: 14999,
    product_category: "living",
    product_subcategory: "room_partition",
  },
  {
    product_id: "307d8cbd-aa92-4887-b22b-6296f9f5a156",
    product_name: '"Artisan Aperture" Macramé Room Partition',
    product_link: "artisan-aperture-macrame-room-partition",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/room-partition/artisan-aperture-macrame-room-partition.webp",
    ],
    product_details: ["18 × 72 inch", "3 panels"],
    product_price: 14999,
    product_category: "living",
    product_subcategory: "room_partition",
  },
  {
    product_id: "adf37f3b-10ad-4323-841b-3f2bc1f4002c",
    product_name: '"Crafted Cloister" Macramé Room Partition',
    product_link: "crafted-cloister-macrame-room-partition",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/room-partition/crafted-cloister-macrame-room-partition.webp",
    ],
    product_details: ["18 × 72 inch", "3 panels"],
    product_price: 14999,
    product_category: "living",
    product_subcategory: "room_partition",
  },
  {
    product_id: "a10fb7d7-b1ab-4284-a7e7-20996725b4b2",
    product_name: '"Lunar Lace" Macramé Round Wall Hanging',
    product_link: "lunar-lace-macrame-round-wall-hanging",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/round-wall-hanging/lunar-lace-macrame-round-wall-hanging.webp",
    ],
    product_details: ["16 inch round"],
    product_price: 499,
    product_category: "bedroom",
    product_subcategory: "round_wall_hanging",
  },
  {
    product_id: "3c03cf53-a951-4373-a26c-2825a18627e7",
    product_name: '"Sunflower Weave" Macramé Round Wall Hanging',
    product_link: "sunflower-weave-macrame-round-wall-hanging",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/round-wall-hanging/sunflower-weave-macrame-round-wall-hanging.webp",
    ],
    product_details: ["18 inch round"],
    product_price: 699,
    product_category: "bedroom",
    product_subcategory: "round_wall_hanging",
  },
  {
    product_id: "df3892db-e5db-4685-baa6-87991a11537b",
    product_name: '"Whispering Wheels" Macramé Round Wall Hanging',
    product_link: "whispering-wheels-macrame-round-wall-hanging",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/round-wall-hanging/whispering-wheels-macrame-round-wall-hanging.webp",
    ],
    product_details: ["18 inch round"],
    product_price: 699,
    product_category: "bedroom",
    product_subcategory: "round_wall_hanging",
  },
  {
    product_id: "9c8be31e-a531-4622-959a-7e5da9f474df",
    product_name: '"Earth Bound Echoes" Macramé Round Wall Hanging',
    product_link: "earth-bound-echoes-macrame-round-wall-hanging",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/round-wall-hanging/earth-bound-echoes-macrame-round-wall-hanging.webp",
    ],
    product_details: ["18 inch round"],
    product_price: 699,
    product_category: "bedroom",
    product_subcategory: "round_wall_hanging",
  },
  {
    product_id: "5ec2b3c4-6131-43ef-a42b-d1fa17008128",
    product_name: '"Sacred Circles" Macramé Round Wall Hanging',
    product_link: "sacred-circles-macrame-round-wall-hanging",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/round-wall-hanging/sacred-circles-macrame-round-wall-hanging.webp",
    ],
    product_details: ["22 inch round"],
    product_price: 899,
    product_category: "bedroom",
    product_subcategory: "round_wall_hanging",
  },
  {
    product_id: "5d15d179-b86c-41f3-96a0-a52590767459",
    product_name: '"Eternal Orbit" Macramé Round Wall Hanging',
    product_link: "eternal-orbit-macrame-round-wall-hanging",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/round-wall-hanging/eternal-orbit-macrame-round-wall-hanging.webp",
    ],
    product_details: ["15 inch round"],
    product_price: 499,
    product_category: "bedroom",
    product_subcategory: "round_wall_hanging",
  },
  {
    product_id: "94a50db1-8f20-4f5e-acb0-7eec3477252a",
    product_name: '"Enchanted Rings" Macramé Round Wall Hanging',
    product_link: "enchanted-rings-macrame-round-wall-hanging",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/round-wall-hanging/enchanted-rings-macrame-round-wall-hanging.webp",
    ],
    product_details: ["22 inch round"],
    product_price: 899,
    product_category: "bedroom",
    product_subcategory: "round_wall_hanging",
  },
  {
    product_id: "cebef19b-d06d-44c4-9529-a6b2ef48cb1c",
    product_name: '"Celestial Threads" Macramé Round Wall Hanging',
    product_link: "celestial-threads-macrame-round-wall-hanging",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/round-wall-hanging/celestial-threads-macrame-round-wall-hanging.webp",
    ],
    product_details: ["22 inch round"],
    product_price: 899,
    product_category: "bedroom",
    product_subcategory: "round_wall_hanging",
  },
  {
    product_id: "fcab3af2-03cf-4243-8769-c8aa3a2ccd5f",
    product_name: '"Ethereal Eclipse" Macramé Round Wall Hanging',
    product_link: "ethereal-eclipse-macrame-round-wall-hanging",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/round-wall-hanging/ethereal-eclipse-macrame-round-wall-hanging.webp",
    ],
    product_details: ["22 inch round"],
    product_price: 899,
    product_category: "bedroom",
    product_subcategory: "round_wall_hanging",
  },
  {
    product_id: "a71fda1d-fb2e-41b6-8e4e-f4db4cb9d409",
    product_name: '"Lavender Wave" Macramé Round Wall Hanging',
    product_link: "lavender-wave-macrame-round-wall-hanging",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/round-wall-hanging/lavender-wave-macrame-round-wall-hanging.webp",
    ],
    product_details: ["22 inch round"],
    product_price: 899,
    product_category: "bedroom",
    product_subcategory: "round_wall_hanging",
  },
  {
    product_id: "a718be9e-fd59-4d04-9943-a1a3d0c93a1e",
    product_name: '"Morning Blossom" Macramé Round Wall Hanging',
    product_link: "morning-blossom-macrame-round-wall-hanging",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/round-wall-hanging/morning-blossom-round-macrame-hanging.webp",
    ],
    product_details: ["22 inch round"],
    product_price: 899,
    product_category: "bedroom",
    product_subcategory: "round_wall_hanging",
  },
  {
    product_id: "40858cca-d4e6-459a-86f5-c3c948f9ad17",
    product_name: '"Twisted Tranquility" Macramé Round Wall Hanging',
    product_link: "twisted-tranquility-macrame-round-wall-hanging",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/round-wall-hanging/twisted-tranquility-round-macrame-hanging.webp",
    ],
    product_details: ["22 inch round"],
    product_price: 899,
    product_category: "bedroom",
    product_subcategory: "round_wall_hanging",
  },
  {
    product_id: "3519a928-e894-476a-9a37-2abd3918cd25",
    product_name: '"Cozy Knotty" Macramé Stool',
    product_link: "cozy-knotty-macrame-stool",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/stool/cozy-knotty-macrame-stool.webp",
    ],
    product_details: ["14 × 20 inch"],
    product_price: 1199,
    product_category: "living",
    product_subcategory: "stool",
  },
  {
    product_id: "b267e55e-7c91-4504-babb-b5a5eb3ac5d0",
    product_name: '"Loom Lounge" Macramé Stool',
    product_link: "loom-lounge-macrame-stool",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/stool/loom-lounge-macrame-stool.webp",
    ],
    product_details: ["14 × 20 inch"],
    product_price: 1199,
    product_category: "living",
    product_subcategory: "stool",
  },
  {
    product_id: "e27cf4e0-80bf-4bec-8c67-e0f269123130",
    product_name: '"Cross Boho Bundle" Macramé Stool',
    product_link: "cross-boho-bundle-macrame-stool",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/stool/cross-boho-bundle-macrame-stool.webp",
    ],
    product_details: ["14 × 20 inch"],
    product_price: 1199,
    product_category: "living",
    product_subcategory: "stool",
  },
  {
    product_id: "65ceff4b-46a3-4367-920d-cfdc61b11879",
    product_name: '"Tassel Serenity" Macramé Stool',
    product_link: "tassel-serenity-macrame-stool",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/stool/tassel-serenity-macrame-stool.webp",
    ],
    product_details: ["14 × 20 inch", "Set of 4"],
    product_price: 4799,
    product_category: "living",
    product_subcategory: "stool",
  },
  {
    product_id: "a2dd6fd7-a26f-42e7-99a9-9aad6c2f8112",
    product_name: '"Ottoman Tassel" Macramé Stool',
    product_link: "ottoman-tassel-macrame-stool",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/stool/ottoman-tassel-macrame-stool.webp",
    ],
    product_details: ["14 × 20 inch"],
    product_price: 1199,
    product_category: "living",
    product_subcategory: "stool",
  },
  {
    product_id: "951de7d7-9df2-4ded-b8f6-e5b94fa830bf",
    product_name: "Handcrafted Wall Mounted Macramé Fruit Basket",
    product_link: "handcrafted-wall-mounted-macrame-fruit-basket",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/fruit-basket/handcrafted-wall-mounted-macrame-fruit-basket.webp",
    ],
    product_details: ["12 × 30 inch"],
    product_price: 999,
    product_category: "dining",
    product_subcategory: "storage_basket",
  },
  {
    product_id: "df7ddaeb-850c-4de9-92d3-35fb12b1d067",
    product_name: '"Teardrop" Nested Farmhouse Kitchen Basket',
    product_link: "teardrop-nested-farmhouse-kitchen-basket",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/fruit-basket/teardrop-nested-farmhouse-kitchen-basket.webp",
    ],
    product_details: ["7 × 12 inch"],
    product_price: 199,
    product_category: "dining",
    product_subcategory: "storage_basket",
  },
  {
    product_id: "6b1e0f77-1db1-4cbc-b64a-2bbb145c02c9",
    product_name: '"Vintage Silo-type" Macramé Storage Basket',
    product_link: "vintage-silo-type-macrame-storage-basket",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/fruit-basket/vintage-silo-type-macrame-storage-basket.webp",
    ],
    product_details: ["8 × 16 inch", "Can be used as Bottle Holder"],
    product_price: 299,
    product_category: "dining",
    product_subcategory: "storage_basket",
  },
  {
    product_id: "dec4789e-d696-4126-9d92-a0fab3abe6dc",
    product_name: "Navy Blue Three-tier Macramé Hanging Storage Basket",
    product_link: "navy-blue-three-tier-macrame-hanging-storage-basket",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/fruit-basket/navy-blue-three-tier-macrame-hanging-storage-basket.webp",
    ],
    product_details: ["12 × 42 inch"],
    product_price: 1399,
    product_category: "dining",
    product_subcategory: "storage_basket",
  },
  {
    product_id: "69a7864b-6387-4b40-9683-4ec538987cb3",
    product_name: '"MacraBloom Pod" Nest Hanger Basket',
    product_link: "macrabloom-pod-nest-hanger-basket",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/fruit-basket/macrabloom-pod-nest-hanger-basket.webp",
    ],
    product_details: ["5 × 18 inch"],
    product_price: 149,
    product_category: "dining",
    product_subcategory: "storage_basket",
  },
  {
    product_id: "595f72cd-7191-46e2-998f-9cd50902b7b3",
    product_name: "Mustard Yellow Three-tier Macramé Hanging Storage Basket",
    product_link: "mustard-yellow-three-tier-macrame-hanging-storage-basket",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/fruit-basket/mustard-yellow-three-tier-macrame-hanging-storage-basket.webp",
    ],
    product_details: ["12 × 42 inch"],
    product_price: 1399,
    product_category: "dining",
    product_subcategory: "storage_basket",
  },
  {
    product_id: "d522b6a6-7b2a-497c-9488-ab0b8cf009eb",
    product_name: "Ivory Two-tier Macramé Hanging Storage Basket",
    product_link: "ivory-two-tier-macrame-hanging-storage-basket",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/fruit-basket/ivory-two-tier-macrame-hanging-storage-basket.webp",
    ],
    product_details: ["12 × 42 inch"],
    product_price: 899,
    product_category: "dining",
    product_subcategory: "storage_basket",
  },
  {
    product_id: "29dec101-a4ac-47de-b6ab-b7548be3d8c4",
    product_name: '"Tassel Tropic" Nest Hanger Basket',
    product_link: "tassel-tropic-nest-hanger-basket",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/fruit-basket/tassel-tropic-nest-hanger-basket.webp",
    ],
    product_details: ["5 × 18 inch", "Set of 3"],
    product_price: 449,
    product_category: "dining",
    product_subcategory: "storage_basket",
  },
  {
    product_id: "a66b5d80-21b6-46c9-b500-f978cd15311a",
    product_name: "Charcoal One-tier Macramé Hanging Storage Basket",
    product_link: "charcoal-one-tier-macrame-hanging-storage-basket",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/fruit-basket/charcoal-one-tier-macrame-hanging-storage-basket.webp",
    ],
    product_details: ["12 × 42 inch"],
    product_price: 499,
    product_category: "dining",
    product_subcategory: "storage_basket",
  },
  {
    product_id: "9fb40d8e-9230-4a96-9ef8-787045b1999a",
    product_name: "Frosty Three-tier Macramé Storage Basket",
    product_link: "frosty-three-tier-macrame-storage-basket",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/fruit-basket/frosty-three-tier-macrame-storage-basket.webp",
    ],
    product_details: ["12 × 42 inch"],
    product_price: 1399,
    product_category: "dining",
    product_subcategory: "storage_basket",
  },
  {
    product_id: "f0410fa5-9fb8-4881-9688-cc676ce0fc71",
    product_name: "Table Top Two-tier Macramé Storage Basket",
    product_link: "table-top-two-tier-macrame-storage-basket",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/fruit-basket/table-top-two-tier-macrame-storage-basket.webp",
    ],
    product_details: ["12 × 30 inch"],
    product_price: 999,
    product_category: "dining",
    product_subcategory: "storage_basket",
  },
  {
    product_id: "1530b86c-94ae-4fe7-bfd4-6f1b37fc6a0c",
    product_name: '"Harmony Fringe" Macramé Table Runner',
    product_link: "harmony-fringe-macrame-table-runner",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/table-runner/harmony-fringe-macrame-table-runner.webp",
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/table-runner/harmony-fringe-macrame-table-runner-another.webp",
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/table-runner/harmony-fringe-macrame-table-runner-closeup.webp",
    ],
    product_details: ["13 × 72 inch"],
    product_price: 899,
    product_category: "dining",
    product_subcategory: "table_runner",
  },
  {
    product_id: "85b84e27-d6d8-48ed-bcc1-a51654f590ef",
    product_name: '"Alabaster Paths" Macramé Table Runner',
    product_link: "alabaster-paths-macrame-table-runner",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/table-runner/alabaster-paths-macrame-table-runner.webp",
    ],
    product_details: ["13 × 72 inch"],
    product_price: 899,
    product_category: "dining",
    product_subcategory: "table_runner",
  },
  {
    product_id: "ce36e272-1251-46ae-ba77-c74ccadceafc",
    product_name: '"Sunrise Weave" Macramé Table Runner',
    product_link: "sunrise-weave-macrame-table-runner",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/table-runner/sunrise-weave-macrame-table-runner.webp",
    ],
    product_details: ["13 × 84 inch", "Set of 2"],
    product_price: 1799,
    product_category: "dining",
    product_subcategory: "table_runner",
  },
  {
    product_id: "96e92055-0285-4d63-a575-7aa6f81640c6",
    product_name: '"Nomad Trail" Macramé Table/Sofa Runner',
    product_link: "nomad-trail-macrame-table-sofa-runner",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/table-runner/nomad-trail-macrame-table-sofa-runner.webp",
    ],
    product_details: ["13 × 56 inch", "Can be used as Sofa Runner"],
    product_price: 699,
    product_category: "dining",
    product_subcategory: "table_runner",
  },
  {
    product_id: "6b5cb5e4-a658-4aba-a6c2-54893ba4b1fc",
    product_name: '"Diamond Weave" Macramé Table Runner',
    product_link: "diamond-weave-macrame-table-runner",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/table-runner/diamond-weave-macrame-table-runner-closeup.webp",
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/table-runner/diamond-weave-macrame-table-runner.webp",
    ],
    product_details: ["13 × 72 inch"],
    product_price: 899,
    product_category: "dining",
    product_subcategory: "table_runner",
  },
  {
    product_id: "7333756c-7be7-4adc-aa8a-2d5843b37c4f",
    product_name: '"Eternal Knot" Macramé Towel Hanger',
    product_link: "eternal-knot-macrame-towel-hanger",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/towel-hanger/eternal-knot-macrame-towel-hanger.webp",
    ],
    product_details: ["2 × 18 inch", "Set of 3"],
    product_price: 599,
    product_category: "organizer",
    product_subcategory: "towel_hanger",
  },
  {
    product_id: "c8a4537d-e2ec-48ba-87e3-28b9ee1120ab",
    product_name: '"Lattice Grace" Macramé Towel Hanger',
    product_link: "lattice-grace-macrame-towel-hanger",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/towel-hanger/lattice-grace-macrame-towel-hanger.webp",
    ],
    product_details: ["2 × 18 inch", "Set of 2"],
    product_price: 299,
    product_category: "organizer",
    product_subcategory: "towel_hanger",
  },
  {
    product_id: "69320e97-6642-4974-a84e-0c863ebec946",
    product_name: '"Vintage Twist" Macramé Towel Hanger',
    product_link: "vintage-twist-macrame-towel-hanger",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/towel-hanger/vintage-twist-macrame-towel-hanger.webp",
    ],
    product_details: ["2 × 18 inch", "Set of 3"],
    product_price: 549,
    product_category: "organizer",
    product_subcategory: "towel_hanger",
  },
  {
    product_id: "8e11ee8e-b5bb-4eaf-a447-d80cdb5c54c4",
    product_name: '"Desert Breeze" Macramé Towel Hanger',
    product_link: "desert-breeze-macrame-towel-hanger",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/towel-hanger/desert-breeze-macrame-towel-hanger.webp",
    ],
    product_details: ["2 × 18 inch", "Set of 3"],
    product_price: 549,
    product_category: "organizer",
    product_subcategory: "towel_hanger",
  },
  {
    product_id: "0416b255-c03a-4d44-b1a9-4a8092bf3ad9",
    product_name: '"Meadow Lines" Macramé Towel Hanger',
    product_link: "meadow-lines-macrame-towel-hanger",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/towel-hanger/meadow-lines-macrame-towel-hanger.webp",
    ],
    product_details: ["2 × 18 inch", "Set of 3"],
    product_price: 549,
    product_category: "organizer",
    product_subcategory: "towel_hanger",
  },
  {
    product_id: "e2af8bc8-98e9-4d41-892a-eaa2b9052a6c",
    product_name: '"Rustic Elegance" Macramé Toy hammock',
    product_link: "rustic-elegance-macrame-toy-hammock",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/toy-hammock/rustic-elegance-macrame-toy-hammock.webp",
    ],
    product_details: ["42 × 55 inch"],
    product_price: 699,
    product_category: "bedroom",
    product_subcategory: "toy_hammock",
  },
  {
    product_id: "1eb3cda2-8560-4fc1-971f-a229a4985126",
    product_name: '"Gossamer Grid" Macramé Toy hammock',
    product_link: "gossamer-grid-macrame-toy-hammock",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/toy-hammock/gossamer-grid-macrame-toy-hammock.webp",
    ],
    product_details: ["32 × 16 inch"],
    product_price: 599,
    product_category: "bedroom",
    product_subcategory: "toy_hammock",
  },
  {
    product_id: "47172bd8-ba5f-417d-a22b-5c9b9ed6f020",
    product_name: '"Solstice Weave" Macramé Wall Shelf',
    product_link: "solstice-weave-macrame-wall-shelf",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/wall-shelf/solstice-weave-macrame-wall-shelf.webp",
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/wall-shelf/solstice-weave-macrame-wall-shelf-another.webp",
    ],
    product_details: ["16 × 38 inch"],
    product_price: 1199,
    product_category: "organizer",
    product_subcategory: "wall_shelf",
  },
  {
    product_id: "cbeaeef5-f2f6-49f9-9698-a9dee0372a52",
    product_name: '"Golden Horizon" Macramé Wall Shelf',
    product_link: "golden-horizon-macrame-wall-shelf",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/wall-shelf/golden-horizon-macrame-wall-shelf.webp",
    ],
    product_details: ["17 × 22 inch"],
    product_price: 799,
    product_category: "organizer",
    product_subcategory: "wall_shelf",
  },
  {
    product_id: "1235d75d-7c0a-4dcc-8bbb-961c610d977f",
    product_name: '"Peachy Strands" Macramé Wall Shelf',
    product_link: "peachy-strands-macrame-wall-shelf",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/wall-shelf/peachy-strands-macrame-wall-shelf.webp",
    ],
    product_details: ["14 × 38 inch"],
    product_price: 1399,
    product_category: "organizer",
    product_subcategory: "wall_shelf",
  },
  {
    product_id: "d8487a63-f07d-4a47-ba90-64693e117d22",
    product_name: '"Bohemian Cascade" Macramé Wall Shelf',
    product_link: "bohemian-cascade-macrame-wall-shelf",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/wall-shelf/bohemian-cascade-macrame-wall-shelf.webp",
    ],
    product_details: ["17 × 32 inch"],
    product_price: 799,
    product_category: "organizer",
    product_subcategory: "wall_shelf",
  },
  {
    product_id: "ebce6306-b328-42b1-810a-affa33150f10",
    product_name: '"Desert Bloom" Macramé Wall Shelf',
    product_link: "desert-bloom-macrame-wall-shelf",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/wall-shelf/desert-bloom-macrame-wall-shelf.webp",
    ],
    product_details: ["16 × 32 inch"],
    product_price: 799,
    product_category: "organizer",
    product_subcategory: "wall_shelf",
  },
  {
    product_id: "9cb3fc85-cc88-423e-86fd-865e8d18245d",
    product_name: '"Harmony Fringe" Macramé Wall Shelf',
    product_link: "harmony-fringe-macrame-wall-shelf",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/wall-shelf/harmony-fringe-macrame-wall-shelf.webp",
    ],
    product_details: ["20 × 42 inch"],
    product_price: 1599,
    product_category: "organizer",
    product_subcategory: "wall_shelf",
  },
  {
    product_id: "1b988842-94c0-4bb8-9cde-9a741832a0aa",
    product_name: '"Amber Woven" Macramé Wall Shelf',
    product_link: "amber-woven-macrame-wall-shelf",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/wall-shelf/amber-woven-macrame-wall-shelf.webp",
    ],
    product_details: ["17 × 34 inch"],
    product_price: 899,
    product_category: "organizer",
    product_subcategory: "wall_shelf",
  },
  {
    product_id: "3a5cf5a3-bc85-48ed-8fe0-33ed73bdc62f",
    product_name: '"Twilight Tapestry" Macramé Wall Shelf',
    product_link: "twilight-tapestry-macrame-wall-shelf",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/wall-shelf/twilight-tapestry-macrame-wall-shelf.webp",
    ],
    product_details: ["16 × 20 inch"],
    product_price: 799,
    product_category: "organizer",
    product_subcategory: "wall_shelf",
  },
  {
    product_id: "97724aa0-c62c-4e06-b4ea-8b6c92d01939",
    product_name: '"Daisy Bliss" Macramé Cushion Cover',
    product_link: "daisy-bliss-macrame-cushion-cover",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/cushion-cover/daisy-bliss-macrame-cushion-cover.webp",
    ],
    product_details: ["16 × 16 inch"],
    product_price: 699,
    product_category: "living",
    product_subcategory: "cushion_cover",
  },
  {
    product_id: "a71efbfc-1a69-4b40-bf25-b9b038c806ed",
    product_name: '"Blue My Mind" Macramé Cushion Cover',
    product_link: "blue-my-mind-macrame-cushion-cover",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/cushion-cover/blue-my-mind-macrame-cushion-cover.webp",
    ],
    product_details: ["16 × 16 inch"],
    product_price: 699,
    product_category: "living",
    product_subcategory: "cushion_cover",
  },
  {
    product_id: "f9897f58-fee9-487e-a3b8-469001aa673c",
    product_name: '"Daisy Peachy Hues" Macramé Cushion Cover',
    product_link: "daisy-peachy-hues-macrame-cushion-cover",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/cushion-cover/daisy-peachy-hues-macrame-cushion-cover.webp",
    ],
    product_details: ["16 × 16 inch"],
    product_price: 699,
    product_category: "living",
    product_subcategory: "cushion_cover",
  },
  {
    product_id: "950c632f-62ad-41d8-b870-7653c71a5d34",
    product_name: '"Custom Intricate" Macramé Cushion Cover',
    product_link: "custom-intricate-macrame-cushion-cover",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/cushion-cover/custom-intricate-macrame-cushion-cover.webp",
    ],
    product_details: ["16 × 16 inch"],
    product_price: 499,
    product_category: "living",
    product_subcategory: "cushion_cover",
  },
  {
    product_id: "abd0e0f7-a4dc-4e19-b2be-c9966286d57b",
    product_name: '"Double Fringe" Cross Knitted Macramé Cushion Cover',
    product_link: "double-fringe-cross-knitted-macrame-cushion-cover",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/cushion-cover/double-fringe-cross-knitted-macrame-cushion-cover.webp",
    ],
    product_details: ["16 × 16 inch"],
    product_price: 499,
    product_category: "living",
    product_subcategory: "cushion_cover",
  },
  {
    product_id: "c4bd97ba-ea6d-4233-81af-2d64d20256c8",
    product_name: '"Babylonian Handmade" Macramé Pillow Cover',
    product_link: "babylonian-handmade-macrame-pillow-cover",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/cushion-cover/babylonian-handmade-macrame-pillow-cover.webp",
    ],
    product_details: ["12 × 20 inch"],
    product_price: 499,
    product_category: "living",
    product_subcategory: "cushion_cover",
  },
  {
    product_id: "356d2fa9-961a-4f05-a724-ba02716e8fb0",
    product_name: '"Boholicious Tan" Macramé Cushion Cover',
    product_link: "boholicious-tan-macrame-cushion-cover",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/cushion-cover/boholicious-tan-macrame-cushion-cover.webp",
    ],
    product_details: ["16 × 16 inch"],
    product_price: 499,
    product_category: "living",
    product_subcategory: "cushion_cover",
  },
  {
    product_id: "f40ba43c-fb2b-44f2-b492-ecbb85d511bb",
    product_name: '"Ivory Tusker" Handcrafted Macramé Cushion Cover',
    product_link: "ivory-tusker-handcrafted-macrame-cushion-cover",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/cushion-cover/ivory-tusker-handcrafted-macrame-cushion-cover.webp",
    ],
    product_details: ["16 × 16 inch"],
    product_price: 499,
    product_category: "living",
    product_subcategory: "cushion_cover",
  },
  {
    product_id: "0d1e207c-a76a-420b-a250-ff6525714276",
    product_name: '"Gossamer Grid" Macramé Table Placemat',
    product_link: "gossamer-grid-macrame-table-placemat",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/table-runner/gossamer-grid-macrame-table-runner.webp",
    ],
    product_details: ["14 × 42 inch"],
    product_price: 699,
    product_category: "dining",
    product_subcategory: "table_placemat",
  },
  {
    product_id: "82a49e4c-ecf1-4b96-8efa-857a6c8341aa",
    product_name: '"Meadow Lines" Macramé Table Placemat',
    product_link: "meadow-lines-macrame-table-placemat",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/table-runner/meadow-lines-macrame-table-runner.webp",
    ],
    product_details: ["13 × 42 inch"],
    product_price: 499,
    product_category: "dining",
    product_subcategory: "table_placemat",
  },
  {
    product_id: "aa72f74a-cbe4-4aff-8028-bb1959ed9e43",
    product_name: '"Boho Bud" Tissue/Napkin Holder',
    product_link: "boho-bud-tissue-napkin-holder",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/fruit-basket/boho-bud-tissue-holder.webp",
    ],
    product_details: ["8 × 10 inch"],
    product_price: 299,
    product_category: "dining",
    product_subcategory: "tissue_paper_holder",
  },
  {
    product_id: "e7bc10d1-8e7c-485c-9cce-476641fd8d60",
    product_name: '"Platinum Luxurious" Hammock Chair with Cushion Set',
    product_link: "platinum-luxurious-hammock-chair-with-cushion-set",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/hammock-chair/platinum-luxurious-hammock-chair-with-cushion-set.webp",
    ],
    product_details: ["35 × 72 inch"],
    product_price: 7999,
    product_category: "balcony",
    product_subcategory: "hammock_chair",
  },
  {
    product_id: "55437042-31fa-4444-a58d-6b08aba5e7a9",
    product_name: '"Frosty Lilac" Hammock Chair with Cushion Set',
    product_link: "frosty-lilac-hammock-chair-with-cushion-set",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/hammock-chair/frosty-lilac-hammock-chair-with-cushion-set.webp",
    ],
    product_details: ["35 × 72 inch"],
    product_price: 7999,
    product_category: "balcony",
    product_subcategory: "hammock_chair",
  },
  {
    product_id: "3162a4cc-ce6f-42b9-9c2a-a7a422761682",
    product_name: '"Ivory Handcrafted" Hammock Chair',
    product_link: "ivory-handcrafted-hammock-chair",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/hammock-chair/ivory-handcrafted-hammock-chair.webp",
    ],
    product_details: ["30 × 48 inch"],
    product_price: 4999,
    product_category: "balcony",
    product_subcategory: "hammock_chair",
  },
  {
    product_id: "77de97ea-5ed8-4006-922b-e17b3ac5784f",
    product_name: '"Winter Black" Hammock Chair with Cushion Set',
    product_link: "winter-black-hammock-chair-with-cushion-set",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/hammock-chair/winter-black-hammock-chair-with-cushion-set.webp",
    ],
    product_details: ["35 × 72 inch"],
    product_price: 7999,
    product_category: "balcony",
    product_subcategory: "hammock_chair",
  },
  {
    product_id: "9d61385d-5c21-4ba9-b458-2e9832a4be43",
    product_name: '"Tangerine" Hammock Chair with Cushion Set',
    product_link: "tangerine-hammock-chair-with-cushion-set",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/hammock-chair/tangerine-hammock-chair-with-cushion-set.webp",
    ],
    product_details: ["35 × 72 inch"],
    product_price: 7999,
    product_category: "balcony",
    product_subcategory: "hammock_chair",
  },
  {
    product_id: "eb534108-b963-486b-a7d6-054540976948",
    product_name: '"Pure Frosty White" Hammock Chair with Cushion Set',
    product_link: "pure-frosty-white-hammock-chair-with-cushion-set",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/hammock-chair/pure-frosty-white-hammock-chair-with-cushion-set.webp",
    ],
    product_details: ["30 × 48 inch"],
    product_price: 4999,
    product_category: "balcony",
    product_subcategory: "hammock_chair",
  },
  {
    product_id: "8b4d1f5c-3a75-4df9-b332-6b8bd951ee23",
    product_name: '"Ivory Luxury" Hammock Chair with Cushion Set',
    product_link: "ivory-luxury-hammock-chair-with-cushion-set",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/hammock-chair/ivory-luxury-hammock-chair-with-cushion-set.webp",
    ],
    product_details: ["35 × 72 inch"],
    product_price: 7999,
    product_category: "balcony",
    product_subcategory: "hammock_chair",
  },
  {
    product_id: "724d248e-3538-4d4d-ad47-95a00b7c106b",
    product_name: '"Emerald Green" Hammock Chair with Cushion Set',
    product_link: "emerald-green-hammock-chair-with-cushion-set",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/hammock-chair/emerald-green-hammock-chair-with-cushion-set.webp",
    ],
    product_details: ["35 × 72 inch"],
    product_price: 7999,
    product_category: "balcony",
    product_subcategory: "hammock_chair",
  },
  {
    product_id: "98d0de0d-7e40-4722-a4ca-db83ca4882d4",
    product_name: '"Mango Aethernest" Macramé Swing Chair with Cushion Set',
    product_link: "mango-aethernest-macrame-swing-chair-with-cushion-set",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/swing-chair/mango-aethernest-macrame-swing-chair-with-cushion-set.webp",
    ],
    product_details: [
      "40 inches diameter",
      "Capacity: 160kg",
      "Double Seater",
      "Cushion Set Included",
    ],
    product_price: 12999,
    product_category: "balcony",
    product_subcategory: "swing_chair",
  },
  {
    product_id: "0c1093e6-08c7-417a-a178-ef01e3e6b445",
    product_name: '"Barbie Pink" Macramé Swing Chair with Cushion Set',
    product_link: "barbie-pink-macrame-swing-chair-with-cushion-set",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/swing-chair/barbie-pink-macrame-swing-chair-with-cushion-set.webp",
    ],
    product_details: [
      "30 inches diameter",
      "Capacity: 110kg",
      "Single Seater",
      "Cushion Set Included",
    ],
    product_price: 10999,
    product_category: "balcony",
    product_subcategory: "swing_chair",
  },
  {
    product_id: "a7ea1a1a-6be8-4937-8b88-17ef2688918d",
    product_name: '"Whispering Weave" Macramé Swing Chair with Cushion Set',
    product_link: "whispering-weave-macrame-swing-chair-with-cushion-set",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/swing-chair/whispering-weave-macrame-swing-chair-with-cushion-set.webp",
    ],
    product_details: [
      "30 inches diameter",
      "Capacity: 110kg",
      "Single Seater",
      "Cushion Set Included",
    ],
    product_price: 10999,
    product_category: "balcony",
    product_subcategory: "swing_chair",
  },
  {
    product_id: "6b439744-d730-46e4-853a-8daee98393f0",
    product_name: '"Peacock Hue" Macramé Swing Chair with Cushion Set',
    product_link: "peacock-hue-macrame-swing-chair-with-cushion-set",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/swing-chair/peacock-hue-macrame-swing-chair-with-cushion-set.webp",
    ],
    product_details: [
      "40 inches diameter",
      "Capacity: 160kg",
      "Double Seater",
      "Cushion Set Included",
    ],
    product_price: 12999,
    product_category: "balcony",
    product_subcategory: "swing_chair",
  },
  {
    product_id: "e399befe-d732-4167-8fca-2d553f7776a1",
    product_name: '"Amethyst Knot" Macramé Swing Chair with Cushion Set',
    product_link: "amethyst-knot-macrame-swing-chair-with-cushion-set",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/swing-chair/amethyst-knot-macrame-swing-chair-with-cushion-set.webp",
    ],
    product_details: [
      "35 inches diameter",
      "Capacity: 120kg",
      "Single Seater",
      "Cushion Set Included",
    ],
    product_price: 11999,
    product_category: "balcony",
    product_subcategory: "swing_chair",
  },
  {
    product_id: "8ff75387-7164-4206-ada2-bdb34d05fabf",
    product_name: '"Peach Burst Lounge" Macramé Swing Chair with Cushion Set',
    product_link: "peach-burst-lounge-macrame-swing-chair-with-cushion-set",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/swing-chair/peach-burst-lounge-macrame-swing-chair-with-cushion-set.webp",
    ],
    product_details: [
      "60 inches diameter",
      "Capacity: 200kg",
      "Deluxe Double Seater",
      "Cushion Set Included",
    ],
    product_price: 18999,
    product_category: "balcony",
    product_subcategory: "swing_chair",
  },
  {
    product_id: "e3daadaa-d9c7-4e80-9977-03530ba9b7cc",
    product_name: '"Black Waver" Macramé Swing Chair with Cushion Set',
    product_link: "black-waver-macrame-swing-chair-with-cushion-set",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/swing-chair/black-waver-macrame-swing-chair-with-cushion-set.webp",
    ],
    product_details: [
      "70 inches diameter",
      "Capacity: 200kg",
      "Deluxe Double Seater",
      "Cushion Set Included",
    ],
    product_price: 22999,
    product_category: "balcony",
    product_subcategory: "swing_chair",
  },
  {
    product_id: "789b949f-09b9-4b2a-8fc7-18cc9f9ca266",
    product_name: '"Cacao Cosmos" Macramé Swing Chair with Cushion Set',
    product_link: "cacao-cosmos-macrame-swing-chair-with-cushion-set",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/swing-chair/cacao-cosmos-macrame-swing-chair-with-cushion-set.webp",
    ],
    product_details: [
      "50 inches diameter",
      "Capacity: 200kg",
      "Deluxe Double Seater",
      "Cushion Set Included",
    ],
    product_price: 12999,
    product_category: "balcony",
    product_subcategory: "swing_chair",
  },
  {
    product_id: "f3e4620b-ddd6-4310-8926-7dd57c16381f",
    product_name: '"Boho Beige Breeze" Macramé Swing Chair with Cushion Set',
    product_link: "boho-beige-breeze-macrame-swing-chair-with-cushion-set",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/swing-chair/boho-beige-breeze-macrame-swing-chair-with-cushion-set.webp",
    ],
    product_details: [
      "30 inches diameter",
      "Capacity: 110kg",
      "Single Seater",
      "Cushion Set Included",
    ],
    product_price: 10999,
    product_category: "balcony",
    product_subcategory: "swing_chair",
  },
  {
    product_id: "0b0493a6-74ab-4ba4-9505-45429ad57fdb",
    product_name: '"Lilac Comfyzone" Macramé Swing Chair with Cushion Set',
    product_link: "lilac-comfyzone-macrame-swing-chair-with-cushion-set",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/swing-chair/lilac-comfyzone-macrame-swing-chair-with-cushion-set.webp",
    ],
    product_details: [
      "60 inches diameter",
      "Capacity: 200kg",
      "Deluxe Double Seater",
      "Cushion Set Included",
    ],
    product_price: 18999,
    product_category: "balcony",
    product_subcategory: "swing_chair",
  },
  {
    product_id: "d25c7f28-2de3-42c4-9455-806ffe6b7527",
    product_name: '"Mocha Brown Luxury" Macramé Swing Chair with Cushion Set',
    product_link: "mocha-brown-luxury-macrame-swing-chair-with-cushion-set",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/swing-chair/mocha-brown-luxury-macrame-swing-chair-with-cushion-set.webp",
    ],
    product_details: [
      "70 inches diameter",
      "Capacity: 200kg",
      "Deluxe Double Seater",
      "Cushion Set Included",
    ],
    product_price: 22999,
    product_category: "balcony",
    product_subcategory: "swing_chair",
  },
  {
    product_id: "0d39fdd9-6050-4a90-a380-4a1e23302eba",
    product_name: '"Sage Sanctuary" Macramé Swing Chair with Cushion Set',
    product_link: "sage-sanctuary-macrame-swing-chair-with-cushion-set",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/swing-chair/sage-sanctuary-macrame-swing-chair-with-cushion-set.webp",
    ],
    product_details: [
      "30 inches diameter",
      "Capacity: 110kg",
      "Single Seater",
      "Cushion Set Included",
    ],
    product_price: 10999,
    product_category: "balcony",
    product_subcategory: "swing_chair",
  },
  {
    product_id: "6eafd3dc-008f-4e53-a7e3-0636fb22d62d",
    product_name: '"Cobalt Cocoon" Macramé Swing Chair with Cushion Set',
    product_link: "cobalt-cocoon-macrame-swing-chair-with-cushion-set",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/swing-chair/cobalt-cocoon-macrame-swing-chair-with-cushion-set.webp",
    ],
    product_details: [
      "35 inches diameter",
      "Capacity: 120kg",
      "Single Seater",
      "Cushion Set Included",
    ],
    product_price: 11999,
    product_category: "balcony",
    product_subcategory: "swing_chair",
  },
  {
    product_id: "a88f8080-1c91-4840-a239-973a543acc83",
    product_name: '"Earthly retreat" Macramé Swing Chair with Cushion Set',
    product_link: "earthly-retreat-macrame-swing-chair-with-cushion-set",
    product_images: [
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/swing-chair/earthly-retreat-macrame-swing-chair-with-cushion-set.webp",
    ],
    product_details: [
      "30 inches diameter",
      "Capacity: 110kg",
      "Single Seater",
      "Cushion Set Included",
    ],
    product_price: 10999,
    product_category: "balcony",
    product_subcategory: "swing_chair",
  },
];
