import NavigationBar from "../../components/ui/NavBar";
import Footer from "../../components/Footer";

const Terms = () => {
  return (
    <>
      <NavigationBar />
      <div className="container lg:px-24 px-4 pt-[7rem] md:pt-32 text-justify">
        <p className="self-center whitespace-nowrap text-3xl font-semibold">
          Terms of Use
        </p>
        <p>Last Updated on August 28th, 2024</p>
        <p className="mt-5">
          These Terms of Use ("Terms") govern your access to and use of the
          services provided by Knots of Macrame ("KOM," "we," "us," or "our").
          By accessing or using our website, mobile applications, and other
          online services (collectively, the "Services"), you agree to be bound
          by these Terms. Please read them carefully before using our Services:
        </p>
        <div className="lg:ml-14 ml-8 mt-2">
          <ul className="list-decimal">
            <li className="mt-5">
              <text className="font-bold">User Eligibility: </text>
              <text>
                You must be at least 18 years old and have the legal capacity to
                enter into a contract to use our Services. By using our
                Services, you represent and warrant that you meet these
                eligibility requirements.
              </text>
            </li>
            <li className="mt-5">
              <text className="font-bold">Registration and Account:</text>
              <text>
                To access certain features of our Services, you may need to
                register for an account. When registering, you must provide
                accurate and complete information. You are responsible for
                maintaining the confidentiality of your account credentials and
                for all activities that occur under your account. You agree to
                notify us immediately of any unauthorized use of your account or
                any other breach of security.
              </text>
            </li>
            <li className="mt-5">
              <text className="font-bold">Use of the Services: </text>
              <ul className="list-disc ml-5">
                <li>
                  Personal Use: Our Services are intended for personal and
                  non-commercial use only. You may not use our Services for any
                  commercial purposes without our prior written consent.
                </li>
                <li>
                  Compliance with Laws: You agree to use our Services in
                  compliance with all applicable laws, regulations, and
                  third-party rights.
                </li>
                <li>
                  Prohibited Activities: You are prohibited from engaging in any
                  activities that may:
                  <ul className="ml-5 list-decimal">
                    <li>
                      Violate these Terms or any other applicable agreements or
                      policies;
                    </li>
                    <li>
                      Infringe upon the intellectual property or other
                      proprietary rights of others;
                    </li>
                    <li>
                      Interfere with or disrupt the operation of our Services or
                      the servers or networks connected to our Services;
                    </li>
                    <li>
                      Introduce viruses, malware, or any other harmful code;
                    </li>
                    <li>
                      Collect or harvest personal information of other users
                      without their consent;
                    </li>
                    <li>
                      Engage in any fraudulent, deceptive, or unlawful
                      activities.
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li className="mt-5">
              <text className="font-bold">Intellectual Property:</text>
              <ul className="list-disc ml-5">
                <li>
                  Ownership: The content, features, and functionality of our
                  Services, including but not limited to text, graphics, logos,
                  images, and software, are owned by KOM or our licensors and
                  are protected by copyright, trademark, and other intellectual
                  property laws.
                </li>
                <li>
                  Limited License: Subject to your compliance with these Terms,
                  we grant you a limited, non-exclusive, non-transferable, and
                  revocable license to access and use our Services for personal,
                  non-commercial purposes.
                </li>
              </ul>
            </li>
            <li className="mt-5">
              <text className="font-bold">Privacy: </text>
              <text>
                Your use of our Services is subject to our Privacy Policy, which
                explains how we collect, use, share, and protect your personal
                information. By using our Services, you consent to the
                collection and use of your personal information as described in
                the Privacy Policy.
              </text>
            </li>
            <li className="mt-5">
              <text className="font-bold">Limitation of Liability: </text>
              <text>
                To the maximum extent permitted by applicable law, KOM and its
                affiliates, directors, officers, employees, agents, and
                licensors shall not be liable for any indirect, incidental,
                special, consequential, or punitive damages, including but not
                limited to damages for loss of profits, goodwill, use, data, or
                other intangible losses, arising out of or in connection with
                your use of our Services.
              </text>
            </li>
            <li className="mt-5">
              <text className="font-bold">Modifications to the Services: </text>
              <text>
                We reserve the right to modify or discontinue our Services,
                temporarily or permanently, with or without notice. You agree
                that we shall not be liable to you or any third party for any
                modification, suspension, or discontinuation of our Services.
              </text>
            </li>
            <li className="mt-5">
              <text className="font-bold">
                Governing Law and Jurisdiction:{" "}
              </text>
              <text>
                These Terms shall be governed by and construed in accordance
                with the laws of [Jurisdiction]. Any disputes arising out of or
                in connection with these Terms shall be subject to the exclusive
                jurisdiction of the courts of [Jurisdiction].
              </text>
            </li>
            <li className="mt-5">
              <text className="font-bold">Entire Agreement: </text>
              <text>
                These Terms, together with our Privacy Policy, constitute the
                entire agreement between you and KOM concerning your use of our
                Services and supersede any prior or contemporaneous agreements,
                communications, or understandings.
              </text>
            </li>
            <li className="mt-5">
              <text className="font-bold">Contact Us: </text>
              <text>
                If you have any questions or concerns about these Terms or our
                Services, please contact us at{" "}
                <a
                  href="mailto:info@knotsofmacrame.com"
                  className="underline text-blue-600"
                >
                  info@knotsofmacrame.com
                </a>
                .
              </text>
            </li>
          </ul>
        </div>
        <p className="mt-5">
          By accessing or using our Services, you acknowledge that you have
          read, understood, and agreed to be bound by these Terms of Use.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default Terms;
