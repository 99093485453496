import React from "react";
import { CheckCircle, Delivery, Support } from "../assets/icons/Icons";

const Features = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-3 container px-5">
      <div className="flex flex-row items-center space-x-4">
        <Delivery />
        <div className="flex flex-col">
          <p className="text-sm font-bold mb-1">FREE SHIPPING</p>
          <p className="text-xs text-[#040404]">Free shipping all over India</p>
        </div>
      </div>
      <div className="flex flex-row items-center space-x-4">
        <CheckCircle />
        <div className="flex flex-col">
          <p className="text-sm font-bold mb-1">QUALITY ASSURANCE</p>
          <p className="text-xs text-[#040404]">Premium materials used</p>
        </div>
      </div>
      <div className="flex flex-row items-center space-x-4">
        <Support />
        <div className="flex flex-col">
          <p className="text-sm font-bold mb-1">ONLINE SUPPORT</p>
          <p className="text-xs text-[#040404]">Fast & reliable support</p>
        </div>
      </div>
    </div>
  );
};

export default Features;
