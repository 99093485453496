import Footer from "../components/Footer";
import Hero from "../components/Hero";
import NavigationBar from "../components/ui/NavBar";

const Main = () => {
  return (
    <div className="flex flex-col items-center min-h-screen">
      <NavigationBar />
      <Hero />
      <Footer />
    </div>
  );
};

export default Main;
