import React from "react";
import DynamicList from "../../ui/DynamicList";
import GetInTouch from "../GetInTouch";
import TableofContent from "../../ui/TableofContent";

const Blog = () => {
  const tocData = [
    {
      id: "introduction",
      label: "Introduction to Macrame Swings in Home Décor",
    },
    {
      id: "choosing-the-right-spot",
      label: "Choosing the Right Spot for Your Macrame Swing",
    },
    {
      id: "boho-chic-vibe",
      label: "Boho-Chic Vibe with Layered Textiles",
    },
    {
      id: "minimalist-aesthetic",
      label: "Minimalist Aesthetic with Neutral Ttones",
    },
    {
      id: "indoor-jungle",
      label: "Indoor Jungle: Adding Plants Around Your Swing",
    },
    {
      id: "cozy-reading-nook",
      label: "Creating a Cozy Reading Nook",
    },
    {
      id: "rustic-farmhouse-styling",
      label: "Rustic Farmhouse Styling",
    },
    {
      id: "pop-of-color",
      label: "Pop of Color with Vibrant Accessories",
    },
    {
      id: "scandinavian-simplicity",
      label: "Scandinavian Simplicity",
    },
    {
      id: "artistic-statement-piece",
      label: "Artistic Statement Piece",
    },
    {
      id: "relaxing-meditation-space",
      label: "Creating a Relaxing Meditation Space",
    },
    {
      id: "conclusion",
      label: "Conclusion: Unleashing Your Creativity",
    },
  ];

  const list1 = [
    {
      header: "Space Availability",
      body: "Ensure there’s enough room for the swing to move freely without bumping into furniture or walls.",
    },
    {
      header: "Natural Light",
      body: "Position your swing near a window or a well-lit area to make it a more inviting space.",
    },
    {
      header: "Functionality",
      body: "Think about how you want to use the swing. Will it be a relaxing nook, a reading corner, or purely decorative?",
    },
  ];

  const list2 = [
    {
      header: "Cushions and Pillows",
      body: "Use soft, vibrant cushions in a variety of sizes and textures to create a comfortable and relaxed vibe.",
    },
    {
      header: "Throws",
      body: "Drape colorful or patterned throws over the swing to add a warm and inviting feel.",
    },
  ];

  const list3 = [
    {
      header: "Choose Subtle Colors",
      body: "Stick to a neutral palette with whites, beiges, grays, and soft pastels.",
    },
    {
      header: "Keep It Simple",
      body: "Avoid cluttering the space with too many accessories. Opt for a single cushion or light throw in a muted color.",
    },
  ];

  const list4 = [
    {
      header: "Hanging Plants",
      body: "Install hanging planters above or around the swing to create a floating garden effect.",
    },
    {
      header: "Potted Plants",
      body: "Place large potted plants nearby to enhance the nature-inspired vibe.",
    },
  ];

  const list5 = [
    {
      header: "Bookshelves or Side Tables",
      body: "Place a small bookshelf or side table next to the swing for easy access to your reading material.",
    },
    {
      header: "Lighting",
      body: "Add a floor lamp or pendant light above the swing to create a well-lit space for reading. Use warm lighting to make the area feel cozy.",
    },
    {
      header: "Soft Fabrics",
      body: "Enhance comfort with cushions and a soft blanket for those long reading sessions.",
    },
  ];

  const list6 = [
    {
      header: "Wooden Accents",
      body: "Incorporate natural wood furniture and vintage pieces, like wooden side tables or benches.",
    },
    {
      header: "Textured Rugs",
      body: "Use jute or handwoven rugs to add warmth and texture to the space.",
    },
    {
      header: "Earthy Colors",
      body: "Stick to neutral, earthy hues like warm browns, creams, and muted greens to complement the farmhouse vibe.",
    },
  ];

  const list7 = [
    {
      header: "Bright Cushions and Pillows",
      body: "Choose cushions in bold hues like turquoise, mustard yellow, or deep red to create contrast with the swing’s natural tones.",
    },
    {
      header: "Colorful Rugs",
      body: "Use a brightly patterned rug beneath the swing to ground the space and add visual interest.",
    },
    {
      header: "Wall Art",
      body: "Hang colorful artwork or tapestries on the walls behind the swing to create a dynamic and lively backdrop.",
    },
  ];

  const list8 = [
    {
      header: "Light and Airy Colors",
      body: "Stick to light tones like white, soft gray, and pale blue.",
    },
    {
      header: "Functional Design",
      body: "Incorporate minimalist furniture with clean lines, like a sleek side table or a simple lamp.",
    },
    {
      header: "Cozy Textures",
      body: "Add a few cozy elements like a fluffy blanket or a sheepskin throw to soften the look while keeping the overall design uncluttered.",
    },
  ];

  const list9 = [
    {
      header: "Bold Décor Elements",
      body: "Surround the swing with eye-catching décor, like bold art prints, geometric rugs, or modern sculptures.",
    },
    {
      header: "Dramatic Lighting",
      body: "Use statement lighting like a chandelier or unique pendant light to draw attention to the swing.",
    },
    {
      header: "Unusual Accessories",
      body: "Opt for quirky, artistic accessories like a custom-made pillow or hand-painted throws that reflect your personal style.",
    },
  ];

  const list10 = [
    {
      header: "Soft Lighting",
      body: "Add candles or dimmable floor lamps to create a calming ambiance.",
    },
    {
      header: "Comfortable Seating",
      body: "Use a soft cushion or bolster for added support during meditation.",
    },
    {
      header: "Mindfulness Elements",
      body: "Surround the swing with calming elements like incense holders, crystals, or a small water fountain to enhance the Zen vibe.",
    },
  ];

  return (
    <div className="container space-y-10">
      <h1 className="text-3xl lg:text-5xl text-gold font-medium font-gt-super mb-10">
        10 Creative Ways to Style a Macrame Swing in Your Living Space
      </h1>
      <img
        src="https://knots-of-macrame.s3.ap-south-1.amazonaws.com/blogs/balcony-swings.webp"
        alt="swing-chairs"
      />

      <TableofContent tocData={tocData} />

      <section id="introduction" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Introduction to Macrame Swings in Home Décor
        </h1>
        <p>
          <a href="/" className="text-blue-500 hover:underline">
            Macramé
          </a>{" "}
          swings have become a popular home décor choice, blending bohemian
          style with functionality. Not only do they add a touch of artistic
          flair to your living space, but they also serve as a cozy spot to
          relax. Macrame swings are versatile, easily customizable, and suit
          various interior styles, making them a perfect addition to any room.
          Whether you want to create a cozy reading corner, a bold statement
          piece, or a serene meditation area, there are countless ways to style
          your Macrame swing creatively.
        </p>
      </section>

      <section id="choosing-the-right-spot" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Choosing the Right Spot for Your Macrame swing
        </h1>
        <p>
          Before you start styling your{" "}
          <a href="/" className="text-blue-500 hover:underline">
            Macramé
          </a>{" "}
          swing, it’s important to choose the perfect location in your living
          space. Some factors to consider include:
        </p>
        <DynamicList listType="list-disc" data={list1} />
        <p>
          A well-placed swing can elevate the overall aesthetic of your living
          space and become a focal point of the room.
        </p>
      </section>

      <section id="boho-chic-vibe" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Boho-Chic Vibe with Layered Textiles
        </h1>

        <p>
          For those who love bohemian style, layering textiles is a perfect way
          to enhance the look of your{" "}
          <a href="/" className="text-blue-500 hover:underline">
            Macramé
          </a>{" "}
          swing. Add:
        </p>
        <DynamicList listType="list-decimal" data={list2} />
        <p>
          This style pairs well with other boho elements such as patterned rugs,
          Macrame wall hangings, and woven baskets, making the space feel cozy
          and eclectic.
        </p>
      </section>

      <section id="minimalist-aesthetic" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Minimalist Aesthetic with Neutral Tones
        </h1>
        <p>
          If you prefer a more understated look, go for a minimalist style with
          neutral tones. To achieve this:
        </p>
        <DynamicList listType="list-decimal" data={list3} />
        <p>
          This minimalist approach enhances the swing’s natural beauty and lets
          the intricate{" "}
          <a href="/" className="text-blue-500 hover:underline">
            Macramé
          </a>{" "}
          design shine on its own.
        </p>
      </section>

      <section id="indoor-jungle" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Indoor Jungle: Adding Plants Around Your Swing
        </h1>
        <p>
          Transform your living space into an indoor jungle by surrounding your
          <a href="/" className="text-blue-500 hover:underline">
            Macramé
          </a>{" "}
          swing with lush greenery. Here’s how:
        </p>
        <DynamicList listType="list-disc" data={list4} />
        <p>
          Pairing plants with your swing not only adds a calming, organic feel
          but also brightens up the space, making it feel fresh and lively.
        </p>
      </section>

      <section id="cozy-reading-nook" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Creating a Cozy Reading Nook
        </h1>
        <p>
          Turn your{" "}
          <a href="/" className="text-blue-500 hover:underline">
            Macramé
          </a>{" "}
          swing into the perfect reading nook by surrounding it with your
          favorite books and a few practical elements:
        </p>
        <DynamicList listType="list-disc" data={list5} />
      </section>

      <section id="rustic-farmhouse-styling" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Rustic Farmhouse Styling
        </h1>
        <p>
          For a charming, rustic farmhouse aesthetic, blend your{" "}
          <a href="/" className="text-blue-500 hover:underline">
            Macramé
          </a>{" "}
          swing with natural textures and earthy tones:
        </p>
        <DynamicList listType="list-disc" data={list6} />
      </section>

      <section id="pop-of-color" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Pop of Color with Vibrant Accessories
        </h1>
        <p>
          If you want your{" "}
          <a href="/" className="text-blue-500 hover:underline">
            Macramé
          </a>{" "}
          swing to stand out, consider adding a pop of color with bold, vibrant
          accessories:
        </p>
        <DynamicList listType="list-disc" data={list7} />
      </section>

      <section id="scandinavian-simplicity" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Scandinavian Simplicity
        </h1>
        <p>
          For those who love the clean, crisp lines of Scandinavian design,
          style your{" "}
          <a href="/" className="text-blue-500 hover:underline">
            Macramé
          </a>{" "}
          swing with simplicity and function in mind:
        </p>
        <DynamicList listType="list-disc" data={list8} />
      </section>

      <section id="artistic-statement-piece" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Artistic Statement Piece
        </h1>
        <p>
          Make your{" "}
          <a href="/" className="text-blue-500 hover:underline">
            Macramé
          </a>{" "}
          swing the centerpiece of your living space by turning it into an
          artistic statement piece:
        </p>
        <DynamicList listType="list-disc" data={list9} />
      </section>

      <section id="relaxing-meditation-space" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Creating a Relaxing Meditation Space
        </h1>
        <p>
          For those who want to create a peaceful, relaxing environment, turn
          your{" "}
          <a href="/" className="text-blue-500 hover:underline">
            Macramé
          </a>{" "}
          swing into a tranquil meditation space:
        </p>
        <DynamicList listType="list-disc" data={list10} />
      </section>

      <section id="conclusion" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Conclusion: Unleashing Your Creativity
        </h1>
        <p>
          The beauty of styling a{" "}
          <a href="/" className="text-blue-500 hover:underline">
            Macramé
          </a>{" "}
          swing in your living space lies in the endless possibilities for
          creativity. Whether you prefer a bohemian, minimalist, or artistic
          approach, you can customize your swing’s look to suit your personal
          style and living space. Experiment with different textures, colors,
          and décor elements to make your Macrame swing a beautiful and
          functional addition to your home.
        </p>
      </section>

      <GetInTouch />
    </div>
  );
};

export default Blog;
