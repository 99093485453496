import howtoChoosethePerfectMacrameSwingforYourHome from "../../components/Blogs/content/howtoChoosethePerfectMacrameSwingforYourHome";
import creativeWaystoStyleaMacrameSwinginYourLivingSpace from "../../components/Blogs/content/creativeWaystoStyleaMacrameSwinginYourLivingSpace";
import howtoCareforandMaintainYourMacrameSwing from "../../components/Blogs/content/howtoCareforandMaintainYourMacrameSwing";
import theBenefitsofAddingaMacrameSwingtoYourSpace from "../../components/Blogs/content/theBenefitsofAddingaMacrameSwingtoYourSpace";

export const blogs = [
  {
    id: "how-to-choose-the-perfect-macrame-swing-for-your-home",
    image:
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/blogs/perfect-macrame-swing.webp",
    title: "How to Choose the Perfect Macrame Swing for Your Home",
    text: "Macrame swings have taken the interior design world by storm, offering a unique blend of bohemian charm, functionality, and comfort. The intricate art of macrame, with its detailed knotting techniques, has a rich history dating back to ancient times.",
    blog: howtoChoosethePerfectMacrameSwingforYourHome,
  },
  {
    id: "10-creative-ways-to-style-a-macrame-swing-in-your-living-space",
    image:
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/blogs/balcony-swings.webp",
    title: "10 Creative Ways to Style a Macrame Swing in Your Living Space",
    text: "Macrame swings have become a popular home décor choice, blending bohemian style with functionality. Not only do they add a touch of artistic flair to your living space, but they also serve as a cozy spot to relax.",
    blog: creativeWaystoStyleaMacrameSwinginYourLivingSpace,
  },
  {
    id: "how-to-care-for-and-maintain-your-macrame-swing",
    image:
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/blogs/swing-jhula.webp",
    title: "How to Care for and Maintain Your Macrame Swing",
    text: "Macrame Swings have a way of turning any space into a cozy nook, blending both relaxation and style. However, like any piece of furniture, they require proper care and maintenance to ensure they last.",
    blog: howtoCareforandMaintainYourMacrameSwing,
  },
  {
    id: "the-benefits-of-adding-a-macrame-swing-to-your-space",
    image:
      "https://knots-of-macrame.s3.ap-south-1.amazonaws.com/blogs/comfortable-macrame-swing.webp",
    title: "The Benefits of Adding a Macrame Swing to Your Space",
    text: "Macramé swings have made a remarkable comeback in recent years, blending the charm of handmade craftsmanship with modern-day comfort. These beautiful, intricately knotted swings not only enhance the aesthetic appeal of your home or garden but also offer a cozy and comfortable space for relaxation.",
    blog: theBenefitsofAddingaMacrameSwingtoYourSpace,
  },
];
