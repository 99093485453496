import { useNavigate } from "react-router-dom";
import NavigationBar from "../components/ui/NavBar";
import Lottie from "lottie-react";
import Animation from "../assets/animation/order_placed.json";
import Footer from "../components/Footer";

const OrderPlaced = () => {
  const navigate = useNavigate();
  return (
    <main>
      <NavigationBar />
      <div
        className="lg:flex max-w-screen-2xl mx-auto py-[6rem] md:py-32 justify-evenly"
        id="checkout"
      >
        {/* Left */}
        <div className="flex flex-col p-5 space-y-10 bg-white items-center shadow-md">
          <h1 className="text-3xl border-b pb-4">
            Your order has been placed!
          </h1>
          {/* <img
            src={require("../assets/images/order_placed.gif")}
            alt="Order Placed"
            width={200}
          /> */}
          <Lottie animationData={Animation} loop={true} className="w-64" />
          <div className="flex flex-col lg:flex-row justify-evenly">
            <button
              className="select-none text-black px-3 py-2 bg-gradient-to-b from-cyan-200 to-cyan-400 border border-cyan-300 rounded-md focus:outline-none hover:from-cyan-500 hover:to-cyan-400 w-44"
              onClick={() => navigate("/")}
            >
              Go to Home
            </button>
            <button
              className="select-none text-black px-3 py-2 bg-gradient-to-b from-cyan-200 to-cyan-400 border border-cyan-300 rounded-md focus:outline-none hover:from-cyan-500 hover:to-cyan-400 w-44 my-8 lg:my-0 ml-0 lg:ml-10"
              onClick={() => navigate("/orders")}
            >
              My Orders
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default OrderPlaced;
