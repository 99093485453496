import React, { createContext, useContext, useEffect, useState } from "react";
import {
  GoogleAuthProvider,
  signInWithPopup,
  User,
  onAuthStateChanged,
  signOut as firebaseSignOut,
  getAuth,
} from "firebase/auth";
import { auth } from "../firebase";
import { UserData } from "./types";
import { toast } from "react-toastify";
import { zzeehApiClient } from "./apiClient";

interface AuthContextType {
  user: FirebaseUser | null;
  userData: UserData | undefined;
  setUserData: React.Dispatch<React.SetStateAction<UserData | undefined>>;
  googleSignIn: () => void;
  signOut: () => void;
  fetchUserData: (email: string | null | undefined) => Promise<void>;
}

interface FirebaseUser extends User {}
interface AuthContextProviderProps {
  children: React.ReactNode;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthContextProvider: React.FC<AuthContextProviderProps> = ({
  children,
}) => {
  const [user, setUser] = useState<FirebaseUser | null>(null);
  const [userData, setUserData] = useState<UserData | undefined>();

  const fetchUserData = async (email: string | null | undefined) => {
    if (email) {
      try {
        const res = await zzeehApiClient.get(`/users/email/${email}`);
        if (res.status === 200) {
          setUserData(res.data);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      }
      fetchUserData(currentUser?.email);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const googleSignIn = async () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();

    try {
      const val = await signInWithPopup(auth, provider);
      toast.success("Sign In Successful!");

      // Handle user creation in the external system
      await createOrUpdateUserInBackend(val.user);

      fetchUserData(val.user.email);
    } catch (error) {
      console.error("Google Sign-in Error:", error);
    }
  };

  // Separate function to handle user creation in your backend
  const createOrUpdateUserInBackend = async (user: User) => {
    try {
      // Check if email and displayName are not null
      if (!user.email || !user.displayName) {
        throw new Error("User email or display name is missing.");
      }

      // Proceed with the request if checks pass
      const res = await zzeehApiClient.post(`/users`, {
        full_name: user.displayName, // You can ensure that user.displayName is not null here
        email: user.email, // You can ensure that user.email is not null here
        phone_number: user.phoneNumber || "", // If phone_number is null, send an empty string or handle it accordingly
        user_type: "kom",
      });

      if (res?.statusText === "OK") {
        console.log("User created/updated in backend:", res);
      }
    } catch (error) {
      console.error("Error creating/updating user in backend:", error);
    }
  };

  const signOut = () => {
    firebaseSignOut(auth);
    setUser(null);
    setUserData(undefined);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        userData,
        setUserData,
        googleSignIn,
        signOut,
        fetchUserData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const UserAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthContextProvider");
  }
  return context;
};
