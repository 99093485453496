import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { List } from "../../assets/icons/Icons";

const TableofContent = ({
  tocData,
}: {
  tocData: {
    id: string;
    label: string;
  }[];
}) => {
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleScroll = (id: string) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
      navigate("#" + id);
    }
  };

  return (
    <nav className="bg-white p-5 rounded border">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold">Table of Contents</h2>
        <button
          className="border border-cyan-400 rounded"
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          <List />
        </button>
      </div>

      {!isCollapsed && (
        <ul className="list-disc mt-2 p-5 space-y-2">
          {tocData.map((item) => (
            <li key={item.id}>
              <button
                className="w-full text-left text-sm hover:text-gold hover:underline transition-all duration-200 ease-in-out"
                onClick={() => handleScroll(item.id)}
              >
                {item.label}
              </button>
            </li>
          ))}
        </ul>
      )}
    </nav>
  );
};

export default TableofContent;
