interface icon {
  bg?: string;
}

export const Check = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 6L9 17L4 12"
      stroke="#099268"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Bag = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="20"
    width="20"
    viewBox="0 -960 960 960"
    fill="#000"
  >
    <path d="M220-80q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h110v-10q0-63 43.5-106.5T480-880q63 0 106.5 43.5T630-730v10h110q24 0 42 18t18 42v520q0 24-18 42t-42 18H220Zm0-60h520v-520H630v90q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T570-570v-90H390v90q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T330-570v-90H220v520Zm170-580h180v-10q0-38-26-64t-64-26q-38 0-64 26t-26 64v10ZM220-140v-520 520Z" />
  </svg>
);

export const Account = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="22"
    viewBox="0 -960 960 960"
    width="22"
    fill="#000"
  >
    <path d="M369-480q-27.429 0-45.714-20.5Q305-521 309-548l16-120q8-57 52-94.5T480-800q59 0 103 37.5t52 94.5l16 120q4 27-14.286 47.5Q618.429-480 591-480H369Zm-1-60h224l-16.059-119.226Q571-694 543.5-717 516-740 480-740t-63.447 22.781Q389.106-694.438 384-659l-16 119Zm112 0ZM160-160v-94q0-38 19-65t49-41q67-30 128.5-45T480-420q62 0 123 15.5t127.921 44.694q31.301 14.126 50.19 40.966Q800-292 800-254v94H160Zm60-60h520v-34q0-16-9.5-30.5T707-306q-64-31-117-42.5T480-360q-57 0-111 11.5T252-306q-14 7-23 21.5t-9 30.5v34Zm260 0Z" />
  </svg>
);

export const Add = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 -960 960 960"
    width="24"
    fill="#FFFFFF"
  >
    <path d="M450-450H200v-60h250v-250h60v250h250v60H510v250h-60v-250Z" />
  </svg>
);

export const Remove = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 -960 960 960"
    width="24"
    fill="#FFFFFF"
  >
    <path d="M200-450v-60h560v60H200Z" />
  </svg>
);

export const Delete = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="20"
    viewBox="0 -960 960 960"
    width="20"
    fill="#FFFFFF"
  >
    <path d="M261-120q-24.75 0-42.375-17.625T201-180v-570h-41v-60h188v-30h264v30h188v60h-41v570q0 24-18 42t-42 18H261Zm438-630H261v570h438v-570ZM367-266h60v-399h-60v399Zm166 0h60v-399h-60v399ZM261-750v570-570Z" />
  </svg>
);

export const Edit = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 -960 960 960"
    width="24"
    fill="#000000"
  >
    <path d="M200-200h56l345-345-56-56-345 345v56Zm572-403L602-771l56-56q23-23 56.5-23t56.5 23l56 56q23 23 24 55.5T829-660l-57 57Zm-58 59L290-120H120v-170l424-424 170 170Zm-141-29-28-28 56 56-28-28Z" />
  </svg>
);

export const Phone = ({ bg }: icon) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 28 28"
    fill={`${bg ? bg : "#FFFFFF"}`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_464_175)">
      <path d="M13.703 0H14.2455C16.3609 0.0448438 18.4685 0.555625 20.3509 1.52469C23.1536 2.94437 25.4472 5.33969 26.7427 8.20203C27.5357 9.92359 27.9491 11.8103 28 13.703V14.2439C27.9541 16.5632 27.3377 18.871 26.1877 20.8873C25.1759 22.6751 23.7677 24.2375 22.0899 25.4226C19.8155 27.0501 17.0406 27.9491 14.2461 28H13.7561C11.171 27.9513 8.60125 27.1835 6.4307 25.7753C3.59352 23.9597 1.45086 21.087 0.537578 17.8434C0.199063 16.6753 0.0328125 15.4623 0 14.2477V13.7074C0.0585156 11.1661 0.808281 8.64227 2.18039 6.49961C3.52352 4.38102 5.44688 2.6343 7.68742 1.505C9.54516 0.555625 11.6195 0.0535938 13.703 0ZM6.67461 6.19008C6.37109 6.30875 6.14633 6.61172 6.13102 6.93875C6.09328 10.4809 7.40523 14.0098 9.72398 16.6846C10.9386 18.1032 12.4255 19.2872 14.0766 20.16C16.2165 21.2931 18.6391 21.8903 21.0607 21.869C21.5053 21.852 21.8848 21.4424 21.8723 20.9984C21.8755 20.0151 21.8712 19.0318 21.8739 18.0491C21.8739 17.809 21.8887 17.5509 21.7569 17.3381C21.5977 17.0499 21.2707 16.882 20.9448 16.8935C19.5989 16.887 18.253 16.6075 17.0248 16.0552C16.8088 15.96 16.5955 15.8326 16.3527 15.8309C16.0207 15.8195 15.698 16.0185 15.5526 16.316C15.3355 16.7502 15.1309 17.1899 14.9177 17.6258C13.0375 16.5435 11.4576 14.9581 10.3726 13.0807C10.8194 12.8609 11.2733 12.6536 11.7168 12.4277C12.1209 12.2183 12.2877 11.6769 12.0805 11.2733C11.4712 10.0122 11.1322 8.62039 11.1114 7.2193C11.1054 7.01094 11.1103 6.79 11.0064 6.60187C10.8626 6.31312 10.5498 6.12227 10.2271 6.12719C9.20555 6.12117 8.18398 6.12719 7.16242 6.12391C6.99836 6.12555 6.82828 6.12391 6.67461 6.19008Z" />
    </g>
    <defs>
      <clipPath id="clip0_464_175">
        <rect width="28" height="28" />
      </clipPath>
    </defs>
  </svg>
);

export const Mail = ({ bg }: icon) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill={`${bg ? bg : "#FFFFFF"}`}>
      <g clipPath="url(#clip0_464_177)">
        <path d="M12 0.164404C18.2837 -0.857341 24.8931 3.02669 27.0718 8.9962C28.5009 12.6738 28.2697 16.9567 26.4273 20.4488C24.5779 24.0529 21.0823 26.7613 17.1243 27.6465C13.6672 28.4583 9.90891 27.867 6.86162 26.0474C3.91941 24.3154 1.65321 21.4706 0.640955 18.2094C-0.521917 14.5563 -0.0945969 10.4273 1.82484 7.10317C3.91591 3.3976 7.7723 0.745259 12 0.164404ZM5.77931 8.1879C5.88088 8.81424 5.70225 9.69602 6.39227 10.0459C8.90015 11.3756 11.4676 12.5863 13.9965 13.874C16.5254 12.5933 19.0998 11.3966 21.5937 10.0494C22.2977 9.71002 22.1156 8.81774 22.2206 8.1879C16.7425 8.1774 11.2609 8.1774 5.77931 8.1879ZM5.83185 12.4008C5.86687 14.5738 5.74078 16.7538 5.88089 18.9197C5.98947 19.7805 6.9667 19.889 7.65672 19.854C12.0945 19.819 16.5359 19.8855 20.9737 19.8225C21.6602 19.868 22.2171 19.2416 22.1576 18.5698C22.2136 16.5158 22.1506 14.4618 22.1716 12.4113C19.4185 13.6815 16.8021 15.2666 13.979 16.3654C11.1944 15.2071 8.55689 13.699 5.83185 12.4008Z" />
      </g>
      <defs>
        <clipPath id="clip0_464_177">
          <rect width="28" height="28" />
        </clipPath>
      </defs>
    </g>
  </svg>
);

export const List = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24px"
    viewBox="0 -960 960 960"
    width="24px"
    fill="#22d3ee"
  >
    <path d="M280-600v-80h560v80H280Zm0 160v-80h560v80H280Zm0 160v-80h560v80H280ZM160-600q-17 0-28.5-11.5T120-640q0-17 11.5-28.5T160-680q17 0 28.5 11.5T200-640q0 17-11.5 28.5T160-600Zm0 160q-17 0-28.5-11.5T120-480q0-17 11.5-28.5T160-520q17 0 28.5 11.5T200-480q0 17-11.5 28.5T160-440Zm0 160q-17 0-28.5-11.5T120-320q0-17 11.5-28.5T160-360q17 0 28.5 11.5T200-320q0 17-11.5 28.5T160-280Z" />
  </svg>
);

export const LinkedIn = ({ bg }: icon) => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 32 32"
    fill="#131921"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_257_446)">
      <path d="M13.0704 0.288804C20.015 -1.1195 27.5378 2.72335 30.4651 9.16066C32.6139 13.621 32.4982 19.0765 30.1671 23.4436C27.8982 27.8062 23.5117 31.0005 18.6447 31.769C12.7857 32.8175 6.50405 30.2319 3.0518 25.3983C-0.249183 20.9557 -0.925396 14.7672 1.28564 9.7071C3.33208 4.878 7.89651 1.21286 13.0704 0.288804ZM9.07099 7.37474C8.04332 7.71682 7.66963 9.09847 8.2969 9.95589C9.24004 11.3375 11.9805 10.7289 11.8648 8.90299C11.8871 7.50802 10.2099 6.8594 9.07099 7.37474ZM7.99439 12.0084V22.6706H11.9983V12.0084H7.99439ZM14.6675 12.0084V22.6706H18.5602C18.6269 20.4626 18.4045 18.2369 18.6759 16.0422C18.9473 14.6695 21.0916 14.4518 21.4252 15.9045C21.7722 18.1391 21.4831 20.4182 21.5765 22.6706H25.3446C25.2957 20.3027 25.4647 17.9259 25.2423 15.5624C25.091 14.1852 24.4015 12.6703 23.0001 12.1905C21.4297 11.7107 19.7036 12.2127 18.5202 13.3145C18.5335 12.9902 18.5602 12.3371 18.5736 12.0128C17.2701 12.0084 15.971 12.0084 14.6675 12.0084Z" />
    </g>
    <defs>
      <clipPath id="clip0_257_446">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export const Instagram = ({ bg }: icon) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 28 28"
    fill={`${bg ? bg : "#131921"}`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_464_181)">
      <path d="M6.37815 0.22522C10.4553 -0.217498 14.5869 0.151434 18.6835 0.019395C20.9145 -0.0971099 23.2504 0.388327 24.9839 1.86794C26.9778 3.46017 28.0816 6.01551 27.9923 8.55143C27.9728 12.4582 28.035 16.365 27.9612 20.2718C27.8523 24.3068 24.2882 27.8602 20.2499 27.9573C16.0833 28.0116 11.9129 28.0116 7.74628 27.9573C3.70797 27.8563 0.136067 24.3029 0.0350118 20.2679C-0.0427228 16.8815 0.0388985 13.4912 0.00391795 10.1048C0.00780468 8.56697 -0.0777034 6.97862 0.458665 5.51066C1.35261 2.8893 3.6691 0.815511 6.37815 0.22522ZM21.859 4.74172C20.9145 4.92425 20.7008 6.34949 21.5286 6.81551C22.2399 7.35143 23.3943 6.72231 23.3126 5.8291C23.3476 5.10289 22.5431 4.52037 21.859 4.74172ZM12.2121 7.25046C9.49531 7.93784 7.33818 10.4039 7.05444 13.1961C6.70853 15.8524 8.07277 18.6136 10.3737 19.9767C12.7057 21.4369 15.9006 21.3048 18.1044 19.6543C20.4015 18.031 21.5131 14.9514 20.7629 12.2369C19.9001 8.60192 15.8151 6.19804 12.2121 7.25046ZM12.5891 9.57668C15.2943 8.61357 18.4737 10.7301 18.6291 13.5883C18.975 16.3495 16.3865 18.9553 13.6191 18.6369C11.1316 18.5009 9.07943 16.0815 9.36705 13.6039C9.49531 11.7709 10.8362 10.1165 12.5891 9.57668Z" />
    </g>
    <defs>
      <clipPath id="clip0_464_181">
        <rect width="28" height="28" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const Pinterest = () => (
  <svg
    fill="#FFCB43"
    height="50"
    width="50"
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-143 145 512 512"
  >
    <path
      d="M113,145c-141.4,0-256,114.6-256,256s114.6,256,256,256s256-114.6,256-256S254.4,145,113,145z M113,528.3
    c-12.6,0-24.8-1.9-36.3-5.3c4.9-7.7,10.2-17.6,12.9-27.4c1.6-5.7,9-35.2,9-35.2c4.4,8.5,17.4,15.9,31.3,15.9
    c41.2,0,69.1-37.5,69.1-87.7c0-38-32.2-73.3-81-73.3c-60.8,0-91.5,43.6-91.5,80c0,22,8.3,41.6,26.2,48.9c2.9,1.2,5.5,0,6.4-3.2
    c0.6-2.2,2-7.9,2.6-10.3c0.9-3.2,0.5-4.3-1.8-7.1c-5.1-6.1-8.4-13.9-8.4-25.1c0-32.3,24.2-61.3,63-61.3c34.4,0,53.3,21,53.3,49
    c0,36.9-16.3,68-40.6,68c-13.4,0-23.4-11.1-20.2-24.6c3.8-16.2,11.3-33.7,11.3-45.4c0-10.5-5.6-19.2-17.3-19.2
    c-13.7,0-24.7,14.2-24.7,33.1c0,12.1,4.1,20.2,4.1,20.2s-14,59.4-16.5,69.7c-2.3,9.7-2.6,20.5-2.2,29.4
    C16.5,497.8-15,452.7-15,400.3c0-70.7,57.3-128,128-128s128,57.3,128,128S183.7,528.3,113,528.3z"
    />
  </svg>
);

export const FaceBook = ({ bg }: icon) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    fill="#FFFFFF"
    viewBox="0 0 16 16"
  >
    {" "}
    <g fill={`${bg ? bg : "#FFFFFF"}`}>
      <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />{" "}
    </g>
  </svg>
);

export const Male = (prop: { stroke: string }) => {
  return (
    <svg
      width="55"
      height="55"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.2499 1.00035H28.9997V9.75035M4.07642 11.0765C8.17657 6.9745 14.8247 6.9745 18.9231 11.0765C23.025 15.1768 23.025 21.825 18.9231 25.9235C14.8247 30.0255 8.17657 30.0255 4.07642 25.9235C-0.0254743 21.825 -0.0254743 15.1768 4.07642 11.0765ZM18.9238 11.0765L29 1L18.9238 11.0765Z"
        stroke={prop.stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const FeMale = (prop: { stroke: string }) => {
  return (
    <svg
      width="55"
      height="55"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.3946 3.11312C23.452 1.16762 20.8298 0.0521388 18.0807 0.00178182C15.3315 -0.0485752 12.6702 0.970131 10.6577 2.84318C8.64518 4.71622 7.43866 7.29728 7.29245 10.0423C7.14625 12.7873 8.07179 15.4819 9.87398 17.558L5.55673 21.8742L2.3404 18.6571C2.1974 18.5191 2.00587 18.4427 1.80707 18.4444C1.60827 18.4461 1.4181 18.5258 1.27752 18.6664C1.13694 18.8069 1.0572 18.997 1.05548 19.1958C1.05375 19.3945 1.13017 19.586 1.26829 19.729L4.48462 22.946L0.731473 26.6967C0.659056 26.7667 0.601294 26.8503 0.561557 26.9428C0.52182 27.0353 0.500903 27.1347 0.500029 27.2354C0.499154 27.336 0.518338 27.4359 0.556461 27.529C0.594585 27.6222 0.650884 27.7068 0.722075 27.778C0.793265 27.8492 0.877921 27.9054 0.971102 27.9436C1.06428 27.9817 1.16412 28.0008 1.2648 28C1.36547 27.9991 1.46497 27.9782 1.55747 27.9385C1.64998 27.8987 1.73364 27.841 1.80358 27.7686L5.55673 24.0179L8.77457 27.2334C8.91757 27.3715 9.1091 27.4479 9.3079 27.4462C9.5067 27.4445 9.69687 27.3647 9.83745 27.2242C9.97802 27.0836 10.0578 26.8935 10.0595 26.6948C10.0612 26.496 9.9848 26.3045 9.84668 26.1616L6.62884 22.946L10.9446 18.6314C13.022 20.4295 15.7161 21.3518 18.46 21.2041C21.2038 21.0564 23.7834 19.8503 25.6557 17.8396C27.528 15.8289 28.547 13.1704 28.4983 10.4237C28.4496 7.67694 27.337 5.05623 25.3946 3.11312ZM24.3224 17.0501C23.0499 18.3221 21.4287 19.1883 19.6638 19.5392C17.8989 19.89 16.0696 19.7098 14.4071 19.0213C12.7447 18.3327 11.3238 17.1669 10.3241 15.671C9.32441 14.1752 8.79083 12.4166 8.79083 10.6175C8.79083 8.81854 9.32441 7.05993 10.3241 5.56409C11.3238 4.06824 12.7447 2.90235 14.4071 2.21383C16.0696 1.52531 17.8989 1.34508 19.6638 1.69594C21.4287 2.04679 23.0499 2.91298 24.3224 4.18496C25.1748 5.02554 25.8516 6.02705 26.3135 7.13131C26.7754 8.23558 27.0133 9.4206 27.0133 10.6175C27.0133 11.8145 26.7754 12.9995 26.3135 14.1038C25.8516 15.2081 25.1748 16.2096 24.3224 17.0501Z"
        stroke={prop.stroke}
      />
    </svg>
  );
};

export const Arrow: React.FC = () => (
  <svg
    width="21"
    height="8"
    viewBox="0 0 21 8"
    fill="none"
    style={{ marginLeft: 5 }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.3536 4.35355C20.5488 4.15829 20.5488 3.84171 20.3536 3.64645L17.1716 0.464466C16.9763 0.269204 16.6597 0.269204 16.4645 0.464466C16.2692 0.659728 16.2692 0.976311 16.4645 1.17157L19.2929 4L16.4645 6.82843C16.2692 7.02369 16.2692 7.34027 16.4645 7.53553C16.6597 7.7308 16.9763 7.7308 17.1716 7.53553L20.3536 4.35355ZM0 4.5H20V3.5H0V4.5Z"
      fill="#212529"
    />
  </svg>
);

export const F1: React.FC = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 64 64"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="#444b54ff">
      <path
        fill="#444b54"
        opacity="1.00"
        d=" M 19.18 0.00 L 19.87 0.00 C 21.80 2.50 20.33 5.85 21.40 8.56 C 28.46 9.62 35.76 8.00 42.91 8.63 C 43.00 5.72 42.49 2.53 44.30 0.00 L 44.71 0.00 C 46.47 2.53 46.02 5.68 46.08 8.58 C 50.50 8.18 55.49 8.94 58.34 12.71 C 61.58 16.35 60.97 21.49 61.03 25.98 C 60.84 28.86 61.72 32.14 59.69 34.56 C 59.27 34.20 58.44 33.49 58.03 33.13 C 58.00 29.27 57.99 25.40 58.01 21.54 C 40.67 21.53 23.34 21.53 6.00 21.54 C 6.05 31.73 5.90 41.93 6.06 52.11 C 5.97 56.36 9.58 60.20 13.82 60.42 C 19.20 60.73 24.60 60.44 29.99 60.50 C 31.76 60.31 33.42 60.92 34.80 62.00 C 33.42 63.10 31.75 63.74 29.97 63.56 C 24.35 63.42 18.72 63.78 13.12 63.34 C 7.53 62.86 2.96 57.65 3.05 52.07 C 2.93 41.71 3.02 31.35 3.00 20.99 C 2.78 16.80 4.74 12.53 8.36 10.31 C 11.15 8.37 14.68 8.52 17.92 8.54 C 17.96 5.66 17.63 2.58 19.18 0.00 M 6.10 18.54 C 23.37 18.52 40.64 18.53 57.92 18.54 C 57.10 14.85 53.96 11.63 50.04 11.58 C 38.04 11.50 26.02 11.51 14.02 11.57 C 10.08 11.60 6.87 14.82 6.10 18.54 Z"
      />
    </g>
    <g id="#ffffffff">
      <path
        fill="#ffffff"
        opacity="1.00"
        d=" M 6.10 18.54 C 6.87 14.82 10.08 11.60 14.02 11.57 C 26.02 11.51 38.04 11.50 50.04 11.58 C 53.96 11.63 57.10 14.85 57.92 18.54 C 40.64 18.53 23.37 18.52 6.10 18.54 Z"
      />
      <path
        fill="#ffffff"
        opacity="1.00"
        d=" M 6.00 21.54 C 23.34 21.53 40.67 21.53 58.01 21.54 C 57.99 25.40 58.00 29.27 58.03 33.13 C 58.44 33.49 59.27 34.20 59.69 34.56 C 59.35 36.29 59.48 38.07 59.71 39.80 C 53.76 33.54 42.37 34.36 37.82 41.85 C 33.19 48.49 36.03 57.96 42.74 62.02 C 40.09 61.95 37.45 61.99 34.80 62.00 C 33.42 60.92 31.76 60.31 29.99 60.50 C 24.60 60.44 19.20 60.73 13.82 60.42 C 9.58 60.20 5.97 56.36 6.06 52.11 C 5.90 41.93 6.05 31.73 6.00 21.54 Z"
      />
      <path
        fill="#ffffff"
        opacity="1.00"
        d=" M 48.24 43.19 C 48.87 43.20 50.13 43.21 50.77 43.21 C 50.94 45.12 51.02 47.03 51.09 48.95 C 52.43 50.39 56.57 53.31 53.03 54.64 C 51.12 53.51 49.53 51.89 48.20 50.14 C 47.72 47.84 48.04 45.50 48.24 43.19 Z"
      />
    </g>
    <g id="#71c2ffff">
      <path
        fill="#71c2ff"
        opacity="1.00"
        d=" M 37.82 41.85 C 42.37 34.36 53.76 33.54 59.71 39.80 C 64.44 45.02 64.78 53.62 59.87 58.83 C 55.70 63.76 48.45 64.47 42.74 62.02 C 36.03 57.96 33.19 48.49 37.82 41.85 M 48.24 43.19 C 48.04 45.50 47.72 47.84 48.20 50.14 C 49.53 51.89 51.12 53.51 53.03 54.64 C 56.57 53.31 52.43 50.39 51.09 48.95 C 51.02 47.03 50.94 45.12 50.77 43.21 C 50.13 43.21 48.87 43.20 48.24 43.19 Z"
      />
    </g>
  </svg>
);
export const F2: React.FC = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 64 64"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="#444b54ff">
      <path
        fill="#444b54"
        opacity="1.00"
        d=" M 1.14 4.19 C 3.87 -1.07 11.10 0.40 15.86 1.02 C 15.86 1.51 15.87 2.49 15.87 2.98 C 12.48 4.38 8.60 2.73 5.19 4.01 C 2.18 6.33 4.04 10.72 3.48 13.95 C 3.75 15.62 2.23 16.14 0.85 16.06 C 0.45 12.14 -0.03 7.99 1.14 4.19 Z"
      />
      <path
        fill="#444b54"
        opacity="1.00"
        d=" M 48.14 0.99 C 51.61 0.11 55.35 0.47 58.89 0.79 C 63.39 1.97 63.85 7.14 63.55 11.01 C 63.41 12.93 64.19 16.89 60.99 15.87 C 59.49 12.26 61.80 7.79 59.44 4.51 C 56.57 2.52 52.73 4.07 49.47 3.43 C 48.13 3.27 47.69 2.45 48.14 0.99 Z"
      />
      <path
        fill="#444b54"
        opacity="1.00"
        d=" M 10.68 10.68 C 14.88 10.45 19.10 10.46 23.30 10.67 C 23.32 11.01 23.37 11.69 23.39 12.03 C 23.63 15.80 23.54 19.59 23.32 23.36 C 19.55 23.54 15.78 23.68 12.01 23.46 C 11.71 23.37 11.11 23.18 10.82 23.09 C 10.13 19.01 10.55 14.80 10.68 10.68 M 13.50 13.54 C 13.50 15.87 13.50 18.20 13.50 20.53 C 15.83 20.53 18.16 20.53 20.50 20.53 C 20.50 18.20 20.50 15.87 20.50 13.54 C 18.17 13.53 15.84 13.53 13.50 13.54 Z"
      />
      <path
        fill="#444b54"
        opacity="1.00"
        d=" M 33.55 12.01 C 33.92 11.51 34.64 10.50 35.00 10.00 C 35.36 10.50 36.08 11.50 36.44 12.00 C 36.63 15.80 36.55 19.61 36.28 23.41 C 34.36 23.46 32.43 23.53 30.50 23.58 C 30.54 26.84 30.50 30.10 30.37 33.35 C 27.79 33.22 22.82 34.95 22.80 30.85 C 24.37 30.70 25.93 30.56 27.50 30.43 C 27.48 27.21 27.51 23.99 27.55 20.77 C 29.53 20.62 31.52 20.53 33.50 20.45 C 33.49 17.63 33.43 14.82 33.55 12.01 Z"
      />
      <path
        fill="#444b54"
        opacity="1.00"
        d=" M 40.63 12.03 C 40.66 11.68 40.72 10.97 40.75 10.61 C 44.92 10.50 49.10 10.47 53.27 10.63 C 53.50 14.76 53.87 18.99 53.17 23.08 C 52.88 23.17 52.28 23.36 51.99 23.46 C 48.21 23.68 44.42 23.55 40.65 23.33 C 40.50 19.57 40.33 15.79 40.63 12.03 M 43.50 13.53 C 43.50 15.87 43.50 18.20 43.50 20.53 C 45.83 20.53 48.17 20.53 50.50 20.53 C 50.50 18.20 50.50 15.87 50.50 13.54 C 48.17 13.53 45.83 13.53 43.50 13.53 Z"
      />
      <path
        fill="#444b54"
        opacity="1.00"
        d=" M 37.79 27.82 C 40.82 27.45 43.88 27.45 46.92 27.75 C 46.94 29.68 47.00 31.60 47.03 33.52 C 48.69 33.51 50.34 33.50 52.00 33.56 C 52.50 33.92 53.50 34.66 54.00 35.02 C 53.49 35.39 52.49 36.11 51.99 36.48 C 49.33 36.70 46.65 36.57 44.02 36.14 C 44.02 34.30 43.99 32.45 43.99 30.60 C 41.70 30.40 37.60 31.43 37.79 27.82 Z"
      />
      <path
        fill="#444b54"
        opacity="1.00"
        d=" M 10.00 32.05 C 10.50 31.65 11.50 30.84 12.00 30.44 C 13.23 30.72 16.06 29.96 15.91 31.98 C 16.15 34.01 13.23 33.34 12.00 33.63 C 11.50 33.24 10.50 32.44 10.00 32.05 Z"
      />
      <path
        fill="#444b54"
        opacity="1.00"
        d=" M 33.80 35.73 C 34.41 35.75 35.63 35.78 36.24 35.80 C 36.79 39.95 36.47 44.15 36.37 48.32 C 34.44 48.43 32.50 48.53 30.56 48.61 C 30.54 49.46 30.48 51.15 30.45 51.99 C 30.41 54.01 27.62 54.01 27.48 52.00 C 27.43 49.85 27.56 47.71 27.75 45.58 C 29.67 45.56 31.59 45.53 33.51 45.52 C 33.49 42.25 33.29 38.97 33.80 35.73 Z"
      />
      <path
        fill="#444b54"
        opacity="1.00"
        d=" M 10.82 40.90 C 11.11 40.82 11.70 40.66 11.99 40.58 C 15.77 40.40 19.55 40.57 23.33 40.63 C 23.50 44.42 23.65 48.21 23.45 52.00 C 23.35 52.28 23.16 52.84 23.07 53.12 C 19.03 54.00 14.75 53.50 10.63 53.26 C 10.59 49.15 10.11 44.96 10.82 40.90 M 13.50 43.54 C 13.50 45.87 13.50 48.20 13.50 50.53 C 15.83 50.53 18.17 50.53 20.50 50.53 C 20.50 48.20 20.50 45.87 20.49 43.53 C 18.16 43.53 15.83 43.53 13.50 43.54 Z"
      />
      <path
        fill="#444b54"
        opacity="1.00"
        d=" M 51.99 40.63 C 52.34 40.65 53.02 40.70 53.37 40.73 C 53.55 44.92 53.62 49.13 53.32 53.32 C 49.22 53.48 45.01 53.96 40.98 53.17 C 40.87 52.88 40.66 52.29 40.55 52.00 C 40.36 48.21 40.50 44.42 40.66 40.64 C 44.44 40.55 48.22 40.39 51.99 40.63 M 43.50 43.54 C 43.50 45.87 43.50 48.20 43.50 50.53 C 45.83 50.53 48.16 50.53 50.50 50.53 C 50.50 48.20 50.50 45.87 50.50 43.54 C 48.17 43.53 45.83 43.53 43.50 43.54 Z"
      />
      <path
        fill="#444b54"
        opacity="1.00"
        d=" M 1.00 48.14 C 1.50 48.13 2.49 48.13 2.99 48.12 C 4.70 51.84 1.73 57.03 5.17 59.96 C 8.63 61.21 12.47 60.06 16.05 60.84 C 16.59 64.49 12.26 63.38 10.02 63.58 C 6.43 63.90 1.92 62.97 0.82 58.98 C 0.41 55.42 0.04 51.62 1.00 48.14 Z"
      />
      <path
        fill="#444b54"
        opacity="1.00"
        d=" M 60.96 48.13 C 64.19 47.09 63.41 51.08 63.55 53.02 C 63.85 56.86 63.39 61.96 58.95 63.19 C 55.46 63.58 51.65 64.07 48.28 62.99 C 48.19 62.45 48.02 61.38 47.94 60.85 C 51.69 59.84 56.11 61.68 59.46 59.46 C 61.75 56.17 59.54 51.75 60.96 48.13 Z"
      />
    </g>
    <g id="#ffffffff">
      <path
        fill="#ffffff"
        opacity="1.00"
        d=" M 23.39 12.03 C 26.78 11.98 30.17 12.00 33.55 12.01 C 33.43 14.82 33.49 17.63 33.50 20.45 C 31.52 20.53 29.53 20.62 27.55 20.77 C 27.51 23.99 27.48 27.21 27.50 30.43 C 25.93 30.56 24.37 30.70 22.80 30.85 C 22.82 34.95 27.79 33.22 30.37 33.35 C 30.50 30.10 30.54 26.84 30.50 23.58 C 32.43 23.53 34.36 23.46 36.28 23.41 C 36.55 19.61 36.63 15.80 36.44 12.00 C 37.49 12.01 39.58 12.02 40.63 12.03 C 40.33 15.79 40.50 19.57 40.65 23.33 C 44.42 23.55 48.21 23.68 51.99 23.46 C 52.01 26.83 52.00 30.19 52.00 33.56 C 50.34 33.50 48.69 33.51 47.03 33.52 C 47.00 31.60 46.94 29.68 46.92 27.75 C 43.88 27.45 40.82 27.45 37.79 27.82 C 37.60 31.43 41.70 30.40 43.99 30.60 C 43.99 32.45 44.02 34.30 44.02 36.14 C 46.65 36.57 49.33 36.70 51.99 36.48 C 51.99 37.52 51.99 39.59 51.99 40.63 C 48.22 40.39 44.44 40.55 40.66 40.64 C 40.50 44.42 40.36 48.21 40.55 52.00 C 37.18 52.00 33.82 52.00 30.45 51.99 C 30.48 51.15 30.54 49.46 30.56 48.61 C 32.50 48.53 34.44 48.43 36.37 48.32 C 36.47 44.15 36.79 39.95 36.24 35.80 C 35.63 35.78 34.41 35.75 33.80 35.73 C 33.29 38.97 33.49 42.25 33.51 45.52 C 31.59 45.53 29.67 45.56 27.75 45.58 C 27.56 47.71 27.43 49.85 27.48 52.00 C 26.47 52.00 24.46 52.00 23.45 52.00 C 23.65 48.21 23.50 44.42 23.33 40.63 C 19.55 40.57 15.77 40.40 11.99 40.58 C 12.00 38.26 12.00 35.95 12.00 33.63 C 13.23 33.34 16.15 34.01 15.91 31.98 C 16.06 29.96 13.23 30.72 12.00 30.44 C 12.00 28.11 12.00 25.79 12.01 23.46 C 15.78 23.68 19.55 23.54 23.32 23.36 C 23.54 19.59 23.63 15.80 23.39 12.03 Z"
      />
      <path
        fill="#ffffff"
        opacity="1.00"
        d=" M 13.50 13.54 C 15.84 13.53 18.17 13.53 20.50 13.54 C 20.50 15.87 20.50 18.20 20.50 20.53 C 18.16 20.53 15.83 20.53 13.50 20.53 C 13.50 18.20 13.50 15.87 13.50 13.54 Z"
      />
      <path
        fill="#ffffff"
        opacity="1.00"
        d=" M 43.50 13.53 C 45.83 13.53 48.17 13.53 50.50 13.54 C 50.50 15.87 50.50 18.20 50.50 20.53 C 48.17 20.53 45.83 20.53 43.50 20.53 C 43.50 18.20 43.50 15.87 43.50 13.53 Z"
      />
      <path
        fill="#ffffff"
        opacity="1.00"
        d=" M 13.50 43.54 C 15.83 43.53 18.16 43.53 20.49 43.53 C 20.50 45.87 20.50 48.20 20.50 50.53 C 18.17 50.53 15.83 50.53 13.50 50.53 C 13.50 48.20 13.50 45.87 13.50 43.54 Z"
      />
      <path
        fill="#ffffff"
        opacity="1.00"
        d=" M 43.50 43.54 C 45.83 43.53 48.17 43.53 50.50 43.54 C 50.50 45.87 50.50 48.20 50.50 50.53 C 48.16 50.53 45.83 50.53 43.50 50.53 C 43.50 48.20 43.50 45.87 43.50 43.54 Z"
      />
    </g>
  </svg>
);

export const F3: React.FC = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 64 64"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="#71c2ffff">
      <path
        fill="#71c2ff"
        opacity="1.00"
        d=" M 15.37 6.11 C 16.87 4.20 19.49 7.05 17.33 8.27 C 12.79 11.78 9.69 16.74 8.04 22.20 C 7.68 24.23 4.58 22.89 5.38 21.22 C 6.83 15.24 10.46 9.81 15.37 6.11 Z"
      />
      <path
        fill="#71c2ff"
        opacity="1.00"
        d=" M 46.20 6.09 C 47.25 4.44 48.91 6.45 49.90 7.11 C 54.13 10.80 57.29 15.77 58.62 21.24 C 59.25 22.49 57.18 24.06 56.25 22.81 C 54.80 19.64 53.95 16.15 51.68 13.41 C 50.11 10.89 47.29 9.49 45.75 6.99 L 46.20 6.09 Z"
      />
    </g>
    <g id="#444b54ff">
      <path
        fill="#444b54"
        opacity="1.00"
        d=" M 14.99 23.96 C 16.53 17.30 22.25 11.85 29.02 10.79 C 37.48 9.14 46.55 14.77 48.75 23.11 C 50.71 30.40 52.48 37.76 54.28 45.10 C 55.37 48.41 53.33 52.30 49.95 53.20 C 46.49 53.82 42.96 53.47 39.47 53.53 C 34.49 53.54 29.50 53.54 24.51 53.53 C 21.17 53.48 17.79 53.76 14.47 53.30 C 10.86 52.61 8.54 48.50 9.72 45.03 C 11.42 38.00 13.19 30.97 14.99 23.96 M 27.26 14.31 C 21.24 16.18 17.90 22.18 17.01 28.09 C 15.57 34.45 13.64 40.70 12.42 47.11 C 12.37 49.53 14.87 50.76 16.99 50.53 C 27.28 50.50 37.59 50.61 47.88 50.48 C 50.17 50.67 52.19 48.33 51.43 46.09 C 50.15 40.37 48.56 34.72 47.22 29.01 C 46.31 25.48 45.65 21.67 43.20 18.82 C 39.51 14.19 32.84 12.32 27.26 14.31 Z"
      />
      <path
        fill="#444b54"
        opacity="1.00"
        d=" M 25.90 56.47 C 29.90 58.09 34.08 58.08 38.09 56.47 C 38.53 59.83 34.66 60.91 32.03 60.98 C 29.39 60.93 25.46 59.86 25.90 56.47 Z"
      />
    </g>
    <g id="#ffffffff">
      <path
        fill="#ffffff"
        opacity="1.00"
        d=" M 27.26 14.31 C 32.84 12.32 39.51 14.19 43.20 18.82 C 45.65 21.67 46.31 25.48 47.22 29.01 C 48.56 34.72 50.15 40.37 51.43 46.09 C 52.19 48.33 50.17 50.67 47.88 50.48 C 37.59 50.61 27.28 50.50 16.99 50.53 C 14.87 50.76 12.37 49.53 12.42 47.11 C 13.64 40.70 15.57 34.45 17.01 28.09 C 17.90 22.18 21.24 16.18 27.26 14.31 Z"
      />
      <path
        fill="#ffffff"
        opacity="1.00"
        d=" M 24.51 53.53 C 29.50 53.54 34.49 53.54 39.47 53.53 C 39.00 54.50 38.53 55.48 38.09 56.47 C 34.08 58.08 29.90 58.09 25.90 56.47 C 25.45 55.48 24.99 54.50 24.51 53.53 Z"
      />
    </g>
  </svg>
);
export const F4: React.FC = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 64 64"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="#444b54ff">
      <path
        fill="#444b54"
        opacity="1.00"
        d=" M 27.35 14.50 C 33.17 11.75 40.76 15.00 42.79 21.10 C 45.02 26.82 41.70 33.86 35.88 35.79 C 30.15 38.02 23.13 34.69 21.20 28.87 C 19.10 23.42 21.98 16.76 27.35 14.50 M 28.34 17.34 C 22.62 19.77 21.86 28.54 27.09 31.92 C 31.99 35.74 39.99 32.14 40.43 25.96 C 41.46 19.62 34.00 14.32 28.34 17.34 Z"
      />
      <path
        fill="#444b54"
        opacity="1.00"
        d=" M 12.13 52.21 C 17.30 42.48 30.37 37.57 40.47 42.25 L 40.89 43.27 C 41.62 43.67 42.24 44.19 42.76 44.82 C 42.19 44.72 41.03 44.53 40.45 44.43 C 37.29 45.00 34.24 43.03 31.01 43.61 C 23.65 43.63 17.61 48.67 13.81 54.58 C 12.82 54.60 11.40 53.27 12.13 52.21 Z"
      />
    </g>
    <g id="#ffffffff">
      <path
        fill="#ffffff"
        opacity="1.00"
        d=" M 28.34 17.34 C 34.00 14.32 41.46 19.62 40.43 25.96 C 39.99 32.14 31.99 35.74 27.09 31.92 C 21.86 28.54 22.62 19.77 28.34 17.34 Z"
      />
      <path
        fill="#ffffff"
        opacity="1.00"
        d=" M 13.81 54.58 C 17.61 48.67 23.65 43.63 31.01 43.61 C 34.24 43.03 37.29 45.00 40.45 44.43 C 41.03 44.53 42.19 44.72 42.76 44.82 L 43.22 44.91 C 42.48 48.15 39.73 50.89 40.56 54.54 C 31.64 54.55 22.73 54.49 13.81 54.58 Z"
      />
      <path
        fill="#ffffff"
        opacity="1.00"
        d=" M 50.65 53.10 C 52.83 50.57 55.08 48.09 57.32 45.61 C 57.80 46.21 58.76 47.42 59.24 48.03 L 58.59 48.74 C 55.96 51.50 53.50 54.41 50.93 57.22 C 48.30 56.01 46.82 53.30 45.38 50.93 C 46.80 47.36 49.39 51.63 50.65 53.10 Z"
      />
    </g>
    <g id="#1fd4afff">
      <path
        fill="#1fd4af"
        opacity="1.00"
        d=" M 47.30 43.56 C 50.73 42.30 54.57 43.33 57.32 45.61 C 55.08 48.09 52.83 50.57 50.65 53.10 C 49.39 51.63 46.80 47.36 45.38 50.93 C 46.82 53.30 48.30 56.01 50.93 57.22 C 53.50 54.41 55.96 51.50 58.59 48.74 L 59.30 48.08 C 62.11 53.39 59.44 60.69 53.59 62.46 C 47.95 64.56 41.46 60.38 40.56 54.54 C 39.73 50.89 42.48 48.15 43.22 44.91 C 44.80 45.38 45.91 43.95 47.30 43.56 Z"
      />
    </g>
  </svg>
);
export const F5: React.FC = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 64 64"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="#444b54ff">
      <path
        fill="#444b54"
        opacity="1.00"
        d=" M 5.52 32.03 C 5.23 19.29 15.46 7.44 28.08 5.81 C 39.83 3.82 52.19 10.96 56.52 22.01 C 58.28 26.14 58.68 30.69 58.38 35.13 C 57.86 35.36 56.81 35.83 56.29 36.06 C 55.10 33.56 55.55 30.74 55.14 28.08 C 53.93 20.18 48.28 13.17 40.87 10.21 C 33.24 7.00 23.93 8.29 17.50 13.51 C 11.64 18.07 8.17 25.51 8.53 32.93 C 8.77 40.99 13.55 48.65 20.60 52.52 C 24.86 55.08 29.90 55.54 34.76 55.52 C 35.07 55.74 35.71 56.16 36.03 56.38 C 35.88 59.42 32.05 58.30 30.03 58.40 C 16.64 57.70 5.22 45.45 5.52 32.03 Z"
      />
      <path
        fill="#444b54"
        opacity="1.00"
        d=" M 30.89 12.94 C 31.42 13.02 32.47 13.19 32.99 13.27 C 34.18 17.68 33.15 22.47 33.54 27.03 C 34.93 27.93 36.08 29.12 36.98 30.50 C 39.92 30.75 43.07 29.98 45.87 31.05 C 45.86 31.54 45.86 32.51 45.86 33.00 C 43.04 34.09 39.91 33.32 36.96 33.56 C 35.53 36.74 31.08 38.31 28.48 35.53 C 25.68 32.94 27.26 28.47 30.46 27.05 C 30.71 22.35 30.07 17.59 30.89 12.94 M 31.09 30.12 C 28.87 31.26 30.51 35.00 32.83 33.80 C 35.23 32.77 33.47 29.04 31.09 30.12 Z"
      />
    </g>
    <g id="#ffffffff">
      <path
        fill="#ffffff"
        opacity="1.00"
        d=" M 17.50 13.51 C 23.93 8.29 33.24 7.00 40.87 10.21 C 48.28 13.17 53.93 20.18 55.14 28.08 C 55.55 30.74 55.10 33.56 56.29 36.06 C 56.85 37.98 55.46 39.93 55.01 41.79 C 50.39 39.87 44.57 41.72 42.25 46.24 C 40.66 48.90 40.84 52.18 41.78 55.03 C 40.04 55.64 38.25 56.14 36.49 56.69 L 36.03 56.38 C 35.71 56.16 35.07 55.74 34.76 55.52 C 29.90 55.54 24.86 55.08 20.60 52.52 C 13.55 48.65 8.77 40.99 8.53 32.93 C 8.17 25.51 11.64 18.07 17.50 13.51 M 30.89 12.94 C 30.07 17.59 30.71 22.35 30.46 27.05 C 27.26 28.47 25.68 32.94 28.48 35.53 C 31.08 38.31 35.53 36.74 36.96 33.56 C 39.91 33.32 43.04 34.09 45.86 33.00 C 45.86 32.51 45.86 31.54 45.87 31.05 C 43.07 29.98 39.92 30.75 36.98 30.50 C 36.08 29.12 34.93 27.93 33.54 27.03 C 33.15 22.47 34.18 17.68 32.99 13.27 C 32.47 13.19 31.42 13.02 30.89 12.94 Z"
      />
      <path
        fill="#ffffff"
        opacity="1.00"
        d=" M 31.09 30.12 C 33.47 29.04 35.23 32.77 32.83 33.80 C 30.51 35.00 28.87 31.26 31.09 30.12 Z"
      />
      <path
        fill="#ffffff"
        opacity="1.00"
        d=" M 51.11 51.08 C 53.32 48.58 55.58 46.12 57.81 43.63 C 58.28 44.21 59.23 45.36 59.70 45.94 C 56.82 49.13 54.11 52.47 50.99 55.44 C 49.10 53.17 46.17 51.16 46.03 47.96 C 48.50 46.39 49.60 49.71 51.11 51.08 Z"
      />
    </g>
    <g id="#1fd4afff">
      <path
        fill="#1fd4af"
        opacity="1.00"
        d=" M 42.25 46.24 C 44.57 41.72 50.39 39.87 55.01 41.79 C 56.01 42.30 56.90 42.98 57.81 43.63 C 55.58 46.12 53.32 48.58 51.11 51.08 C 49.60 49.71 48.50 46.39 46.03 47.96 C 46.17 51.16 49.10 53.17 50.99 55.44 C 54.11 52.47 56.82 49.13 59.70 45.94 C 62.25 50.70 60.62 57.24 55.71 59.75 C 50.79 62.64 44.05 60.16 41.78 55.03 C 40.84 52.18 40.66 48.90 42.25 46.24 Z"
      />
    </g>
  </svg>
);
export const F6: React.FC = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 64 64"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="#71c2ffff">
      <path
        fill="#71c2ff"
        opacity="1.00"
        d=" M 42.89 11.55 C 42.78 3.87 52.38 -1.45 58.64 3.28 C 65.04 7.29 63.96 17.75 57.11 20.70 C 50.66 23.54 43.03 18.54 42.89 11.55 M 51.79 4.86 C 51.43 7.56 51.41 10.29 51.79 12.99 C 54.26 12.95 56.74 12.95 59.21 13.00 C 59.22 12.28 59.22 10.83 59.23 10.10 C 57.68 10.01 56.13 9.99 54.58 9.89 C 54.39 7.79 55.01 4.40 51.79 4.86 Z"
      />
    </g>
    <g id="#ffffffff">
      <path
        fill="#ffffff"
        opacity="1.00"
        d=" M 51.79 4.86 C 55.01 4.40 54.39 7.79 54.58 9.89 C 56.13 9.99 57.68 10.01 59.23 10.10 C 59.22 10.83 59.22 12.28 59.21 13.00 C 56.74 12.95 54.26 12.95 51.79 12.99 C 51.41 10.29 51.43 7.56 51.79 4.86 Z"
      />
      <path
        fill="#ffffff"
        opacity="1.00"
        d=" M 39.67 11.87 L 40.07 11.61 C 40.77 11.59 42.18 11.56 42.89 11.55 C 43.03 18.54 50.66 23.54 57.11 20.70 C 57.01 21.36 56.81 22.68 56.71 23.35 C 56.21 24.52 55.46 25.66 55.50 26.99 C 55.31 34.00 55.77 41.02 55.38 48.02 C 55.31 50.75 52.13 51.10 50.04 51.02 C 38.02 50.99 25.99 50.99 13.96 51.02 C 11.86 51.11 8.78 50.75 8.67 48.03 C 8.23 39.55 8.64 31.02 8.50 22.52 C 8.12 22.18 7.36 21.51 6.98 21.17 C 7.00 20.50 7.03 19.16 7.05 18.49 C 8.59 16.67 9.01 13.15 11.89 13.11 C 19.92 12.78 27.98 13.12 36.02 13.03 C 37.39 13.11 38.61 12.73 39.67 11.87 M 13.38 24.34 C 12.12 26.18 14.35 27.35 15.47 28.50 C 22.93 34.89 34.15 36.30 42.98 32.03 C 46.01 30.33 49.65 28.55 51.04 25.16 C 49.74 22.71 47.65 25.24 46.32 26.26 C 38.47 33.19 25.54 33.20 17.65 26.33 C 16.32 25.48 15.18 23.43 13.38 24.34 Z"
      />
    </g>
    <g id="#444b54ff">
      <path
        fill="#444b54"
        opacity="1.00"
        d=" M 7.19 12.13 C 9.63 9.29 13.70 10.12 17.00 9.98 C 24.31 10.20 31.66 9.63 38.95 10.25 C 39.13 10.65 39.49 11.46 39.67 11.87 C 38.61 12.73 37.39 13.11 36.02 13.03 C 27.98 13.12 19.92 12.78 11.89 13.11 C 9.01 13.15 8.59 16.67 7.05 18.49 C 4.44 17.19 5.66 13.82 7.19 12.13 Z"
      />
      <path
        fill="#444b54"
        opacity="1.00"
        d=" M 5.46 22.64 C 5.84 22.27 6.60 21.54 6.98 21.17 C 7.36 21.51 8.12 22.18 8.50 22.52 C 8.64 31.02 8.23 39.55 8.67 48.03 C 8.78 50.75 11.86 51.11 13.96 51.02 C 25.99 50.99 38.02 50.99 50.04 51.02 C 52.13 51.10 55.31 50.75 55.38 48.02 C 55.77 41.02 55.31 34.00 55.50 26.99 C 55.46 25.66 56.21 24.52 56.71 23.35 C 58.10 24.19 58.70 25.40 58.51 27.00 C 58.45 33.98 58.60 40.97 58.44 47.95 C 58.51 51.77 54.58 54.36 51.02 53.99 C 37.98 53.97 24.93 54.08 11.89 53.94 C 8.63 54.01 5.62 51.22 5.58 47.93 C 5.34 39.51 5.62 31.07 5.46 22.64 Z"
      />
      <path
        fill="#444b54"
        opacity="1.00"
        d=" M 13.38 24.34 C 15.18 23.43 16.32 25.48 17.65 26.33 C 25.54 33.20 38.47 33.19 46.32 26.26 C 47.65 25.24 49.74 22.71 51.04 25.16 C 49.65 28.55 46.01 30.33 42.98 32.03 C 34.15 36.30 22.93 34.89 15.47 28.50 C 14.35 27.35 12.12 26.18 13.38 24.34 Z"
      />
    </g>
  </svg>
);

export const Tick: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <g clipPath="url(#clip0_545_227)">
      <path
        d="M9.58491 0H10.4898C12.5645 0.123019 14.6075 0.868679 16.2294 2.17736C18.526 3.98491 19.9434 6.83925 20 9.76075V10.4906C19.8785 12.4996 19.1811 14.48 17.9442 16.074C16.4717 18.0091 14.2725 19.3789 11.8785 19.8204C11.4272 19.9215 10.9638 19.9487 10.5057 20H9.76528C7.94264 19.9555 6.13434 19.4068 4.60075 18.4196C2.67396 17.1842 1.18717 15.274 0.489811 13.0913C0.207547 12.2574 0.0724528 11.3819 0 10.5072V9.76604C0.0535849 6.93585 1.38264 4.1683 3.55396 2.35321C5.22264 0.933585 7.36981 0.112453 9.55396 0"
        fill="#13AAFF"
      />
      <path
        d="M13.8053 6.3593C14.037 6.12383 14.4045 6.04533 14.7162 6.14722C15.1411 6.27628 15.4234 6.76684 15.3049 7.19854C15.2506 7.46571 15.04 7.65816 14.8536 7.84156C12.917 9.77439 10.9849 11.714 9.04604 13.6453C8.70566 13.997 8.08604 13.9736 7.76151 13.6121C6.81132 12.6589 5.85358 11.7133 4.91019 10.754C4.46641 10.3284 4.6649 9.50118 5.24453 9.31175C5.6234 9.15326 6.05887 9.31854 6.31094 9.62345C7.01585 10.3261 7.71547 11.034 8.42264 11.7329C10.2249 9.94949 12.0091 8.14798 13.806 6.35854L13.8053 6.3593Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_545_227">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const Tick2: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 20 20"
    fill="none"
  >
    <g clipPath="url(#clip0_545_227)">
      <path
        d="M9.58491 0H10.4898C12.5645 0.123019 14.6075 0.868679 16.2294 2.17736C18.526 3.98491 19.9434 6.83925 20 9.76075V10.4906C19.8785 12.4996 19.1811 14.48 17.9442 16.074C16.4717 18.0091 14.2725 19.3789 11.8785 19.8204C11.4272 19.9215 10.9638 19.9487 10.5057 20H9.76528C7.94264 19.9555 6.13434 19.4068 4.60075 18.4196C2.67396 17.1842 1.18717 15.274 0.489811 13.0913C0.207547 12.2574 0.0724528 11.3819 0 10.5072V9.76604C0.0535849 6.93585 1.38264 4.1683 3.55396 2.35321C5.22264 0.933585 7.36981 0.112453 9.55396 0"
        fill="#13AAFF"
      />
      <path
        d="M13.8053 6.3593C14.037 6.12383 14.4045 6.04533 14.7162 6.14722C15.1411 6.27628 15.4234 6.76684 15.3049 7.19854C15.2506 7.46571 15.04 7.65816 14.8536 7.84156C12.917 9.77439 10.9849 11.714 9.04604 13.6453C8.70566 13.997 8.08604 13.9736 7.76151 13.6121C6.81132 12.6589 5.85358 11.7133 4.91019 10.754C4.46641 10.3284 4.6649 9.50118 5.24453 9.31175C5.6234 9.15326 6.05887 9.31854 6.31094 9.62345C7.01585 10.3261 7.71547 11.034 8.42264 11.7329C10.2249 9.94949 12.0091 8.14798 13.806 6.35854L13.8053 6.3593Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_545_227">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const Underline = () => (
  <svg
    width="144"
    height="8"
    viewBox="0 0 144 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.7557 6.28675C49.0346 2.72863 94.4766 0.0136685 141.855 5.87339"
      stroke="#13AAFF"
      strokeWidth="3"
      strokeLinecap="round"
    />
  </svg>
);

export const Alert: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 70 87.5"
  >
    <g>
      <g>
        <path
          d="M56.5214844,58.8115234H13.4785156c-0.2871094,0-0.5600586-0.1230469-0.75-0.3388672    c-0.1894531-0.2148438-0.277832-0.5009766-0.2421875-0.7861328l3.6103516-28.7597656l0,0    c0.6694336-5.3330078,5.2280273-9.3554688,10.6035156-9.3554688h16.5996094c5.3754883,0,9.934082,4.0224609,10.6035156,9.3554688    l3.6103516,28.7597656c0.0356445,0.2851562-0.0527344,0.5712891-0.2421875,0.7861328    C57.081543,58.6884766,56.8085938,58.8115234,56.5214844,58.8115234z M14.6118164,56.8115234h40.7763672l-3.4692383-27.6347656    c-0.5444336-4.3359375-4.25-7.6054688-8.6191406-7.6054688H26.7001953c-4.3691406,0-8.074707,3.2695312-8.6191406,7.6054688    L14.6118164,56.8115234z"
          stroke="white"
        />
      </g>
      <g>
        <path
          stroke="white"
          d="M22.081543,42.5722656c-0.0419922,0-0.0834961-0.0029297-0.1259766-0.0078125    c-0.5478516-0.0683594-0.9365234-0.5683594-0.8676758-1.1171875l1.0317383-8.21875    c0.1499023-1.1953125,0.6245117-2.3251953,1.3725586-3.2675781c0.3427734-0.4306641,0.9736328-0.5048828,1.4047852-0.1611328    c0.4326172,0.34375,0.5048828,0.9726562,0.1616211,1.4052734c-0.5200195,0.6552734-0.8500977,1.4414062-0.9545898,2.2734375    l-1.0317383,8.21875C23.0087891,42.2021484,22.578125,42.5722656,22.081543,42.5722656z"
        />
      </g>
      <g>
        <path
          stroke="white"
          d="M58.0644531,68H11.9350586c-2.1000977,0-3.809082-1.6533203-3.809082-3.6865234v-3.8154297    c0-2.0332031,1.7089844-3.6865234,3.809082-3.6865234h46.1293945c2.1005859,0,3.8095703,1.6533203,3.8095703,3.6865234v3.8154297    C61.8740234,66.3466797,60.1650391,68,58.0644531,68z M11.9350586,58.8115234c-0.9975586,0-1.809082,0.7568359-1.809082,1.6865234    v3.8154297C10.1259766,65.2431641,10.9375,66,11.9350586,66h46.1293945c0.9975586,0,1.8095703-0.7568359,1.8095703-1.6865234    v-3.8154297c0-0.9296875-0.8120117-1.6865234-1.8095703-1.6865234H11.9350586z"
        />
      </g>
      <g>
        <g>
          <path
            stroke="white"
            d="M35,10.4482422c-0.5522461,0-1-0.4472656-1-1V3c0-0.5527344,0.4477539-1,1-1s1,0.4472656,1,1v6.4482422     C36,10.0009766,35.5522461,10.4482422,35,10.4482422z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            stroke="white"
            d="M52.9726562,17.8925781c-0.2558594,0-0.5117188-0.0976562-0.7070312-0.2929688     c-0.390625-0.390625-0.390625-1.0234375,0-1.4140625l4.5595703-4.5595703c0.390625-0.390625,1.0234375-0.390625,1.4140625,0     s0.390625,1.0234375,0,1.4140625l-4.5595703,4.5595703C53.484375,17.7949219,53.2285156,17.8925781,52.9726562,17.8925781z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            stroke="white"
            d="M66.8657227,35.8652344h-6.4482422c-0.5522461,0-1-0.4472656-1-1s0.4477539-1,1-1h6.4482422c0.5522461,0,1,0.4472656,1,1     S67.4179688,35.8652344,66.8657227,35.8652344z"
          />
        </g>
        <g>
          <path
            stroke="white"
            d="M9.5825195,35.8652344H3.1342773c-0.5522461,0-1-0.4472656-1-1s0.4477539-1,1-1h6.4482422c0.5522461,0,1,0.4472656,1,1     S10.1347656,35.8652344,9.5825195,35.8652344z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            stroke="white"
            d="M17.0273438,17.8925781c-0.2558594,0-0.5117188-0.0976562-0.7070312-0.2929688l-4.5595703-4.5595703     c-0.390625-0.390625-0.390625-1.0234375,0-1.4140625s1.0234375-0.390625,1.4140625,0l4.5595703,4.5595703     c0.390625,0.390625,0.390625,1.0234375,0,1.4140625C17.5390625,17.7949219,17.2832031,17.8925781,17.0273438,17.8925781z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const DownArrowIcon = () => {
  return (
    <svg
      fill="#868E96"
      height="20"
      width="20"
      version="1.1"
      id="DownArrowIcon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 330 330"
    >
      <path
        id="XMLID_225_"
        d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
	c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
	s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"
      />
    </svg>
  );
};

export const DownArrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="20px"
    viewBox="0 -960 960 960"
    width="20px"
    fill="#000"
  >
    <path d="M480-333 240-573l51-51 189 189 189-189 51 51-240 240Z" />
  </svg>
);

export const Plus = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="20px"
    viewBox="0 -960 960 960"
    width="20px"
    fill="#000"
  >
    <path d="M444-444H240v-72h204v-204h72v204h204v72H516v204h-72v-204Z" />
  </svg>
);

export const Minus = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="20px"
    viewBox="0 -960 960 960"
    width="20px"
    fill="#000"
  >
    <path d="M232-444v-72h496v72H232Z" />
  </svg>
);

export const UpArrowIcon = () => {
  return (
    <svg
      fill="#868E96"
      height="20"
      width="20"
      version="1.1"
      id="UpArrowIcon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 330 330"
      transform="matrix(-1, 0, 0, -1, 0, 0)"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0" />

      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <g id="SVGRepo_iconCarrier">
        {" "}
        <path
          id="XMLID_225_"
          d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393 c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393 s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"
        />{" "}
      </g>
    </svg>
  );
};
export const Search = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="20"
      viewBox="0 -960 960 960"
      width="20"
      fill="#000"
    >
      <path d="M784-120 532-372q-30 24-69 38t-83 14q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l252 252-56 56ZM380-400q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z" />
    </svg>
  );
};

export const Heart = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24px"
    viewBox="0 -960 960 960"
    width="24px"
    fill="#e8eaed"
  >
    <path d="m480-120-58-52q-101-91-167-157T150-447.5Q111-500 95.5-544T80-634q0-94 63-157t157-63q52 0 99 22t81 62q34-40 81-62t99-22q94 0 157 63t63 157q0 46-15.5 90T810-447.5Q771-395 705-329T538-172l-58 52Zm0-108q96-86 158-147.5t98-107q36-45.5 50-81t14-70.5q0-60-40-100t-100-40q-47 0-87 26.5T518-680h-76q-15-41-55-67.5T300-774q-60 0-100 40t-40 100q0 35 14 70.5t50 81q36 45.5 98 107T480-228Zm0-273Z" />
  </svg>
);

export const FilledHeart = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24px"
    viewBox="0 0 24 24"
    width="24px"
    fill="#EA3323" // Use the desired color here
  >
    <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
  </svg>
);

export const Delivery = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="48px"
    viewBox="0 -960 960 960"
    width="48px"
    fill="#0891b2"
  >
    <path d="M224.12-161q-49.12 0-83.62-34.42Q106-229.83 106-279H40v-461q0-24 18-42t42-18h579v167h105l136 181v173h-71q0 49.17-34.38 83.58Q780.24-161 731.12-161t-83.62-34.42Q613-229.83 613-279H342q0 49-34.38 83.5t-83.5 34.5Zm-.12-60q24 0 41-17t17-41q0-24-17-41t-41-17q-24 0-41 17t-17 41q0 24 17 41t41 17ZM100-339h22q17-27 43.04-43t58-16q31.96 0 58.46 16.5T325-339h294v-401H100v401Zm631 118q24 0 41-17t17-41q0-24-17-41t-41-17q-24 0-41 17t-17 41q0 24 17 41t41 17Zm-52-204h186L754-573h-75v148ZM360-529Z" />
  </svg>
);

export const CheckCircle = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="48px"
    viewBox="0 -960 960 960"
    width="48px"
    fill="#0891b2"
  >
    <path d="m421-298 283-283-46-45-237 237-120-120-45 45 165 166Zm59 218q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-156t86-127Q252-817 325-848.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Zm0-60q142 0 241-99.5T820-480q0-142-99-241t-241-99q-141 0-240.5 99T140-480q0 141 99.5 240.5T480-140Zm0-340Z" />
  </svg>
);

export const Support = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="48px"
    viewBox="0 -960 960 960"
    width="48px"
    fill="#0891b2"
  >
    <path d="M440-120v-60h340v-304q0-123.69-87.32-209.84Q605.36-780 480-780q-125.36 0-212.68 86.16Q180-607.69 180-484v244h-20q-33 0-56.5-23.5T80-320v-80q0-21 10.5-39.5T120-469l3-53q8-68 39.5-126t79-101q47.5-43 109-67T480-840q68 0 129 24t109 66.5Q766-707 797-649t40 126l3 52q19 9 29.5 27t10.5 38v92q0 20-10.5 38T840-249v69q0 24.75-17.62 42.37Q804.75-120 780-120H440Zm-80.18-290q-12.82 0-21.32-8.68-8.5-8.67-8.5-21.5 0-12.82 8.68-21.32 8.67-8.5 21.5-8.5 12.82 0 21.32 8.68 8.5 8.67 8.5 21.5 0 12.82-8.68 21.32-8.67 8.5-21.5 8.5Zm240 0q-12.82 0-21.32-8.68-8.5-8.67-8.5-21.5 0-12.82 8.68-21.32 8.67-8.5 21.5-8.5 12.82 0 21.32 8.68 8.5 8.67 8.5 21.5 0 12.82-8.68 21.32-8.67 8.5-21.5 8.5ZM241-462q-7-106 64-182t177-76q87 0 151 57.5T711-519q-89-1-162.5-50T434.72-698Q419-618 367.5-555.5T241-462Z" />
  </svg>
);

export const Location = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 10C20 16 12 22 12 22C12 22 4 16 4 10C4 7.87827 4.84285 5.84344 6.34315 4.34315C7.84344 2.84285 9.87827 2 12 2C14.1217 2 16.1566 2.84285 17.6569 4.34315C19.1571 5.84344 20 7.87827 20 10Z"
      stroke="#FCFBFA"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z"
      stroke="#FCFBFA"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ContactPhone = ({ fill }: { fill?: string }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill ? fill : "none"}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.0004 16.9201V19.9201C22.0016 20.1986 21.9445 20.4743 21.8329 20.7294C21.7214 20.9846 21.5577 21.2137 21.3525 21.402C21.1473 21.5902 20.905 21.7336 20.6412 21.8228C20.3773 21.912 20.0978 21.9452 19.8204 21.9201C16.7433 21.5857 13.7874 20.5342 11.1904 18.8501C8.77425 17.3148 6.72576 15.2663 5.19042 12.8501C3.5004 10.2413 2.44866 7.27109 2.12042 4.1801C2.09543 3.90356 2.1283 3.62486 2.21692 3.36172C2.30555 3.09859 2.44799 2.85679 2.63519 2.65172C2.82238 2.44665 3.05023 2.28281 3.30421 2.17062C3.5582 2.05843 3.83276 2.00036 4.11042 2.0001H7.11042C7.59573 1.99532 8.06621 2.16718 8.43418 2.48363C8.80215 2.80008 9.0425 3.23954 9.11042 3.7201C9.23704 4.68016 9.47187 5.62282 9.81042 6.5301C9.94497 6.88802 9.97408 7.27701 9.89433 7.65098C9.81457 8.02494 9.62928 8.36821 9.36042 8.6401L8.09042 9.9101C9.51398 12.4136 11.5869 14.4865 14.0904 15.9101L15.3604 14.6401C15.6323 14.3712 15.9756 14.1859 16.3495 14.1062C16.7235 14.0264 17.1125 14.0556 17.4704 14.1901C18.3777 14.5286 19.3204 14.7635 20.2804 14.8901C20.7662 14.9586 21.2098 15.2033 21.527 15.5776C21.8441 15.9519 22.0126 16.4297 22.0004 16.9201Z"
      stroke="#FCFBFA"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.05 2C16.0882 2.21477 17.9922 3.1188 19.4469 4.56258C20.9015 6.00636 21.8199 7.90341 22.05 9.94"
      stroke="#FCFBFA"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.05 6C15.0335 6.19394 15.936 6.67903 16.6404 7.39231C17.3447 8.10559 17.8184 9.01413 18 10"
      stroke="#FCFBFA"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ContactMail = ({ fill }: { fill?: string }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill ? fill : "none"}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 4H4C2.89543 4 2 4.89543 2 6V18C2 19.1046 2.89543 20 4 20H20C21.1046 20 22 19.1046 22 18V6C22 4.89543 21.1046 4 20 4Z"
      stroke="#FCFBFA"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22 7L13.03 12.7C12.7213 12.8934 12.3643 12.996 12 12.996C11.6357 12.996 11.2787 12.8934 10.97 12.7L2 7"
      stroke="#FCFBFA"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const Pendant = () => (
  <svg
    width="70"
    height="20"
    viewBox="0 0 71 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M70.6515 2L2.15115 0.532135C1.32816 0.5145 0.651489 1.17682 0.651489 2C0.651489 2.82318 1.32816 3.4855 2.15115 3.46786L70.6515 2Z"
      fill="url(#paint0_linear_1122_2009)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1122_2009"
        x1="46.1515"
        y1="2"
        x2="0.651489"
        y2="2"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#000" stopOpacity="0.2" />
        <stop offset="1" stopColor="#000" stopOpacity="0.7" />
      </linearGradient>
    </defs>
  </svg>
);
