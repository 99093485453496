import React from "react";
import NavigationBar from "../components/ui/NavBar";
import Footer from "../components/Footer";
import AllBlogs from "../components/Blogs/AllBlogs";

const Blogs = () => {
  return (
    <div className="flex flex-col items-center min-h-screen">
      <NavigationBar />
      <AllBlogs />
      <Footer />
    </div>
  );
};

export default Blogs;
