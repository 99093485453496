import React from "react";
import { useDispatch } from "react-redux";
import { addToBasket, BasketProduct } from "../lib/redux/basketSlice";
import { FilledHeart, Heart } from "../assets/icons/Icons";
import { addToWishList, removeFromWishList } from "../lib/apis";
import { UserAuth } from "../lib/authContext";
import { toast } from "react-toastify";

const Card = ({
  item,
  setItem,
  setVisible,
}: {
  item: BasketProduct | undefined;
  setItem?: React.Dispatch<React.SetStateAction<BasketProduct | undefined>>;
  setVisible?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const dispatch = useDispatch();
  const { user, userData, setUserData, fetchUserData } = UserAuth();

  const addItemToBasket = () => {
    if (item) {
      dispatch(addToBasket(item));
    }
  };

  const inflatedPrice = +((item?.product_price ?? 0) / 0.7).toFixed(0);

  const addItem = async () => {
    if (userData?.user_id && item) {
      try {
        const res = await addToWishList(userData?.user_id, item.product_id);
        if (res?.status === 200) {
          setUserData((prevState) => {
            if (!prevState) return prevState;

            const updatedWishlist = prevState.wishlist
              ? [...prevState.wishlist, item.product_id]
              : [item.product_id];

            return {
              ...prevState,
              wishlist: updatedWishlist,
            };
          });
          fetchUserData(user?.email);
          toast.success("Added to Wishlist!");
        } else {
          toast.error("Failed to add to Wishlist.");
        }
      } catch (error) {
        toast.error("An error occurred while adding to Wishlist.");
      }
    }
  };

  const removeItem = async () => {
    if (userData?.user_id && item) {
      try {
        const res = await removeFromWishList(userData.user_id, item.product_id);
        if (res?.status === 200) {
          setUserData((prevState) => {
            if (!prevState) return prevState;

            const updatedWishlist = prevState.wishlist
              ? prevState.wishlist.filter((id) => id !== item.product_id)
              : [];

            return {
              ...prevState,
              wishlist: updatedWishlist,
            };
          });
          fetchUserData(user?.email);
          toast.success("Removed from Wishlist!");
        } else {
          toast.error("Failed to remove from Wishlist.");
        }
      } catch (error) {
        toast.error("An error occurred while removing from Wishlist.");
      }
    }
  };

  return (
    <div
      data-aos="zoom-in"
      data-aos-delay={100}
      className="flex flex-col items-center justify-center rounded-xl"
    >
      <div className="group min-h-[290px] w-full md:min-h-[410px] [perspective:1000px] rounded-xl">
        <div className="relative h-full w-full shadow-lg transition-all duration-500 [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)] bg-white rounded-xl">
          <div className="absolute inset-0 p-3 space-y-3">
            <img
              className="object-contain h-44 md:h-72 w-full"
              src={item?.product_images ? item.product_images[0] : ""}
              alt={item?.product_name}
            />
            <div>
              <p className="select-none text-xs md:text-base md:font-medium">
                {item?.product_name}
              </p>
              <p className="select-none text-xs md:text-base mt-2">
                <span className=" line-through mr-2 md:mr-3">
                  Rs. {inflatedPrice}
                </span>
                <span className="font-bold ">Rs. {item?.product_price}</span>
              </p>
            </div>
          </div>
          <div className="absolute inset-0 h-full w-full bg-black/95 px-3 md:px-12 [transform:rotateY(180deg)] [backface-visibility:hidden] rounded-xl">
            {user && (
              <div className="absolute top-2 right-2">
                {item && userData?.wishlist?.includes(item.product_id) ? (
                  <button
                    className="hover:opacity-80 focus:outline-none"
                    onClick={removeItem}
                  >
                    <FilledHeart />
                  </button>
                ) : (
                  <button
                    className="hover:opacity-80 focus:outline-none"
                    onClick={addItem}
                  >
                    <Heart />
                  </button>
                )}
              </div>
            )}
            <div className="flex min-h-full flex-col justify-evenly">
              <div>
                <p className="text-cyan-400 text-xs md:text-lg select-none">
                  {item?.product_name}
                </p>
                <ul className="list-disc px-5 py-3">
                  {item?.product_details?.map((detail, index) => (
                    <li
                      key={index}
                      className="text-white text-xs md:text-lg select-none"
                    >
                      {detail}
                    </li>
                  ))}
                </ul>
              </div>
              <button
                className="select-none text-sm md:text-base text-black px-5 py-2 bg-gradient-to-b from-cyan-200 to-cyan-400 rounded-md focus:outline-none hover:from-cyan-500 hover:to-cyan-400 w-full uppercase"
                onClick={addItemToBasket}
              >
                Add to Cart
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
