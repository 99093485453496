export const isPhoneNumberValid = (input: string): boolean => {
  // Check if the input is exactly 10 digits and contains only digits
  return /^\d{10}$/.test(input);
};

export const isPinCodeValid = (input: string): boolean => {
  // Check if the input is exactly 10 digits and contains only digits
  return input !== "" && /^\d{6}$/.test(input);
};

export const isEmailValid = (input: string): boolean => {
  // Basic email validation using regex
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input);
};
