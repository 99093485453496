export const zzeehBaseURL = "https://api.zzeehweddings.com/api/zzeeh";

export const komBaseURL = "https://api.zzeehweddings.com/api/kom";

// export const zzeehBaseURL = "http://localhost:8000/api/zzeeh";

// export const komBaseURL = "http://localhost:8000/api/kom";

// export const rentalBaseURL = "https://api.zzeehweddings.com/api/rental";

export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
