import React from "react";
import GetInTouch from "../GetInTouch";
import TableofContent from "../../ui/TableofContent";
import DynamicList from "../../ui/DynamicList";

const Blog = () => {
  const tocData = [
    { id: "introduction", label: "Introduction" },
    {
      id: "aesthetic-appeal",
      label: "Aesthetic Appeal of Macramé Swings",
    },
    { id: "versatility", label: "Versatility in Different Spaces" },
    { id: "comfort-relaxation", label: "Comfort and Relaxation" },
    { id: "eco-friendly", label: "Eco-Friendly and Sustainable" },
    { id: "durability-longevity", label: "Durability and Longevity" },
    {
      id: "enhancing-ambiance",
      label: "Enhancing Outdoor and Indoor Ambiance",
    },
    { id: "customization-options", label: "Customization Options" },
    { id: "easy-maintenance", label: "Easy Maintenance" },
    { id: "conclusion", label: "Conclusion" },
  ];

  const list1 = [
    {
      header: "Indoors",
      body: "Add one to a living room corner, bedroom, or reading nook to create a cozy spot to unwind.",
    },
    {
      header: "Outdoors",
      body: "Hang it on a porch, patio, or in the garden for a stylish seating option that complements your greenery.",
    },
  ];

  return (
    <div className="container space-y-10">
      <h1 className="text-3xl lg:text-5xl text-gold font-medium font-gt-super mb-10">
        The Benefits of Adding a Macrame Swing to Your Space
      </h1>
      <img
        src="https://knots-of-macrame.s3.ap-south-1.amazonaws.com/blogs/comfortable-macrame-swing.webp"
        alt="perfect-macrame-swing"
      />

      <TableofContent tocData={tocData} />

      <section id="introduction" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Introduction
        </h1>
        <p>
          <a href="/" className="text-blue-500 hover:underline">
            Macramé
          </a>{" "}
          swings have made a remarkable comeback in recent years, blending the
          charm of handmade craftsmanship with modern-day comfort. These
          beautiful, intricately knotted swings not only enhance the aesthetic
          appeal of your home or garden but also offer a cozy and comfortable
          space for relaxation. Whether placed indoors or outdoors, macramé
          swings are more than just furniture—they’re a lifestyle addition that
          brings a bohemian touch to any space.
        </p>
        <p>
          In this blog, we’ll explore the various benefits of adding a Macrame
          Swing to your space, from aesthetics and comfort to their eco-friendly
          nature and versatility.
        </p>
      </section>

      <section id="aesthetic-appeal" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Aesthetic Appeal of Macrame Swings
        </h1>
        <p>
          One of the main reasons{" "}
          <a href="/" className="text-blue-500 hover:underline">
            Macramé
          </a>{" "}
          swings have become popular is due to their undeniable visual appeal.
          The intricate knotting techniques used in macramé create stunning
          designs that exude a sense of craftsmanship and artistry. These swings
          add a unique, bohemian-chic vibe to your space, whether it’s a cozy
          reading nook or a lush garden. Their neutral colors, often in shades
          of cream, white, or beige, make them easy to integrate into any
          interior style—modern, rustic, or traditional.
        </p>
      </section>

      <section id="versatility" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Versatility in Different Spaces
        </h1>

        <p>
          <a href="/" className="text-blue-500 hover:underline">
            Macramé
          </a>{" "}
          swings are incredibly versatile. They fit seamlessly into a variety of
          spaces:
        </p>
        <DynamicList listType="list-disc" data={list1} />
      </section>

      <section id="comfort-relaxation" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Comfort and Relaxation
        </h1>
        <p>
          Beyond their beauty, macrame swings provide a comfortable spot to
          relax. Made from soft, natural fibers, they gently conform to the
          shape of your body, making them perfect for lounging with a book,
          sipping coffee, or even taking a nap. The slight swaying motion can
          also help relieve stress and promote relaxation, giving you a peaceful
          retreat in your own home.
        </p>
      </section>

      <section id="eco-friendly" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Eco-Friendly and Sustainable
        </h1>
        <p>
          Most{" "}
          <a href="/" className="text-blue-500 hover:underline">
            Macramé
          </a>{" "}
          swings are made from natural materials such as cotton or jute, making
          them an eco-friendly choice. These materials are renewable,
          biodegradable, and often sustainably sourced. By choosing a macramé
          swing, you’re making a conscious decision to reduce your environmental
          footprint while supporting handcrafted, sustainable products.
        </p>
      </section>

      <section id="durability-longevity" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Durability and Longevity
        </h1>
        <p>
          While{" "}
          <a href="/" className="text-blue-500 hover:underline">
            Macramé
          </a>{" "}
          swings may look delicate, they are surprisingly durable. The knots
          used in macramé create strong, supportive structures, capable of
          holding significant weight. When properly cared for, these swings can
          last for many years, offering both aesthetic and functional benefits
          over time. The use of high-quality materials ensures that macramé
          swings are resistant to wear and tear, even with regular use.
        </p>
      </section>

      <section id="enhancing-ambiance" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Enhancing Outdoor and Indoor Ambiance
        </h1>
        <p>
          <a href="/" className="text-blue-500 hover:underline">
            Macramé
          </a>{" "}
          swings have the ability to transform the ambiance of any space.
          Outdoors, they add a whimsical, serene touch, making your garden or
          patio feel like a relaxing retreat. Indoors, they can turn an ordinary
          room into a stylish and cozy haven. Whether you're looking to create a
          focal point or just want a small corner to unwind, a Macrame Swing can
          elevate the vibe of your home.
        </p>
      </section>

      <section id="customization-options" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Customization Options
        </h1>
        <p>
          <a href="/" className="text-blue-500 hover:underline">
            Macramé
          </a>{" "}
          swings come in a variety of styles, shapes, and colors, allowing for
          customization to fit your personal taste. You can choose from
          different knot patterns, materials, and sizes to suit your home’s
          design. Additionally, you can accessorize your swing with cushions,
          throws, or blankets to make it even more inviting and cozy.
        </p>
      </section>

      <section id="easy-maintenance" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Easy Maintenance
        </h1>
        <p>
          Maintaining a{" "}
          <a href="/" className="text-blue-500 hover:underline">
            Macramé
          </a>{" "}
          Swing is relatively simple. Most are machine washable or can be
          hand-washed with mild detergent, making them easy to clean. Regularly
          dusting or spot-cleaning them will keep them looking fresh and free
          from dirt, ensuring they stay a beautiful part of your décor for years
          to come.
        </p>
      </section>

      <section id="conclusion" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Conclusion
        </h1>
        <p>
          Adding a{" "}
          <a href="/" className="text-blue-500 hover:underline">
            Macramé
          </a>{" "}
          Swing to your space is a stylish, comfortable, and eco-friendly
          choice. Its aesthetic appeal, versatility, and durability make it a
          timeless addition to both indoor and outdoor spaces. Whether you're
          looking to create a relaxing corner for yourself or add a boho touch
          to your home décor, a Macrame Swing is the perfect choice. Embrace the
          beauty of handcrafted design while enhancing the comfort and
          ambiance of your home.
        </p>
      </section>

      <GetInTouch />
    </div>
  );
};

export default Blog;
