import React from "react";
import DynamicList from "../../ui/DynamicList";
import GetInTouch from "../GetInTouch";
import TableofContent from "../../ui/TableofContent";

const Blog = () => {
  const tocData = [
    {
      id: "introduction",
      label: "Introduction",
    },
    {
      id: "why-macrame-swings-need-care",
      label: "Why Macrame Swings Need Care",
    },
    {
      id: "choosing-the-right-spot",
      label: "Choosing the Right Spot for Your Macrame Swing",
    },
    {
      id: "essential-tools",
      label: "Essential Tools for Macrame Swing Maintenance",
    },
    {
      id: "routine-cleaning-dusting",
      label: "Routine Cleaning and Dusting",
    },
    {
      id: "deep-cleaning",
      label: "Deep Cleaning Your Macrame Swing",
    },
    {
      id: "treat-stains",
      label: "How to Treat Stains on Your Macrame Swing",
    },
    {
      id: "preventing-mold",
      label: "Preventing Mold and Mildew",
    },
    {
      id: "inspecting-wear-tear",
      label: "Inspecting for Wear and Tear",
    },
    {
      id: "protecting-sun-damage",
      label: "Protecting Your Swing from Sun Damage",
    },
    {
      id: "storing-swing",
      label: "Storing Your Macrame Swing",
    },
    {
      id: "extending-lifespan",
      label: "How to Extend the Lifespan of Your Macrame Swing",
    },
    {
      id: "styling-ideas",
      label: "Creative Ideas to Style Your Macrame Swing",
    },
    {
      id: "diy-repair",
      label: "DIY Repair Tips for Minor Damage",
    },
    {
      id: "faqs",
      label: "FAQs",
    },
    {
      id: "conclusion",
      label: "Conclusion",
    },
  ];

  const list1 = [
    {
      header: "Indoor vs. Outdoor Placement",
      body: "Deciding where to place your Macrame Swing is crucial for its longevity. Indoors, it may be exposed to less dust and fewer environmental elements, but outdoor swings require more attention due to weather changes.",
    },
    {
      header: "Factors to Consider When Choosing a Location",
      body: "Consider factors like sunlight exposure, moisture, and accessibility. An area with too much humidity can encourage mold growth, while direct sunlight can weaken the fibers of the swing.",
    },
  ];

  const list2 = [
    {
      header: "Basic Cleaning Supplies",
      body: "Having the right cleaning supplies makes maintenance much easier. You’ll need a soft cloth, mild detergent, and a soft-bristled brush to remove dust and dirt effectively.",
    },
    {
      header: "Repair Tools for Wear and Tear",
      body: "Keep a small repair kit handy for when knots become loose or cords start to fray. This can include scissors, replacement cords, and a guide for retightening macrame knots.",
    },
  ];

  const list3 = [
    {
      header: "How Often Should You Clean?",
      body: "Routine cleaning is essential for keeping your Macrame Swing in top shape. Dusting once a week can help prevent the accumulation of debris, while a deeper clean should be done every few months.",
    },
    {
      header: "Techniques for Dusting Your Macrame Swing",
      body: "Use a soft cloth or a duster to gently remove dust from the cords. For hard-to-reach areas, a soft-bristled brush works wonders in getting rid of dirt without damaging the material.",
    },
  ];

  const list4 = [
    {
      header: "Steps for Washing the Swing by Hand",
      body: "Fill a basin with cold water and mild detergent. Submerge the swing, gently scrubbing the cords with a soft brush. Rinse thoroughly and hang it up to dry in a well-ventilated area, avoiding direct sunlight.",
    },
    {
      header: "Can You Machine Wash Macrame Swings?",
      body: "It’s generally not recommended to machine wash your Macrame Swing, as the agitation can damage the knots. Hand washing is the safer, more effective option.",
    },
  ];

  const list5 = [
    {
      header: "Common Types of Stains and How to Remove Them",
      body: "Whether it’s food, drink, or outdoor grime, certain stains are more stubborn than others. Blotting with a mixture of water and vinegar is a great natural solution, while more persistent stains may need specialized cleaners.",
    },
    {
      header: "DIY Stain Removers vs. Store-Bought Options",
      body: "DIY solutions like baking soda and lemon juice can effectively remove stains, but for tougher marks, a store-bought fabric cleaner may be more efficient.",
    },
  ];

  const list6 = [
    {
      header: "Humidity Control for Indoor Swings",
      body: "To prevent mold and mildew indoors, ensure your swing is placed in a dry area with proper ventilation. Using a dehumidifier can help if the room tends to be humid.",
    },
    {
      header: "Weatherproofing Outdoor Macrame Swings",
      body: "If your swing is outdoors, applying a water-resistant spray can help protect the cords from moisture. This simple step can make a significant difference in preventing mold.",
    },
  ];

  const list7 = [
    {
      header: "Identifying Potential Problems Early",
      body: "Regularly inspect your Macrame Swing for signs of wear and tear. Look for loose knots, fraying cords, or any areas that might be weakening over time.",
    },
    {
      header: "Repairing Frayed Cords or Loose Knots",
      body: "If you notice frayed cords, replace them immediately. Loose knots can usually be tightened, but if you're unsure, consult a guide or professional to prevent further damage.",
    },
  ];

  const list8 = [
    {
      header: "UV Protection for Outdoor Swings",
      body: "Sunlight can weaken the fibers of your Macrame Swing. Using UV-protective sprays or relocating the swing out of direct sunlight will help extend its lifespan.",
    },
    {
      header: "Rotating and Moving to Avoid Direct Sunlight",
      body: "If your swing is stationary, rotating it or moving it to avoid continuous direct sunlight is a simple but effective way to prevent UV damage.",
    },
  ];

  const list9 = [
    {
      header: "Seasonal Storage Tips",
      body: "When not in use, especially during harsh weather seasons, store your Macrame Swing in a cool, dry place. Make sure it's completely dry before storing to avoid mold.",
    },
    {
      header: "Best Materials for Wrapping and Storing",
      body: "Use breathable fabric covers to wrap the swing. Avoid plastic, as it can trap moisture, leading to mold and mildew.",
    },
  ];

  const list10 = [
    {
      header: "Best Practices for Long-Term Use",
      body: "Regular maintenance, such as cleaning, inspecting, and rotating your swing, will greatly extend its life. Make sure to follow the manufacturer’s care instructions as well.",
    },
    {
      header: "Knowing When It’s Time for Replacement",
      body: "No matter how well you maintain your swing, there comes a time when wear and tear might require you to replace it. If the cords are too worn or if repairs no longer hold, it’s time to consider a replacement.",
    },
  ];

  const list11 = [
    {
      header: "Cushions and Throws to Complement Your Swing",
      body: "Adding decorative cushions or throws is an easy way to personalize your Macrame Swing. Not only do they add comfort, but they also offer a fun way to change the look of your swing.",
    },
    {
      header: "Adding Plants and Decorative Items",
      body: "Placing plants or decorative items around your swing can enhance its aesthetic appeal. Make sure they don’t interfere with the swing’s movement or cause additional wear on the cords.",
    },
  ];

  const list12 = [
    {
      header: "How to Fix a Loose Knot",
      body: "If you notice a loose knot, retighten it by following a simple Macrame knot guide. This is a quick fix and will help keep your swing sturdy.",
    },
    {
      header: "Replacing Worn-Out Strings",
      body: "When a string becomes too frayed, replace it by carefully removing it and weaving a new one in its place. Ensure the new string matches the original material for consistency.",
    },
  ];

  return (
    <div className="container space-y-10">
      <h1 className="text-3xl lg:text-5xl text-gold font-medium font-gt-super mb-10">
        How to Care for and Maintain Your Macrame Swing
      </h1>
      <img
        src="https://knots-of-macrame.s3.ap-south-1.amazonaws.com/blogs/swing-jhula.webp"
        alt="perfect-macrame-swing"
      />

      <TableofContent tocData={tocData} />

      <section id="introduction" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Introduction
        </h1>
        <p>
          Macrame Swings have a way of turning any space into a cozy nook,
          blending both relaxation and style. However, like any piece of
          furniture, they require proper care and maintenance to ensure they
          last. Whether you have one indoors or outdoors, learning how to care
          for and maintain your Macrame Swing will keep it in prime condition
          for years to come. In this guide, we’ll walk you through everything
          you need to know.
        </p>
      </section>

      <section id="why-macrame-swings-need-care" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Why Macrame Swings Need Care
        </h1>
        <p>
          Macrame Swings are made of intricate knots and durable cords, but they
          are still susceptible to dirt, weather conditions, and wear over time.
          Proper care helps maintain the beauty and structural integrity of your
          swing, ensuring it stays strong and functional.
        </p>
      </section>

      <section id="choosing-the-right-spot" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Choosing the Right Spot for Your Macrame Swing
        </h1>

        <DynamicList newLine listType="" data={list1} />
      </section>

      <section id="essential-tools" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Essential Tools for Macrame Swing Maintenance
        </h1>
        <DynamicList newLine listType="" data={list2} />
      </section>

      <section id="routine-cleaning-dusting" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Routine Cleaning and Dusting
        </h1>
        <DynamicList newLine listType="" data={list3} />
      </section>

      <section id="deep-cleaning" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Deep Cleaning Your Macrame Swing
        </h1>
        <DynamicList newLine listType="" data={list4} />
      </section>

      <section id="treat-stains" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          How to Treat Stains on Your Macrame Swing
        </h1>
        <DynamicList newLine listType="" data={list5} />
      </section>

      <section id="preventing-mold" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Preventing Mold and Mildew
        </h1>
        <DynamicList newLine listType="" data={list6} />
      </section>

      <section id="inspecting-wear-tear" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Inspecting for Wear and Tear
        </h1>
        <DynamicList newLine listType="" data={list7} />
      </section>

      <section id="protecting-sun-damage" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Protecting Your Swing from Sun Damage
        </h1>
        <DynamicList newLine listType="" data={list8} />
      </section>

      <section id="storing-swing" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Storing Your Macrame Swing
        </h1>
        <DynamicList newLine listType="" data={list9} />
      </section>

      <section id="extending-lifespan" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          How to Extend the Lifespan of Your Macrame Swing
        </h1>
        <DynamicList newLine listType="" data={list10} />
      </section>

      <section id="styling-ideas" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Creative Ideas to Style Your Macrame Swing
        </h1>
        <DynamicList newLine listType="" data={list11} />
      </section>

      <section id="diy-repair" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          DIY Repair Tips for Minor Damage
        </h1>
        <DynamicList newLine listType="" data={list12} />
      </section>

      <section id="faqs" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Frequently Asked Questions (FAQ)
        </h1>

        <ul className={`list-decimal ml-5 px-5 py-2 space-y-1`}>
          <li>
            <p className="font-semibold">
              Can Macrame Swings be used outdoors?
            </p>
            <p>
              o Yes, but they require more maintenance and weather protection.
            </p>
          </li>
          <li>
            <p className="font-semibold">
              How often should I clean my Macrame Swing?{" "}
            </p>
            <p>o Dust weekly and do a deep clean every few months.</p>
          </li>
          <li>
            <p className="font-semibold">
              What’s the best way to store my swing?
            </p>
            <p>
              o Store it in a cool, dry place and use breathable fabric to cover
              it.
            </p>
          </li>
          <li>
            <p className="font-semibold">
              Can I repair a Macrame Swing myself?
            </p>
            <p>
              o Yes, minor repairs like retightening knots and replacing cords
              can be done at home.
            </p>
          </li>
          <li>
            <p className="font-semibold">
              Is it safe to wash my Macrame Swing in the washing machine?{" "}
            </p>
            <p>
              o It’s not recommended, as the agitation can damage the swing.
              Hand washing is a better option.
            </p>
          </li>
        </ul>
      </section>

      <section id="conclusion" className="space-y-3">
        <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
          Conclusion
        </h1>
        <p>
          Maintaining your Macrame Swing may seem like a lot of work, but with a
          little effort, it can last you for years. By following these care
          tips, you’ll keep your swing looking beautiful and feeling
          comfortable, whether it's indoors or outdoors. Regular cleaning,
          inspecting for damage, and taking precautions like using UV
          protectants will go a long way in ensuring your swing remains a
          relaxing part of your home.
        </p>
      </section>

      <GetInTouch />
    </div>
  );
};

export default Blog;
