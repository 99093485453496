import NavigationBar from "../../components/ui/NavBar";
import Footer from "../../components/Footer";

const Shipping = () => {
  return (
    <>
      <NavigationBar />
      <div className="container lg:px-24 px-4 pt-[7rem] md:pt-32 text-justify">
        <p className="self-center whitespace-nowrap text-3xl font-semibold">
          Shipping Policy
        </p>
        <p className="mt-1">Last Updated on August 28th, 2024</p>
        <p className="mt-3">
          This Shipping Policy ("<text className="font-bold">Policy</text>")
          outlines the shipping practices of Knots of Macrame ("KOM," "we,"
          "us," or "our"). By using our services, you agree to the terms
          outlined in this Policy. Please read it carefully.
        </p>

        <p className="mt-7 font-semibold text-xl">Processing Time</p>
        <p>
          All orders are processed within 1-2 business days of receipt. During
          peak periods or promotional events, processing times may be extended.
        </p>

        <p className="mt-7 font-semibold text-xl">Shipping Time</p>
        <p>
          We offer standard shipping with an estimated delivery time ranging
          from a minimum of 30 days to a maximum of 60 days from the date of
          order confirmation.
        </p>
        <p>
          Delivery times may vary based on your location and other factors such
          as weather conditions and logistical delays.
        </p>

        <p className="mt-7 font-semibold text-xl">Shipping Costs</p>
        <p>
          Shipping costs are calculated based on the weight and dimensions of
          your order, as well as the delivery location. The total shipping cost
          will be displayed at checkout.
        </p>

        <p className="mt-7 font-semibold text-xl">Tracking Your Order</p>
        <p>
          Once your order has been shipped, you will receive a confirmation
          email with a tracking number. You can use this number to track the
          status of your delivery through our shipping partner’s website.
        </p>

        <p className="mt-7 font-semibold text-xl">Delivery Issues</p>
        <p>
          If you experience any issues with your delivery, such as delays or
          damaged items, please contact us at{" "}
          <a
            href="mailto:info@knotsofmacrame.com"
            className="underline text-blue-600"
          >
            info@knotsofmacrame.com
          </a>
          . We will work with you and our shipping partners to resolve the issue
          as quickly as possible.
        </p>

        <p className="mt-7 font-semibold text-xl">Address Accuracy</p>
        <p>
          It is your responsibility to ensure that the shipping address provided
          at checkout is accurate. KOM is not responsible for delays or errors
          resulting from incorrect or incomplete addresses.
        </p>

        <p className="mt-7 font-semibold text-xl">International Shipping</p>
        <p>
          At this time, we only ship within the [Specify Country/Countries]. We
          do not offer international shipping.
        </p>

        <p className="mt-7 font-semibold text-xl">Changes to Shipping Policy</p>
        <p>
          We reserve the right to modify this Shipping Policy at any time. Any
          changes will be posted on our website, and your continued use of our
          Services constitutes acceptance of the revised policy.
        </p>

        <p className="mt-7 font-semibold text-xl">Contact Us</p>
        <p>
          If you have any questions or need further assistance regarding our
          shipping policy, please contact us at{" "}
          <a
            href="mailto:info@knotsofmacrame.com"
            className="underline text-blue-600"
          >
            info@knotsofmacrame.com
          </a>
          .
        </p>
      </div>
      <Footer />
    </>
  );
};

export default Shipping;
