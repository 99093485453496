import React from "react";
interface Props {
  product_id: string;
  product_images: Array<string>;
  product_name: string;
  product_details: Array<string>;
  product_price: number;
  itemCount?: number;
}

const Order: React.FC<Props> = ({
  product_id,
  product_images,
  product_name,
  product_details,
  product_price,
  itemCount,
}) => {
  return (
    <div className="flex flex-row py-3 border-t">
      <img
        src={product_images[0]}
        alt={product_name}
        className="object-contain h-20 w-20 border bg-gray-50 p-2 rounded-lg"
      />
      <div className="flex flex-col justify-between mx-5">
        <p className="text-sm font-medium">{product_name}</p>

        <div className="flex items-center">
          {product_details.map((detail, index) => (
            <p
              key={index}
              className={`text-xs md:text-sm text-gray-500 ${
                product_details.length === index + 1 ? "" : "pr-1 mr-1 border-r"
              }`}
            >
              {detail}
            </p>
          ))}
        </div>

        <p className="text-sm font-semibold">Rs. {product_price}</p>

        {itemCount && itemCount > 1 && (
          <p className="text-sm">
            Quantity: <span className="font-semibold">{itemCount}</span>
          </p>
        )}
      </div>
    </div>
  );
};

export default Order;
