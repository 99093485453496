import React from "react";

const CustomToggle = ({
  isOpen,
  onClick,
}: {
  isOpen: boolean;
  onClick: () => void;
}) => {
  return (
    <button
      className={`block xl:hidden navbar-toggler ${isOpen ? "collapsed" : ""}`}
      onClick={onClick}
      aria-label="Toggle navigation"
    >
      <span className="toggler-icon"></span>
      <span className="toggler-icon"></span>
      <span className="toggler-icon"></span>
    </button>
  );
};

export default CustomToggle;
