import NavigationBar from "../../components/ui/NavBar";
import Collections from "../../components/Collections";
import Footer from "../../components/Footer";

const CollectionsPage = () => {
  return (
    <div className="flex flex-col items-center min-h-screen">
      <NavigationBar />
      <Collections />
      <Footer />
    </div>
  );
};

export default CollectionsPage;
