import axios from "axios";
import { komBaseURL, zzeehBaseURL } from "./constants";

// Create an instance of axios with default headers and base URL
export const zzeehApiClient = axios.create({
  baseURL: zzeehBaseURL, // Set the base URL from environment variable
  headers: {
    "x-api-key": process.env.REACT_APP_API_SECRET_KEY, // Set the API key from environment variable
  },
});

export const komApiClient = axios.create({
  baseURL: komBaseURL, // Set the base URL from environment variable
  headers: {
    "x-api-key": process.env.REACT_APP_API_SECRET_KEY, // Set the API key from environment variable
  },
});
