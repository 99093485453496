import React from "react";

const ScrollingText = () => {
  return (
    <div className="fixed top-0 w-full bg-black text-white py-2 overflow-hidden z-50">
      <div className="flex items-center whitespace-nowrap animate-marquee">
        <p className="text-xs md:text-sm mx-40">
          🎉 Launch Sale: 30% OFF on All Products + Free Shipping on Every
          Order! Don't miss out—shop your favorite macramé products today!
        </p>
        <p className="text-xs md:text-sm mx-40">
          🎉 Launch Sale: 30% OFF on All Products + Free Shipping on Every
          Order! Don't miss out—shop your favorite macramé products today!
        </p>
      </div>
    </div>
  );
};

export default ScrollingText;
