import NavigationBar from "../../components/ui/NavBar";
import Footer from "../../components/Footer";
import Dining from "../../components/categories/Dining";

const DiningPage = () => {
  return (
    <div className="flex flex-col items-center min-h-screen">
      <NavigationBar />
      <Dining />
      <Footer />
    </div>
  );
};

export default DiningPage;
