import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../lib/authContext";
import { OrderData } from "../lib/types";
import NavigationBar from "../components/ui/NavBar";
import Order from "../components/Order";
import Footer from "../components/Footer";
import { BasketProduct } from "../lib/redux/basketSlice";
import { fetchOrders } from "../lib/apis";

const Orders = () => {
  const navigate = useNavigate();
  const { userData } = UserAuth();
  const [ordersData, setOrdersData] = useState<OrderData[]>();

  const getOrders = useCallback(async () => {
    if (userData?.user_id) {
      const res = await fetchOrders(userData?.user_id);
      // console.log(res);
      if (res?.status === 200) setOrdersData(res.data);
    }
  }, [userData]);

  useEffect(() => {
    if (!ordersData || ordersData.length === 0) {
      getOrders();
    }
  }, [getOrders]);

  useEffect(() => {
    getOrders();
  }, [userData]);

  return (
    <main className="flex flex-col items-center min-h-screen">
      <NavigationBar />
      <section
        className="lg:flex my-auto lg:mx-auto pt-[6rem] md:pt-[8rem] md:28 justify-evenly"
        id="orders"
      >
        <div className="flex flex-col p-3 md:px-10 space-y-5 bg-white shadow-md w-full">
          {ordersData === undefined ? (
            <>
              <h1 className="text-3xl border-b pb-4">
                You do not have any orders.
              </h1>
              <button
                className="select-none text-black px-3 py-2 bg-gradient-to-b from-cyan-200 to-cyan-400 border border-cyan-300 rounded-md focus:outline-none hover:from-cyan-500 hover:to-cyan-400"
                onClick={() => navigate("/")}
              >
                Place an Order
              </button>
            </>
          ) : (
            <h1 className="text-3xl border-b pb-4">My Orders</h1>
          )}
          {ordersData === undefined ? (
            <></>
          ) : (
            ordersData?.map((order) => {
              // Create a Map to store unique items by product_id
              const uniqueItemsMap = new Map();

              order.items.forEach((item) => {
                if (!uniqueItemsMap.has(item.product_id)) {
                  uniqueItemsMap.set(item.product_id, item);
                }
              });

              // Convert the Map back to an array of unique items
              const uniqueItems: BasketProduct[] = Array.from(
                uniqueItemsMap.values()
              );
              return (
                <React.Fragment key={order.order_id}>
                  <div className="p-4 my-3 bg-offWhite rounded-lg">
                    <div className="flex flex-col mb-3">
                      <p className="md:text-lg font-medium">
                        Order ID: {order.order_id}
                      </p>
                      <p className="text-sm">
                        <span className="text-gray-500">Order placed:</span>{" "}
                        {new Date(order.created_at).toDateString()}
                      </p>
                    </div>
                    {uniqueItems.map((item) => (
                      <Order
                        key={item.product_id}
                        product_id={item.product_id}
                        product_name={item.product_name}
                        product_images={item.product_images}
                        product_price={item.product_price}
                        product_details={item.product_details}
                        itemCount={
                          order.items.filter(
                            (i) => i.product_id === item.product_id
                          ).length
                        }
                      />
                    ))}
                    <div className="flex flex-col lg:flex-row border-t">
                      <div className="py-2 lg:w-1/2 lg:border-r">
                        <p className="text-xs text-gray-500">
                          Delivery address
                        </p>
                        <p className="text-sm">{order.order_address}</p>
                      </div>

                      <div className="flex flex-col justify-center pt-2 lg:w-1/2 lg:pl-5 border-t lg:border-0">
                        {order.affiliate_code !== "" && (
                          <div className="flex justify-between text-sm">
                            <p>Code Applied</p>
                            <p> {order.affiliate_code}</p>
                          </div>
                        )}

                        <div className="flex justify-between text-sm">
                          <p>Payment</p>
                          <p>
                            <span className="font-medium">
                              {order.order_type === "cod"
                                ? "Cash on Delivery"
                                : "Prepaid"}
                            </span>
                          </p>
                        </div>
                        {order.payment_id && (
                          <div className="flex justify-between text-sm">
                            <p>Payment ID</p>
                            <p>{order.payment_id}</p>
                          </div>
                        )}
                        <div className="flex justify-between text-sm">
                          <p>Subtotal</p>
                          <p className="">
                            Rs.{" "}
                            {(order.order_total - order.order_tax).toFixed(2)}
                          </p>
                        </div>
                        <div className="flex justify-between text-sm">
                          <p>Tax</p>
                          <p>+ Rs. {order.order_tax}</p>
                        </div>
                        <div className="flex justify-between text-sm">
                          <p>Total</p>
                          <p className="font-bold">Rs. {order.order_total}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              );
            })
          )}
        </div>
      </section>
      <Footer />
    </main>
  );
};

export default Orders;
