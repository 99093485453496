import Card from "./ProductCard";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchProducts } from "../lib/apis";
import { Product } from "../lib/types";

const Collections = () => {
  const { product_subcategory } = useParams();
  const [allProducts, setAllProducts] = useState<Product[] | undefined>();
  const [sort, setSort] = useState("4");

  const getItems = useCallback(async () => {
    const res = await fetchProducts("", product_subcategory);
    if (res?.status === 200) setAllProducts(res.data);
  }, [product_subcategory]);

  useEffect(() => {
    getItems();
  }, [getItems, product_subcategory]);

  // Sort products based on the selected sorting option
  const sortedProducts = allProducts
    ? [...allProducts].sort((a, b) => {
        switch (sort) {
          case "0": // Lowest Price
            return a.product_price - b.product_price;
          case "1": // Highest Price
            return b.product_price - a.product_price;
          case "2": // A-Z
            return a.product_name.localeCompare(b.product_name);
          case "3": // Z-A
            return b.product_name.localeCompare(a.product_name);
          case "4": // New to Old
            return (
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
            );
          case "5": // Old to New
            return (
              new Date(a.created_at).getTime() -
              new Date(b.created_at).getTime()
            );
          default:
            return 0;
        }
      })
    : [];

  return (
    <main
      id={product_subcategory}
      className="container min-h-screen w-full px-3 md:px-10 pt-[6rem] md:pt-[7rem]"
    >
      <section id={product_subcategory}>
        <div className="flex justify-between items-center">
          <h1 className="text-2xl my-10 md:ml-3 font-semibold uppercase">
            {product_subcategory?.replace(/_/g, " ")}
          </h1>

          {/* Sorting dropdown */}
          <select
            name="sort"
            value={sort}
            className="border-0 rounded"
            onChange={(e) => setSort(e.target.value)}
          >
            <option value="4">New to Old</option>
            <option value="5">Old to New</option>
            <option value="0">Lowest Price</option>
            <option value="1">Highest Price</option>
            <option value="2">A-Z</option>
            <option value="3">Z-A</option>
          </select>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-5 xl:gap-10">
          {sortedProducts?.map((product, index) => (
            <Card key={index} item={product} />
          ))}
        </div>
      </section>
    </main>
  );
};

export default Collections;
