import React, { useState } from "react";
import { SearchProduct } from "../lib/types";
import kom_products from "../assets/data/kom_products";
import { useNavigate } from "react-router-dom";

const SearchBar = ({
  setSearchVisible,
}: {
  setSearchVisible: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState<string>("");

  // Filter the products based on the input value
  const filteredProducts = kom_products.filter(
    (product: SearchProduct) =>
      product.product_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      product?.product_category
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      product?.product_subcategory
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
  );

  return (
    <div className="fixed top-32 left-1/2 transform -translate-x-1/2 w-3/4 z-20 rounded-md">
      <div className="flex flex-col w-full">
        <div className="flex flex-row items-center w-full space-x-5">
          <div className="relative w-full">
            <input
              autoFocus
              type="text"
              className="w-full shadow-md rounded-md p-2"
              placeholder="What are you looking for?"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            {searchTerm && (
              <button
                onClick={() => setSearchTerm("")}
                className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-cyan-200 rounded-full p-1 text-sm hover:opacity-80 transition-opacity ease-in-out duration-300"
              >
                Clear
              </button>
            )}
          </div>
          <button
            onClick={() => setSearchVisible(false)}
            className="bg-gray-200 rounded-full font-semibold h-7 w-7 text-sm hover:opacity-80 transition-opacity ease-in-out duration-300"
          >
            ✕
          </button>
        </div>

        {/* Display filtered products */}
        {searchTerm && (
          <div className="bg-white py-4 rounded-md">
            {filteredProducts.length > 0 ? (
              <ul className="max-h-80 overflow-hidden overflow-y-scroll">
                {filteredProducts.map((product) => (
                  <li
                    key={product.product_id}
                    className="p-2 border-b cursor-pointer"
                    onClick={() => {
                      navigate(`/collections/${product.product_subcategory}`);
                      setSearchVisible(false);
                      setSearchTerm("");
                    }}
                  >
                    <div className="font-medium">{product.product_name}</div>
                    {/* <div className="text-sm text-gray-500">
                      {product.product_category} / {product.product_subcategory}
                    </div> */}
                  </li>
                ))}
              </ul>
            ) : (
              <div className="text-gray-500 text-center">No products found</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchBar;
