import React from "react";

const GetInTouch = () => {
  return (
    <section id="contact-zzeeh" className="space-y-3">
      <h1 className="text-xl lg:text-2xl font-medium font-gt-super">
        Get in Touch with Knots of Macrame
      </h1>
      <ul className="list-disc ml-5 px-5 py-2 space-y-1">
        <li>
          <span className="font-semibold">Website: </span>{" "}
          <a href="/" className="hover:text-cyan-500">
            www.knotsofmacrame.com
          </a>
        </li>
        <li>
          <span className="font-semibold">Contact: </span>{" "}
          <a
            href="mailto:info@knotsofmacrame.com"
            className="hover:text-cyan-500"
          >
            info@knotsofmacrame.com
          </a>
        </li>
        <li>
          <span className="font-semibold">Phone: </span>{" "}
          <a href="tel:+918147289895" className="hover:text-cyan-500">
            +91 81472 89895
          </a>
        </li>
      </ul>
    </section>
  );
};

export default GetInTouch;
