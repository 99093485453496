import React from "react";
import { useSelector } from "react-redux";
import {
  BasketProduct,
  selectProductCountById,
} from "../lib/redux/basketSlice";

const CheckoutProduct: React.FC<BasketProduct> = ({
  product_id,
  product_images,
  product_name,
  product_details,
  product_price,
  product_description,
}) => {
  const itemCount = useSelector(selectProductCountById(product_id));

  const inflatedPrice = +(product_price / 0.7).toFixed(0);

  return (
    <div className="grid grid-cols-4 self-center">
      <img
        src={product_images[0]}
        alt={product_name}
        width={200}
        className="object-contain"
      />
      <div className="col-span-3 mx-5">
        <p className="font-semibold lg:text-xl">{product_name} </p>

        <ul className="list-disc p-5">
          {product_details.map((detail, index) => (
            <li key={index} className="text-sm lg:text-base">
              {detail}
            </li>
          ))}
        </ul>
        <p className="text-sm lg:text-base">
          M.R.P.:{" "}
          <span className=" line-through mr-2 md:mr-3">
            Rs. {inflatedPrice}
          </span>
          <span className="font-semibold text-base lg:text-lg ">
            Rs. {product_price}
          </span>
        </p>
        {itemCount > 1 && (
          <p className="text-sm lg:text-base">
            Quantity:{" "}
            <span className="font-bold text-base lg:text-lg ">{itemCount}</span>
          </p>
        )}
      </div>
    </div>
  );
};

export default CheckoutProduct;
